import React from 'react';
import makeStyles from "@material-ui/core/styles/makeStyles";
import colors from "./styles/colors";
import FlatButton from "./FlatButton";
import {Link, withRouter} from "react-router-dom";
import boxShadows from "./styles/boxShadows";
import arrow from "./img/blog/arrow_blog_trip_teaser_big.svg";
import bg from "./img/blog/bg_blog_trip_teaser_big.svg";

const useStyles = makeStyles(theme => ({
    root: {
        [theme.breakpoints.up('sm')]: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
        },
        [theme.breakpoints.down('xs')]: {
            backgroundPosition: 'center top',
            backgroundSize: '100%'
        },
        paddingBottom: '5rem',
        background: `url(${bg}) no-repeat center 70%`,
        backgroundSize: '90%'
    },
    leftColumn: {
        [theme.breakpoints.up('sm')]: {
            width: '40%'
        },
        [theme.breakpoints.down('sm')]: {
            marginBottom: '2rem'
        },
        [theme.breakpoints.down('xs')]: {
            textAlign: 'center',
        }
    },
    title: {
        marginBottom: '3rem',
        fontSize: '3rem',

        '@media (min-width: 960.05px) and (max-width: 1110px)': {
            fontSize: '2.5rem'
        },
        '@media (max-width: 740px)': {
            fontSize: '2.5rem',
            marginBottom: '2rem'
        }
    },
    text: {
        margin: 0,
        fontSize: '1.1rem',
        lineHeight: '1.5',
        fontWeight: '800'
    },
    arrow: {
        display: 'block',
        width: '50%',
        margin: '0 10% 0 auto',

        [theme.breakpoints.down('md')]: {
            marginTop: '1rem',
        },
        [theme.breakpoints.down('xs')]: {
            display: 'none'
        }
    },
    rightColumn: {
        [theme.breakpoints.up('sm')]: {
            width: '55%'
        }
    },
    tripTeaser: {
        padding: '1.5rem',
        boxShadow: boxShadows.normal,
        backgroundColor: colors.white,

        '&:hover': {
            cursor: 'pointer',
            boxShadow: boxShadows.hover
        },
    },
    imgWrapper: {
        position: 'relative',
        paddingBottom: '66%'
    },
    teaserImg: {
        position: 'absolute',
        height: '100%',
        objectFit: 'cover'
    },
    bottomWrapper: {
        textAlign: 'center',
        padding: '1rem 0 0.5rem'
    },
    tripTitle: {
        margin: '0 0 1rem'
    }
}));

function BlogTripTeaserBig(props) {
    const classes = useStyles(props);
    const {citySlug, cityName, tripName} = props;
    const headline = props.adContent.bigAdHeadline;
    let teaserText = props.adContent.bigAdText;

    const baseImgPath = `./img/`;
    const url = `/trip/${props.cityId}`;

    const handleClick = (e) => {
        e.preventDefault();
        e.stopPropagation();
        props.history.push(`/trip/${citySlug}`);
    };

    teaserText = teaserText.replace('%TRIP_NAME%', tripName);
    teaserText = teaserText.replace('%CITY_NAME%', cityName);

    return (
        <article className={classes.root}>
            <div className={classes.leftColumn}>
                <h2 className={classes.title}>{headline}</h2>
                <p className={classes.text}>{teaserText}</p>
                <img src={arrow} alt="Arrow to the right" className={classes.arrow} />
            </div>
            <div className={classes.rightColumn}>
                <article className={classes.tripTeaser} onClick={handleClick}>
                    <div className={classes.innerWrapper}>
                        <figure className={classes.imgWrapper}>
                            <picture>
                                <source
                                    src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
                                    data-srcset={`${require(`${baseImgPath}${citySlug}/teaser_small.webp`)} 300w, ${require(`${baseImgPath}${citySlug}/teaser_large.webp`)} 600w`}
                                    type="image/webp"
                                />
                                <source
                                    src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
                                    data-srcset={`${require(`${baseImgPath}${citySlug}/teaser_small.jpg`)} 300w, ${require(`${baseImgPath}${citySlug}/teaser_large.jpg`)} 600w`}
                                    type="image/jpeg"
                                />
                                <img
                                    src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
                                    data-src={require(`${baseImgPath}${citySlug}/teaser_large.jpg`)}
                                    alt="Friends during a group trip"
                                    className={`lazyload ${classes.teaserImg}`}
                                    data-sizes="auto"
                                />
                            </picture>
                        </figure>
                        <div className={classes.bottomWrapper}>
                            <h3 className={classes.tripTitle}>
                                <Link to={url} onClick={handleClick}>{tripName}</Link>
                            </h3>
                            <FlatButton smallButton bgColor={colors.black} color={colors.white}>Trip Details</FlatButton>
                        </div>
                    </div>
                </article>
            </div>
        </article>
    );
}

export default withRouter(BlogTripTeaserBig);