import React from 'react';
import {Button} from '@material-ui/core';
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles(theme => ({
    root: {
        position: 'relative',
        borderRadius: '10rem',
        fontFamily: 'Open Sans, sans-serif',
        fontWeight: 800,
        letterSpacing: 0,
        textTransform: 'initial',
        fontSize: '1rem',
        padding: '0.6rem 3rem',
        boxShadow: 'none',
        zIndex: 1,
        width: props => props.width,
        color: props => props.color,
        backgroundColor: props => props.bgColor,

        '&:hover, &:focus': {
            backgroundColor: props => props.bgColor,
            boxShadow: props => props.noHover ? 'none' : '0px 10px 25px -8px rgba(0,0,0,0.4)'
        },
        [theme.breakpoints.up('lg')]: {
            minWidth: props => (props.smallButton ? 0 : '14rem')
        },
        '@media (min-width: 1024.05px)': {
            padding: props => (props.smallButton ? '0.2rem 1.2rem' : '0.6rem 3rem'),
            fontSize: props => (props.smallButton ? '1rem' : '1.2rem'),
        },
        '@media (min-width: 960px) and (max-width: 1024px)': {
            padding: '0.4rem 3rem',
        },
        [theme.breakpoints.down('sm')]: {
            padding: props => props.smallButton ? '0.3rem  2rem' : '',
        },
        [theme.breakpoints.down('xs')]: {
            minWidth: 0,
            fontSize: props => props.smallButton ? '0.875rem' : '',
            padding: props => props.smallButton ? '0.3rem 1.2rem' : (props.smallPadding ? '0.4rem 1rem' : '0.6rem 2rem'),
        },
        '@media (max-width: 370px)': {
            padding: props => props.smallButton ? '0.3rem 0.8rem' : (props.smallPadding ? '0.4rem 1rem' : '0.6rem 2rem'),
        },
    }
}));

function FlatButton(props) {
    const classes = useStyles(props);

    return (
        <Button
            onClick={props.onClick}
            href={props.href}
            variant="contained"
            className={classes.root}
            type={props.type}
            name={props.name}
            id={props.id}
        >
            {props.children}
        </Button>
    );
}

export default FlatButton;