import React from 'react';
import makeStyles from "@material-ui/core/styles/makeStyles";
import ImageGrid from "./ImageGrid";
import tripData from "./data/tripData";


const useStyles = makeStyles(theme => ({
    root: {
        marginBottom: '8rem'
    }
}));

function TripImageGrid(props) {
    const classes = useStyles();
    const { cityId } = props;
    const {gridImageNames, slug} = tripData[cityId];
    const baseUrl = `./img/${slug}/grid`;
    let imageList = {};



    gridImageNames.map((img, i) => {
        imageList[`img${i+1}DesktopJpg`] = require(`${baseUrl}/desktop/${img}.jpg`);
        imageList[`img${i+1}DesktopWebp`] = require(`${baseUrl}/desktop/${img}.webp`);
        imageList[`img${i+1}MobileJpg1x`] = require(`${baseUrl}/mobile/1x/${img}.jpg`);
        imageList[`img${i+1}MobileWebp1x`] = require(`${baseUrl}/mobile/1x/${img}.webp`);
        imageList[`img${i+1}MobileJpg2x`] = require(`${baseUrl}/mobile/2x/${img}.jpg`);
        imageList[`img${i+1}MobileWebp2x`] = require(`${baseUrl}/mobile/2x/${img}.webp`);
    });

    return (
        <section className={classes.root}>
            <ImageGrid imageList={imageList}/>
        </section>
    );
}

export default TripImageGrid;