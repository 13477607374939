import React, {createRef, useEffect, useRef, useState} from 'react';
import makeStyles from "@material-ui/core/styles/makeStyles";
import illuLean from "./img/illus/illu_lean_square.svg";
import illuUntourist from "./img/illus/illu_untourist_square.svg";
import illuFun from "./img/illus/illu_fun_square.svg";
import illuFriends from "./img/illus/illu_clique_square.svg";


const useStyles = makeStyles(theme => ({
    benefitsSmall: {
        padding: '4rem 5vw 0',
        margin: '0 -5vw',
        backgroundColor: '#f4f4f4',

        [theme.breakpoints.down('md')]: {
            padding: '4rem 6vw 0',
            margin: '0 -6vw'
        },
        [theme.breakpoints.only('sm')]: {
            // paddingBottom: '2rem'
        },
        [theme.breakpoints.down('xs')]: {
            padding: '4rem 5vw 0',
            margin: '0 -5vw'
        }
    },
    benefitsList: {
        display: 'flex',
        justifyContent: 'space-between',

       '@media only screen and (max-width: 1200px) and (min-width: 600.05px)': {
            flexWrap: 'wrap',
            justifyContent: 'space-around'
        },
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column',
        }
    },
    benefit: {
        textAlign: 'center',

        [theme.breakpoints.up('md')]: {
            padding: '0 4rem 3rem',
        },
        '@media only screen and (max-width: 1200px) and (min-width: 600.05px)': {
            width: '37%',
            padding: '0 2rem 5rem'
        },
        [theme.breakpoints.down('xs')]: {
            marginBottom: '5rem'
        }
    },
    benefitTitle: {
        fontSize: '1rem',
        fontWeight: 800,
        marginBottom: '0.25rem',

        [theme.breakpoints.down('xs')]: {
            marginBottom: '0.5rem'
        }
    },
    benefitText: {
        fontSize: '0.875rem',
        margin: 0,

        [theme.breakpoints.down('xs')]: {
            padding: '0 2rem'
        }
    },
    benefitIllu: {
        height: '10rem',
        marginBottom: '1rem',

        '&.lean': {
            transform: 'translateX(-1rem)'
        },
        '&.friends': {
            transform: 'translateX(1.5rem)'
        },
        [theme.breakpoints.down('xs')]: {
            width: '60%'
        }
    }
}));

function BenefitsSmall(props) {
    const classes = useStyles();

    return (
        <section className={classes.benefitsSmall}>
            <ul className={classes.benefitsList}>
                <li className={classes.benefit}>
                    <img src={illuLean} alt="" className={`${classes.benefitIllu} lean`}/>
                    <h3 className={classes.benefitTitle}>Hassle-Free Travel</h3>
                    <p className={classes.benefitText}>
                        Just book your flight and show up, we take care of the boring details so you can concentrate on having fun!
                    </p>
                </li>
                <li className={classes.benefit}>
                    <img src={illuFriends} alt="" className={`${classes.benefitIllu} friends`}/>
                    <h3 className={classes.benefitTitle}>Make new friends</h3>
                    <p className={classes.benefitText}>
                        Join a small group of like-minded explorers and create lifelong memories together!
                    </p>
                </li>
                <li className={classes.benefit}>
                    <img src={illuFun} alt="" className={classes.benefitIllu}/>
                    <h3 className={classes.benefitTitle}>No risk, only fun</h3>
                    <p className={classes.benefitText}>
                        Free cancelation until 30 days pre-departure and low refundable deposit make it easy and
                        risk-free.
                    </p>
                </li>
                <li className={classes.benefit}>
                    <img src={illuUntourist} alt="" className={classes.benefitIllu}/>
                    <h3 className={classes.benefitTitle}>Untourist Yourself</h3>
                    <p className={classes.benefitText}>
                        All our trips are led by locals that will show you the true spirit of your destination off the beaten track.
                    </p>
                </li>
            </ul>
        </section>
    );
}

export default BenefitsSmall;