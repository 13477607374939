import React from 'react';
import makeStyles from "@material-ui/core/styles/makeStyles";


const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        maxWidth: '80rem',
        margin: '0 auto'
    },
    grid: {
        display: 'flex',
        width: '100%',
        height: '37vw',
        maxHeight: 'calc(90rem * 0.37)',
        justifyContent: 'space-between',
        marginBottom: '3rem',

        [theme.breakpoints.down('xs')]: {
            display: 'none'
        }
    },
    gridColumnLg: {
        position: 'relative',
        display: 'flex',
        justifyContent: 'space-between',
        alignContent: 'space-between',
        flexWrap: 'wrap',
        width: 'calc(43% - 0.5rem)',

        [theme.breakpoints.down('xs')]: {
            width: '100%',
            paddingTop: '66%',
            marginBottom: '0.75rem'
        }
    },
    gridColumnSm: {
        display: 'flex',
        justifyContent: 'space-between',
        alignContent: 'space-between',
        flexWrap: 'wrap',
        width: 'calc(28.5% - 0.5rem)',

        [theme.breakpoints.down('xs')]: {
            width: '100%',
            marginBottom: '0.75rem'
        }
    },
    gridItemLg: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
    },
    gridItemMd: {
        width: '100%',
        height: 'calc(66.5% - 0.375rem)',

        [theme.breakpoints.down('xs')]: {
            width: 'calc(50% - 0.15rem)',
            height: 0,
            paddingTop: 'calc(50% - 0.15rem)',
            position: 'relative'
        }
    },
    gridItemSm: {
        width: 'calc(50% - 0.375rem)',
        height: 'calc(33.5% - 0.375rem)',

        [theme.breakpoints.down('xs')]: {
            position: 'relative',
            width: 'calc(33.3333% - 0.2rem)',
            height: 0,
            paddingTop: 'calc(33.3333% - 0.2rem)'
        }
    },
    image: {
        height: '100%',
        objectFit: 'cover'
    },
    mobileGrid: {
        position: 'relative',
        marginBottom: '2rem',
        width: '100%',

        [theme.breakpoints.up('sm')]: {
            display: 'none'
        },
        '& picture': {
            [theme.breakpoints.down('xs')]: {
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%'
            }
        },
    },
    gridRow: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: '0.25rem'
    }
}));

function ImageGrid(props) {
    const classes = useStyles();

    const { img1DesktopJpg, img1DesptopWebp, img1MobileJpg1x, img1MobileWebp1x, img1MobileJpg2x,
        img1MobileWebp2x, img2DesktopJpg, img2DesptopWebp, img2MobileJpg1x, img2MobileWebp1x, img2MobileJpg2x,
        img2MobileWebp2x, img3DesktopJpg, img3DesptopWebp, img3MobileJpg1x, img3MobileWebp1x, img3MobileJpg2x,
        img3MobileWebp2x, img4DesktopJpg, img4DesptopWebp, img4MobileJpg1x, img4MobileWebp1x, img4MobileJpg2x,
        img4MobileWebp2x, img5DesktopJpg,  img5DesptopWebp, img5MobileJpg1x, img5MobileWebp1x, img5MobileJpg2x,
        img5MobileWebp2x, img6DesktopJpg, img6DesptopWebp, img6MobileJpg1x, img6MobileWebp1x, img6MobileJpg2x,
        img6MobileWebp2x, img7DesktopJpg, img7DesptopWebp, img7MobileJpg1x, img7MobileWebp1x, img7MobileJpg2x,
        img7MobileWebp2x
    } = props.imageList;

    return (
        <div className={classes.root}>
            <div className={classes.grid}>
                <div className={classes.gridColumnLg}>
                    <div className={classes.gridItemLg}>
                        <picture>
                            <source
                                src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
                                data-srcset={img1DesptopWebp}
                                type="image/webp"
                            />
                            <source
                                src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
                                data-srcset={img1DesktopJpg}
                                type="image/jpeg"
                            />
                            <img
                                src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
                                data-src={img1DesktopJpg}
                                alt=""
                                className={`lazyload ${classes.image}`}
                                data-sizes="auto"
                            />
                        </picture>
                    </div>
                </div>
                <div className={classes.gridColumnSm}>
                    <div className={classes.gridItemSm}>
                        <picture>
                            <source
                                src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
                                data-srcset={img2DesptopWebp}
                                type="image/webp"
                            />
                            <source
                                src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
                                data-srcset={img2DesktopJpg}
                                type="image/jpeg"
                            />
                            <img
                                src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
                                data-src={img2DesktopJpg}
                                alt=""
                                className={`lazyload ${classes.image}`}
                                data-sizes="auto"
                            />
                        </picture>
                    </div>
                    <div className={classes.gridItemSm}>
                        <picture>
                            <source
                                src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
                                data-srcset={img3DesptopWebp}
                                type="image/webp"
                            />
                            <source
                                src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
                                data-srcset={img3DesktopJpg}
                                type="image/jpeg"
                            />
                            <img
                                src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
                                data-src={img3DesktopJpg}
                                alt=""
                                className={`lazyload ${classes.image}`}
                                data-sizes="auto"
                            />
                        </picture>
                    </div>
                    <div className={classes.gridItemMd}>
                        <picture>
                            <source
                                src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
                                data-srcset={img4DesptopWebp}
                                type="image/webp"
                            />
                            <source
                                src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
                                data-srcset={img4DesktopJpg}
                                type="image/jpeg"
                            />
                            <img
                                src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
                                data-src={img4DesktopJpg}
                                alt=""
                                className={`lazyload ${classes.image}`}
                                data-sizes="auto"
                            />
                        </picture>
                    </div>
                </div>
                <div className={classes.gridColumnSm}>
                    <div className={classes.gridItemMd}>
                        <picture>
                            <source
                                src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
                                data-srcset={img5DesptopWebp}
                                type="image/webp"
                            />
                            <source
                                src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
                                data-srcset={img5DesktopJpg}
                                type="image/jpeg"
                            />
                            <img
                                src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
                                data-src={img5DesktopJpg}
                                alt=""
                                className={`lazyload ${classes.image}`}
                                data-sizes="auto"
                            />
                        </picture>
                    </div>
                    <div className={classes.gridItemSm}>
                        <picture>
                            <source
                                src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
                                data-srcset={img6DesptopWebp}
                                type="image/webp"
                            />
                            <source
                                src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
                                data-srcset={img6DesktopJpg}
                                type="image/jpeg"
                            />
                            <img
                                src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
                                data-src={img6DesktopJpg}
                                alt=""
                                className={`lazyload ${classes.image}`}
                                data-sizes="auto"
                            />
                        </picture>
                    </div>
                    <div className={classes.gridItemSm}>
                        <picture>
                            <source
                                src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
                                data-srcset={img7DesptopWebp}
                                type="image/webp"
                            />
                            <source
                                src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
                                data-srcset={img7DesktopJpg}
                                type="image/jpeg"
                            />
                            <img
                                src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
                                data-src={img7DesktopJpg}
                                alt=""
                                className={`lazyload ${classes.image}`}
                                data-sizes="auto"
                            />
                        </picture>
                    </div>
                </div>
            </div>
            <div className={classes.mobileGrid}>
                <div className={classes.gridRow}>
                    <div className={classes.gridItemMd}>
                        <picture>
                            <source
                                src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
                                data-srcset={`${img1MobileWebp1x} 1x, ${img1MobileWebp2x} 2x`}
                                type="image/webp"
                            />
                            <source
                                src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
                                data-srcset={`${img1MobileJpg1x} 1x, ${img1MobileJpg2x} 2x`}
                                type="image/jpeg"
                            />
                            <img
                                src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
                                data-src={img1MobileJpg1x}
                                alt=""
                                className={`lazyload ${classes.image}`}
                                data-sizes="auto"
                            />
                        </picture>
                    </div>
                    <div className={classes.gridItemMd}>
                        <picture>
                            <source
                                src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
                                data-srcset={`${img2MobileWebp1x} 1x, ${img2MobileWebp2x} 2x`}
                                type="image/webp"
                            />
                            <source
                                src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
                                data-srcset={`${img2MobileJpg1x} 1x, ${img2MobileJpg2x} 2x`}
                                type="image/jpeg"
                            />
                            <img
                                src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
                                data-src={img2MobileJpg1x}
                                alt=""
                                className={`lazyload ${classes.image}`}
                                data-sizes="auto"
                            />
                        </picture>
                    </div>
                </div>
                <div className={classes.gridRow}>
                    <div className={classes.gridItemSm}>
                        <picture>
                            <source
                                src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
                                data-srcset={`${img3MobileWebp1x} 1x, ${img3MobileWebp2x} 2x`}
                                type="image/webp"
                            />
                            <source
                                src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
                                data-srcset={`${img3MobileJpg1x} 1x, ${img3MobileJpg2x} 2x`}
                                type="image/jpeg"
                            />
                            <img
                                src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
                                data-src={img3MobileJpg1x}
                                alt=""
                                className={`lazyload ${classes.image}`}
                                data-sizes="auto"
                            />
                        </picture>
                    </div>
                    <div className={classes.gridItemSm}>
                        <picture>
                            <source
                                src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
                                data-srcset={`${img4MobileWebp1x} 1x, ${img4MobileWebp2x} 2x`}
                                type="image/webp"
                            />
                            <source
                                src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
                                data-srcset={`${img4MobileJpg1x} 1x, ${img4MobileJpg2x} 2x`}
                                type="image/jpeg"
                            />
                            <img
                                src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
                                data-src={img4MobileJpg1x}
                                alt=""
                                className={`lazyload ${classes.image}`}
                                data-sizes="auto"
                            />
                        </picture>
                    </div>
                    <div className={classes.gridItemSm}>
                        <picture>
                            <source
                                src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
                                data-srcset={`${img5MobileWebp1x} 1x, ${img5MobileWebp2x} 2x`}
                                type="image/webp"
                            />
                            <source
                                src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
                                data-srcset={`${img5MobileJpg1x} 1x, ${img5MobileJpg2x} 2x`}
                                type="image/jpeg"
                            />
                            <img
                                src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
                                data-src={img5MobileJpg1x}
                                alt=""
                                className={`lazyload ${classes.image}`}
                                data-sizes="auto"
                            />
                        </picture>
                    </div>
                </div>
                <div className={classes.gridRow}>
                    <div className={classes.gridItemMd}>
                        <picture>
                            <source
                                src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
                                data-srcset={`${img6MobileWebp1x} 1x, ${img6MobileWebp2x} 2x`}
                                type="image/webp"
                            />
                            <source
                                src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
                                data-srcset={`${img6MobileJpg1x} 1x, ${img6MobileJpg2x} 2x`}
                                type="image/jpeg"
                            />
                            <img
                                src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
                                data-src={img6MobileJpg1x}
                                alt=""
                                className={`lazyload ${classes.image}`}
                                data-sizes="auto"
                            />
                        </picture>
                    </div>
                    <div className={classes.gridItemMd}>
                        <picture>
                            <source
                                src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
                                data-srcset={`${img7MobileWebp1x} 1x, ${img7MobileWebp2x} 2x`}
                                type="image/webp"
                            />
                            <source
                                src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
                                data-srcset={`${img7MobileJpg1x} 1x, ${img7MobileJpg2x} 2x`}
                                type="image/jpeg"
                            />
                            <img
                                src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
                                data-src={img7MobileJpg1x}
                                alt=""
                                className={`lazyload ${classes.image}`}
                                data-sizes="auto"
                            />
                        </picture>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ImageGrid;