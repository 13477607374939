import makeStyles from "@material-ui/core/styles/makeStyles";
import React from "react";
import illu from './img/how-it-works/illu_photo_booth.svg';
import illuBgTop from './img/how-it-works/bg_photo_booth_top.svg';
import illuBgBottom from './img/how-it-works/bg_photo_booth_bottom.svg';

const useStyles = makeStyles(theme => ({
    root: {
        padding: '10rem 0 0',
        marginBottom: '15rem',

        [theme.breakpoints.only('sm')]: {
            paddingTop: '7rem'
        },
        [theme.breakpoints.only('xs')]: {
            paddingTop: '5rem',
            marginBottom: '8rem',
        },
    },
    content: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',

        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column'
        }
    },
    title: {
        marginBottom: '5rem',

        [theme.breakpoints.only('md')]: {
            fontSize: '3rem'
        },
        [theme.breakpoints.only('sm')]: {
            fontSize: '4rem'
        },
        [theme.breakpoints.only('xs')]: {
            marginBottom: '3rem'
        }
    },
    leftColumn: {
        width: '35%',

        [theme.breakpoints.down('sm')]: {
            width: '100%',
            padding: '0 1rem',
            marginBottom: '2rem'
        }
    },
    rightColumn: {
        width: '50%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',

        [theme.breakpoints.down('sm')]: {
            width: '100%'
        },
    },
    imgWrapper: {
        position: 'relative',
        width: '70%',

        [theme.breakpoints.only('md')]: {
            width: '90%'
        },
        [theme.breakpoints.down('xs')]: {
            width: '80%',
            marginRight: '2rem'
        }
    },
    illu: {
        position: 'relative',
        zIndex: '1'
    },
    illuBg: {
        position: 'absolute',
    },
    illuBgBottom: {
        left: '-5%',
        bottom: '0',
        width: '28%'
    },
    illuBgTop: {
        top: '-5%',
        right: '-25%',
        width: '60%'
    }
}));

function SmallGroups(props) {
    const classes = useStyles(props);

    return (
        <section className={classes.root}>
            <article className={classes.content}>
                <div className={classes.leftColumn}>
                    <h2 className={classes.title}>SmAll GrouP <br/>big EXPerience</h2>
                    <p className={classes.description}>
                        With Travelmeister, you always travel in groups of minimum 8 and maximum 12 people. You decide
                        if you wanna travel by yourself or with some of your friends, and we will fill the open spots
                        for you.
                    </p>
                </div>
                <div className={classes.rightColumn}>
                    <div className={classes.imgWrapper}>
                        <img src={illu}  className={classes.illu} alt="Illustration of friends taking pictures in a photo booth on a group trip"/>
                        <img src={illuBgTop} alt="" className={`${classes.illuBgTop} ${classes.illuBg}`}/>
                        <img src={illuBgBottom} alt="" className={`${classes.illuBgBottom} ${classes.illuBg}`}/>
                    </div>
                </div>
            </article>
        </section>
    )

}

export default SmallGroups;