import React, {useState} from 'react';
import { makeStyles } from "@material-ui/core/styles";
import Page from "./Page";
import {Helmet} from "react-helmet";

const useStyles = makeStyles(theme => ({
    root: {
        padding: '10rem 0',

        '& h1, & h2': {
            fontFamily: 'Open Sans, sans-serif',
            fontWeight: '800'
        },
        '& h1': {
            fontSize: '2rem',
            marginBottom: '2rem'
        },
        '& h2': {
            fontSize: '1.5rem',
            transform: 'rotate(0)',
            margin: '1rem 0 0.75rem',

            '&:after': {
                display: 'none'
            }
        },
        '& ul': {
            marginBottom: '1rem'
        },
        '& li': {
            marginBottom: '0.5rem'
        }
    },
    companyInfo: {
        marginTop: '5rem'
    }
}));

function ConditionsPage(props) {
    const classes = useStyles();

    return (
        <Page>
            <Helmet>
                <title>Terms & Conditions | Travelmeister</title>
                <meta name="description"
                      content="Our Booking Terms and Conditions"
                />
            </Helmet>
            <section className={classes.root}>
                <h1>General Terms and Conditions</h1>

                <p>The following travel conditions apply to package travel contracts to which the provisions of §§ 651a ff BGB (German Civil Code) on the travel contract are directly applicable. The provisions, if effectively agreed, become the content of the package travel contract concluded between the traveler and the tour operator (hereinafter: Travelmeister). They supplement and fill out the statutory provisions of §§ 651 a to y BGB and Articles 250 and 252 EGBGB.</p>

                <h2>1. Conclusion of the contract</h2>
                <p>1.1 With the travel registration (booking), which is made via the website, the traveler offers Travelmeister the conclusion of a binding travel contract on the basis of these travel conditions, the travel description and all supplementary information in the booking basis, insofar as these are available to the traveler. Travelmeister immediately confirms the receipt of the booking. This confirmation does not yet constitute a confirmation of acceptance of the contract.</p>
                <p>1.2 The travel contract is concluded upon receipt of the travel confirmation by Travelmeister. Upon or immediately after the conclusion of the contract, Travelmeister will send the traveler a travel confirmation on a durable data carrier that complies with the legal requirements.</p>
                <p>1.3 The customer shall be liable for all contractual obligations of travelers for whom he makes the booking as for his own, insofar as he has assumed this obligation by express and separate declaration.</p>
                <p>1.4 If the content of the travel confirmation differs from the booking content, there is a new contract offer, to which Travelmeister is bound for a period of 10 days. The contract is concluded on the basis of this new offer if Travelmeister has pointed out the change with regard to the new offer and has fulfilled its pre-contractual information obligations and the traveler confirms this within the binding period by express declaration of acceptance or declares the deposit.</p>
                <p>1.5 It is pointed out that for all above-mentioned booking types, due to the legal provision of § 312 g para. 2 sentence 1 No. 9 BGB, there is no right of withdrawal for package travel contracts concluded at a distance after the conclusion of the contract. Cancellation and termination of the contract, on the other hand, are possible, taking into account the provision in section 4.</p>

                <h2>2. Payment</h2>
                <p>2.1 Payments on the tour price before the end of the tour may only be demanded and accepted if an effective customer money protection contract exists and the traveler has been handed over the protection certificate with the name and contact details of the customer money protector in a clear, understandable and highlighted manner. After the conclusion of the contract, a deposit of 20% is due for payment against the delivery of the security certificate. The remaining payment is due 30 days before the start of the trip, as far as the security certificate was handed over and the right of withdrawal of Travelmeister for the reason mentioned in paragraph 5.2 can no longer be exercised if expressly agreed in the individual case.</p>
                <p>2.2 If the traveler does not make the deposit and the payment of the remaining amount of the tour price according to the agreed payment terms, although Travelmeister is ready and able to properly provide the contractual services, has fulfilled its legal information obligations and there is no legal or contractual right of retention of the traveler, Travelmeister is entitled to withdraw from the travel contract after a reminder with a deadline and to charge the traveler with cancellation costs.</p>

                <h2>3. Change of service, Rebooking</h2>
                <p>3.1 Deviations of essential characteristics of travel services from the agreed content of the travel contract, which become necessary after the conclusion of the contract and which were not caused by Travelmeister against good faith, are only permitted before the start of the trip if the deviations are not significant and do not affect the overall design of the booked trip.</p>
                <p>3.2 Travelmeister is obliged to inform the traveler about changes in services and deviations in services immediately after knowledge of the reason for the change on a permanent data carrier in a clear, understandable, and highlighted manner.</p>
                <p>3.3 In the event of a significant change to an essential feature of a travel service or the deviation from particular specifications, the traveler is entitled within a reasonable period either to accept the change or to withdraw from the travel contract free of charge or to demand participation in a substitute trip, if Travelmeister has offered such a trip. The traveler has the choice to respond to the notification or not. If the traveler does not react to Travelmeister or does not respond within the statutory period, the notified change is deemed accepted. The traveler must be informed of this in connection with the change notification in a clear, understandable, and highlighted manner.</p>
                <p>3.4 Possible warranty claims remain unaffected insofar as the changed services are defective. Suppose Travelmeister had lower costs for the implementation of the changed trip or replacement trip with equivalent quality. In that case, the difference is to be reimbursed to the traveler in accordance with § 651 m paragraph 2 BGB.</p>
                <p>3.5 After the conclusion of the contract, the traveler has no claim to changes in the travel date, the travel destination, the place of departure, the accommodation, or the mode of transportation. This does not apply if the rebooking is necessary because Travelmeister has given no, insufficient, or incorrect pre-contractual information to the traveler according to Art. 250 § 3 EGBGB, in this case, the rebooking is possible without charge.</p>

                <h2>4 Cancellation by the customer before the start of the trip</h2>
                <p>4.1 The traveler has the option to withdraw from the travel contract at any time before the start of the trip. The withdrawal must be declared to Travelmeister. The traveler is recommended to declare the withdrawal on a durable medium.</p>
                <p>4.2 In case of withdrawal before the beginning of the trip by the traveler or if he does not start the trip, Travelmeister is entitled to appropriate compensation for the travel arrangements made and the expenses, as far as the withdrawal is not the responsibility of Travelmeister or extraordinary circumstances occur at the destination or in its immediate vicinity, which significantly affect the implementation of the trip or the transportation of persons to the destination. According to § 651 h paragraph 3 sentence 2 BGB, circumstances are unavoidable and extraordinary if they are not within the control of the tour operator and their consequences could not have been avoided even if all reasonable precautions had been taken. The amount of compensation is calculated according to the travel price less the value of the saved costs of Travelmeister as well as less what Travelmeister acquires through other use of the travel service. The compensation is calculated according to the time of receipt of the cancellation notice in a percentage ratio to the travel price as follows:</p>

                <ul>
                    <li>Up to the 31st day before departure 0%,</li>
                    <li>from the 30th day before departure 20%,</li>
                    <li>from the 24th day before departure 30%,</li>
                    <li>from the 17th day before departure 50%,</li>
                    <li>from the 10th day before departure 80%,</li>
                    <li>from the 3rd day before departure until the day of departure or in case of a no-show 90% of the tour price.</li>
                </ul>


                <p>4.3 The traveler reserves the right to prove that the reasonable compensation to which Travelmeister is entitled is significantly lower than the compensation lump sum demanded by him.</p>
                <p>4.4 Travelmeister reserves the right to claim a higher, individually calculated compensation instead of the above lump sums if the tour operator proves that it has incurred significantly higher expenses than the applicable lump sum. In this case, Travelmeister is obligated to specifically quantify and justify the demanded compensation taking into account the saved expenses as well as less what it acquires through other use of the travel service.</p>
                <p>4.5 Travelmeister is obliged as a result of a withdrawal to refund the travel price immediately but in any case within 14 days after receipt of the withdrawal notice.</p>
                <p>4.6 Within a reasonable period of time, the traveler may declare on a durable medium that a third party will enter into the rights and obligations under the travel contract instead of him. The declaration is in any case timely if it is received by Travelmeister no later than 7 days before the start of the trip. Travelmeister may object to the entry of the third party if it does not fulfill the contractual travel requirements. If a third party enters into the contract, he and the traveler are liable to Travelmeister as joint debtors for the travel price and the resulting additional costs. Travelmeister may only demand reimbursement of additional costs if and insofar as these are reasonable and actually incurred by it. Travelmeister must provide the traveler with proof of the amount of additional costs incurred due to the entry of the third party. In this case, the above-mentioned cancellation fees apply.</p>
                <p>4.7 Travelmeister recommends the conclusion of a travel cancellation insurance.</p>


                <h2>5 Cancellation and termination by Travelmeister</h2>
                <p>5.1 Travelmeister may terminate the travel contract without notice if the Guest, notwithstanding a warning by Travelmeister, persistently disturbs or if he behaves in a manner contrary to the contract to such an extent that the immediate cancellation of the contract is justified. This does not apply if the behavior contrary to the contract is causally based on a violation of the information obligations of Travelmeister. If Travelmeister cancels the contract, it retains the claim to the travel price; however, it must take into account the value of the saved expenses as well as those advantages that it gains from another use of the unused service, including the amounts credited by the service providers.</p>
                <p>5.2 Travelmeister can only withdraw from the travel contract due to failure to reach the minimum number of participants in accordance with the following provisions:<br/><br/>
                The minimum number of participants is quantified in the tour description as well as the time by which the declaration must be received by the traveler before the contractually agreed start of the tour at the latest, and the minimum number of participants, as well as the latest cancellation deadline, is stated in the booking confirmation. Travelmeister is obligated to immediately declare the cancellation of the trip to the traveler or the group commissioning party as its representative, if it is determined that the trip will not be carried out due to failure to reach the minimum number of participants. If it is already apparent at an earlier point in time that the minimum number of participants cannot be reached, Travelmeister must immediately exercise its right of cancellation. If the trip is not carried out, for this reason, Travelmeister must immediately, but in any case within 14 days after receipt of the notice of withdrawal, refund payments made by the customer on the tour price.</p>


                <h2>6. Not used services</h2>
                <p>If the traveler does not make use of individual travel services, which Travelmeister was willing and able to provide in accordance with the contract, for reasons attributable to the traveler, the traveler has no right to a proportional refund of the travel price, insofar as such reasons would not have entitled the traveler to withdraw free of charge or to terminate the travel contract in accordance with the statutory provisions. Travelmeister will strive for reimbursement of the saved expenses by the service providers. This obligation does not apply if the expenses are completely insignificant.</p>


                <h2>7. Obligations of the traveler to cooperate</h2>
                <p>7.1 If the travel is not provided free of travel defects, the traveler may demand a remedy. As far as Travelmeister could not provide remedy due to a culpable omission of the notice of defects, the traveler can generally neither assert claims for reduction according to § 651 m BGB nor claims for damages according to § 651 n BGB. The traveler is obliged to give his notice of defects immediately to the representative of Travelmeister on-site. If a representative of Travelmeister is not available on-site and is not contractually owed, any travel defects must be brought to the attention of Travelmeister at the notified contact point; the availability of the representative of Travelmeister or the contact point on site is informed in the travel confirmation. The representative of Travelmeister is instructed to take remedial action if this is possible. However, he is not authorized to recognize claims.</p>
                <p>7.2 If the stay is significantly impaired due to a defect of the kind described in § 651 i paragraph 2 BGB, the traveler may terminate the contract in accordance with § 651 l BGB. The termination is only permissible if Travelmeister has allowed a reasonable deadline set by the traveler to elapse without remedial action. Setting a deadline is not required if a remedy is impossible or refused by Travelmeister or if the termination of the contract is justified by a special interest of the traveler.</p>
                <p>7.3 Travelmeister refers to the duty of assistance according to § 651 q BGB, according to which the traveler in the case of § 651 k paragraph 4 BGB or for other reasons in difficulty is to be guaranteed immediate assistance in an appropriate manner, in particular by providing suitable information about health services, local authorities and consular assistance, assistance in establishing long-distance communication links and assistance in finding other travel options. In this regard, Section 651 k (3) of the German Civil Code shall remain unaffected.</p>


                <h2>8. Liability of the tour operator</h2>
                <p>8.1 The contractual liability of Travelmeister for damages, which are not bodily injuries and were not culpably caused, is limited to three times the travel price. Possible further claims according to international agreements or legal regulations based on such agreements remain unaffected by the limitation.</p>
                <p>8.2 Travelmeister is not liable for service disruptions, personal injury, and property damage in connection with services that are only mediated as external services, if these services are expressly and clearly marked as external services in the travel description and the travel confirmation, stating the identity and address of the mediated contractual partner so that they are not recognizable for the traveler as part of the package tour of Travelmeister and were selected separately. The §§ 651 b, 651 c, 651 w and 651 y BGB remain unaffected.</p>
                <p>8.3 Travelmeister is liable, however, if and to the extent that a damage to the traveler was caused by the violation of information, education, or organizational obligations of Travelmeister.</p>


                <h2>9. Assertion of claims, the statute of limitations, and information about consumer dispute resolution.</h2>
                <p>9.1 Contractual claims according to § 651 i Abs. 3 Nr. 2 to 7 BGB have to be asserted by the traveler against Travelmeister. It is recommended to assert claims on a durable data medium.</p>
                <p>9.2 Contractual claims become time-barred according to § 651 j BGB after two years. The limitation period begins with the day on which the trip should end according to the contract.</p>
                <p>9.3 Travelmeister points out with regard to the law on consumer dispute resolution that Travelmeister does not participate in a voluntary consumer dispute resolution. If a consumer dispute resolution would become mandatory for Travelmeister after the printing of these travel conditions, Travelmeister will inform the traveler about this in an appropriate form. Travelmeister refers to the European online dispute resolution platform http://ec.europa.eu/consumers/odr/ for all travel contracts concluded in electronic legal transactions.</p>


                <h2>10. Passport, Visa and Health Regulations</h2>
                <p>Travelmeister is responsible for informing the traveler about general provisions of passport, visa, and health regulations of the destination country, including the deadlines for obtaining any necessary visas before the conclusion of the contract, as well as any changes to them before the start of the trip. The tour operator is not liable for the timely issuance and receipt of necessary visas by the respective diplomatic representation if he was commissioned with the procurement unless Travelmeister is responsible for the delay. The traveler is responsible for obtaining and carrying the necessary travel documents and compliance with all regulations important for the implementation of the trip. All disadvantages, in particular the payment of cancellation costs, resulting from non-compliance with these regulations, are at his expense, except if they are caused by culpable misinformation or failure to provide information by Travelmeister.</p>

                <h2>11. General</h2>
                <p>11.1 The ineffectiveness of individual provisions of the contract and/or the terms of the contract does not result in the ineffectiveness of the entire contract. In such a case, the parties will bring about a supplementary, effective individual agreement that comes closest to the legal and economic sense and purpose of the invalid provisions.</p>
                <p>11.2 The place of jurisdiction for all disputes arising from the contract with merchants, legal entities under public law or special funds under public law is Berlin. The application of German law shall be deemed agreed.</p>


                <p className={classes.companyInfo}>
                    Management: Alejandro David Duarte Raposo<br/>
                    Singerstrasse 33<br/>
                    10243 Berlin<br/>
                    Phone: +49 (0) 173 286 31 25<br/>
                    E-mail: contact@travelmeister.eu<br/>
                    <a href="https://travelmeister.eu/">https://travelmeister.eu/</a><br/>
                    <br/>
                    Status: 09.06.2022
                </p>

            </section>
        </Page>
    );
}

export default ConditionsPage;