const colors = {
    white: 'rgb(255,255,255)',
    fontGrey: '#666',
    lightBgGrey: '#f6f6f6',
    green: 'rgb(25, 245, 155)',
    orange: 'rgb(255, 170, 60)',
    yellow: 'rgb(255, 240, 50)',
    blue: 'rgb(93, 204, 255)',
    purple: 'rgb(197, 68, 197)',
    red: 'rgb(238, 116, 108)',
    black: 'rgb(0,0,0)',
    lightTransparentGrey: 'rgba(255,255,255,.5)',
    lightTransparentBlack: 'rgba(0,0,0,.3)'
};

export default colors;