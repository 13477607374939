import React, {useEffect, useRef, useState} from 'react';
import makeStyles from "@material-ui/core/styles/makeStyles";
import FlatButton from "./FlatButton";
import colors from "./styles/colors";
import useInputState from "./hooks/useInputState";
import { Link } from 'react-router-dom';
import useToggleState from "./hooks/useToggleState";
import ReactPixel from "react-facebook-pixel";
import ReactGA from 'react-ga4';

const useStyles = makeStyles(theme => ({
    input: {
        borderRadius: '10rem',
        padding: '0 0 0 1.5rem',
        fontSize: '1rem',
        color: colors.black,
        width: props => props.inputWidth ? props.inputWidth : 'calc(100% - 16rem)',
        border: 'none',
        boxSizing: 'border-box',
        outline: 'none',

        '&:-internal-autofill-selected': {
            background: `${colors.white} !important`
        },
        [theme.breakpoints.down('sm')]: {
            width: props => props.inputWidth ? props.inputWidth : 'calc(100% - 220px)',
        },
        [theme.breakpoints.down('xs')]: {
            width: props => props.inputWidth ? props.inputWidth : 'calc(100% - 140px)',
            padding: '0 0.5rem 0 1rem',
            fontSize: '0.875rem'
        }
    },
    inputWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        width: props => props.width ? props.wrapperWidth : '100%',
        padding: '0.25rem',
        backgroundColor: colors.white,
        borderRadius: '10rem',

        [theme.breakpoints.down('xs')]: {
            width: '100%'
        },
        '& button': {
            [theme.breakpoints.down('xs')]: {
                fontSize: '0.875rem'
            }
        }
    },
    showSmUp: {
        [theme.breakpoints.down('xs')]: {
            display: 'none'
        }
    },
    showXsDown: {
        [theme.breakpoints.up('sm')]: {
            display: 'none'
        }
    },
    button: {
        [theme.breakpoints.down('xs')]: {
            padding: '0.4 1rem'
        }
    },
    form: {
        minHeight: '3rem'
    },
    privacyWrapper: {
        margin: '0.75rem 0 0 1.75rem',
        display: 'flex',

        [theme.breakpoints.down('xs')]: {
            marginLeft: '2px'
        },

        '& a': {
            textDecoration: 'underline'
        }
    },
    privacyCheckbox: {
        verticalAlign: 'top',
        margin: '5px 0.5rem 0 0',
    },
    responseWrapper: {
        position: 'absolute',
        marginTop: '1rem',
        padding: '0.6rem 1.2rem',
        borderRadius: '2rem',
        backgroundColor: 'white',
        opacity: 0,

        '&.visible': {
            opacity: 1
        },

        [theme.breakpoints.down('xs')]: {
            marginLeft: '1rem'
        },

        '& a': {
            textDecoration: 'underline'
        }
    }
}));

function NewsletterForm(props) {
    const classes = useStyles();
    const { location } = props;
    const [email, updateEmail, resetEmail] = useInputState('');
    const formRef = useRef(null);
    const responseRef = useRef(null);
    const [formSent, setFormSent] = useState(false);
    const [showResponse, setShowResponse] = useState(false);

    const handleSubmit = (e) => {
        e.preventDefault();

        if (false === formRef.current.privacy.checked) {
            responseRef.current.innerHTML = 'Please accept the privacy policy to subscribe.';
            setShowResponse(true);
            return
        }

        const email = formRef.current.querySelector('#newsletter_email').value;
        const botTest = formRef.current.querySelector('#newsletter_test_33f219c445').value;
        const emailRegex = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;

        if (emailRegex.test(email)) {
            if (location) {
                document.getElementById('mce-LOCATION').value = location;
                ReactGA.event({
                    category: "Coming Soon",
                    action: "Newsletter",
                    label: location
                });
            }
            document.getElementById('mce-EMAIL').value = email;
            document.getElementById('newsletter-TEST-33f219c445').value = botTest;
            document.getElementById('mc-embedded-subscribe').click();
        } else {
            responseRef.current.innerHTML = 'Please enter a valid Email address.';
            setShowResponse(true);
        }

        ReactPixel.track('Subscribe');
    };

    useEffect(() => {
        setErrorObserver();
        setSuccessObserver();
    }, []);

    const setErrorObserver = () => {
        const target = document.querySelector('#mce-error-response');
        const config = {
            attributes: true,
            childList: true
        };

        const observer = new MutationObserver(function(mutations) {
            mutations.forEach(function(mutation) {
                if (mutation.type === "childList") {
                    responseRef.current.innerHTML = target.innerHTML;
                    setShowResponse(true);
                }
            });
        });

        observer.observe(target, config);
    };

    const setSuccessObserver = () => {
        const target = document.querySelector('#mce-success-response');
        const config = {
            attributes: true,
            childList: true
        };

        const observer = new MutationObserver(function(mutations) {
            mutations.forEach(function(mutation) {
                if (mutation.type === "childList") {
                    responseRef.current.innerHTML = target.innerHTML;
                    setShowResponse(true);
                    setFormSent(true);
                }
            });
        });

        observer.observe(target, config);
    };

    return (
        <form
            action="https://travelmeister.us4.list-manage.com/subscribe/post?u=6d3a37f219ecb1a5ecf3caab3&amp;id=33f219c445"
            method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form"
            className={classes.form} target="_blank" noValidate onSubmit={handleSubmit} ref={formRef}
        >
            {
                !formSent &&
                    <React.Fragment>
                        <div className={classes.inputWrapper}>
                            <input
                                id="newsletter_email"
                                type="email"
                                required
                                onChange={updateEmail}
                                value={email}
                                placeholder="Email"
                                className={classes.input}
                            />
                            <div style={{position: 'absolute', left: '-5000px'}} aria-hidden="true">
                                <input type="text" name="b_6d3a37f219ecb1a5ecf3caab3_33f219c445" tabIndex="-1" defaultValue="" id="newsletter_test_33f219c445" />
                            </div>
                            <FlatButton
                                type="submit"
                                bgColor={colors.black}
                                color={colors.white}
                                smallPadding
                            >
                                {props.buttonText}
                            </FlatButton>
                        </div>
                        <div className={classes.privacyWrapper}>
                            <label htmlFor="privacy">
                                <input type="checkbox" id="privacy" name="privacy" required={true} className={classes.privacyCheckbox} />
                                Keep me updated on the latest deals and trips. <Link to="/privacy">Privacy policy</Link>
                            </label>
                        </div>
                    </React.Fragment>
            }
            <div className={`${classes.responseWrapper} ${showResponse ? 'visible': ''}`} ref={responseRef}></div>
        </form>
    );
}

export default NewsletterForm;