import {useState} from 'react';

function useInputState(initialVal) {
    const [state, setState] = useState(initialVal);

    const updateState = (e) => {
        setState(e.target.value);
    };

    const reset = (e) => {
        setState(initialVal);
    };

    return [state, updateState, reset];
}

export default useInputState;