import makeStyles from "@material-ui/core/styles/makeStyles";
import React from "react";
import bg_step_1 from "./img/how-it-works/bg_small_steps_1.svg"
import bg_step_3 from "./img/how-it-works/bg_small_steps_3.svg"
import line_1 from "./img/how-it-works/small_steps_line_1.svg"
import line_3 from "./img/how-it-works/small_steps_line_3.svg"
import number_1 from "./img/how-it-works/small_steps_number_1.svg"
import number_2 from "./img/how-it-works/small_steps_number_2.svg"
import number_3 from "./img/how-it-works/small_steps_number_3.svg"

const useStyles = makeStyles(theme => ({
    root: {
        maxWidth: '80rem',
        margin: '0 auto 18rem',

        [theme.breakpoints.down('xs')]: {
            marginBottom: '6rem'
        }
    },
    title: {
        marginBottom: '3rem',

        '@media only screen and (min-width: 960px) and (max-width: 1200px)': {
            fontSize: '3rem'
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: '2.5rem'
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: '1.5rem'
        },
        [theme.breakpoints.down('xs')]: {
            marginBottom: '1.5rem'
        }
    },
    step: {
        display: 'flex',
        alignItems: 'center',

        '&:last-child': {

            [theme.breakpoints.down('sm')]: {
                alignItems: 'flex-start'
            }
        },
        [theme.breakpoints.only('sm')]: {
            marginBottom: '1rem'
        }
    },
    leftColumn: {
        display: 'flex',
        width: '60%',
        justifyContent: 'center',

        [theme.breakpoints.down('sm')]: {
            width: '50%'
        },
        '&.step-1': {
            [theme.breakpoints.down('xs')]: {
                display: 'none'
            }
        },
        '&.step-2': {
            paddingLeft: '3%',
            justifyContent: 'flex-start',

            [theme.breakpoints.up('lg')]: {
                marginTop: '1%'
            },
            [theme.breakpoints.down('sm')]: {
                alignItems: 'flex-start',
                width: '80%'
            },
            [theme.breakpoints.down('xs')]: {
                width: '100%',
                flexDirection: 'column',
                alignItems: 'center',
                padding: 0
            },
        },
        '&.step-3': {
            [theme.breakpoints.down('xs')]: {
                display: 'none'
            }
        }
    },
    imgWrapper: {
        position: 'relative',
        width: '75%',

        '&.step-3': {
            width: '65%',
            margin: '1% 7% 0 0'
        },
    },
    bgStep1: {
        position: 'absolute',
        top: '33%',
        left: '-22%',
        width: '45%'
    },
    bgStep3: {
        position: 'absolute',
        bottom: '42%',
        left: '20%',
        width: '95%',

        [theme.breakpoints.down('sm')]: {
            left: '35%',
            bottom: '75%',
            width: '65%'
        },
        [theme.breakpoints.down('xs')]: {
            display: 'none'
        },
    },
    rightColumn: {
        position: 'relative',
        width: '30%',

        [theme.breakpoints.down('md')]: {
            width: '35%'
        },
        [theme.breakpoints.down('sm')]: {
            width: '50%'
        },
        [theme.breakpoints.down('xs')]: {
            width: '100%'
        },
        '&.step-3': {
            [theme.breakpoints.up('md')]: {
                paddingTop: '8%'
            }
        },
        '&.step-2': {
            [theme.breakpoints.down('sm')]: {
                width: '20%'
            },
            [theme.breakpoints.down('xs')]: {
                display: 'none'
            }
        }
    },
    stepTitle: {
        fontSize: '1rem',
        marginBottom: '1.5rem'
    },
    numberStep1: {
        height: '5rem',
        margin: '2rem 0',

        [theme.breakpoints.up('sm')]: {
            display: 'none'
        }
    },
    numberStep2: {
        width: '14%',
        marginRight: '4rem',

        [theme.breakpoints.down('sm')]: {
            width: '12%',
            marginTop: '5%'
        },
        '@media only screen and (max-width: 770px) and (min-width: 600.05px)': {
            marginTop: '7%'
        },
        [theme.breakpoints.down('xs')]: {
            width: 'auto',
            height: '5rem',
            margin: '2rem 0'
        }
    },
    numberStep3: {
        height: '5rem',
        margin: '2rem 0',

        [theme.breakpoints.up('sm')]: {
            display: 'none'
        }
    },
    textWrapper: {
        position: 'relative',
        zIndex: 1,

        '&.step-2': {
            width: '70%',

            [theme.breakpoints.down('xs')]: {
                width: '100%'
            }
        }
    },

}));

function SmallSteps(props) {
    const classes = useStyles(props);

    return (
        <section className={classes.root}>
            <h2 className={classes.title}>
                3 small steps for you <br/>
                1 giant lEAP for friendshiP
            </h2>
            <ul className={classes.steps}>
                <li className={classes.step}>
                    <div className={`${classes.leftColumn} step-1`}>
                        <div className={classes.imgWrapper}>
                            <img src={line_1} alt=""/>
                            <img className={classes.bgStep1} src={bg_step_1} alt=""/>
                        </div>
                    </div>
                    <div className={`${classes.rightColumn} step-1`}>
                        <img className={classes.numberStep1} src={number_1} alt=""/>
                        <h4 className={classes.stepTitle}>Get to know your co-travellers <br/>before you arrive</h4>
                        <p>
                            Once your trip has been completely booked, we set up a group chat for all of you. This
                            gives you and your fellow travelers the chance to get to know each other, even before
                            meeting in person for the first time.
                        </p>
                    </div>
                </li>
                <li className={classes.step}>
                    <div className={`${classes.leftColumn} step-2`}>
                        <img className={classes.numberStep2} src={number_2} alt=""/>
                        <div className={`${classes.textWrapper} step-2`}>
                            <h4 className={classes.stepTitle}>Let us introduce you to the group</h4>
                            <p>
                                We will take the pain out of the small talk for you. When you first meet up in your
                                group, we makesure that all of you get to know each other better –in a completely
                                non-awkward way. We promise: No name tags needed.
                            </p>
                        </div>
                    </div>
                    <div className={`${classes.rightColumn} step-2`}>

                    </div>
                </li>
                <li className={classes.step}>
                    <div className={`${classes.leftColumn} step-3`}>
                        <div className={`${classes.imgWrapper} step-3`}>
                            <img src={line_3} alt=""/>
                        </div>
                    </div>
                    <div className={`${classes.rightColumn} step-3`}>
                        <img className={classes.bgStep3} src={bg_step_3} alt=""/>
                        <img className={classes.numberStep3} src={number_3} alt=""/>
                        <div className={classes.textWrapper}>
                            <h4 className={classes.stepTitle}>Pick your favorites on the way</h4>
                            <p>
                                Throughout the entire trip, you call the shots. We design our trips in a way so that
                                you get to decide who you hang out, sit or drink with. And if you ever need some
                                quiet moments to yourself, we have got you covered too.
                            </p>
                        </div>
                    </div>
                </li>
            </ul>
        </section>
    )

}

export default SmallSteps;