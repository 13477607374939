import React from 'react';
import Page from "./Page";
import BlogCityHeader from "./BlogCityHeader";
import BlogFeaturedArticles from "./BlogFeaturedArticles";
import BlogLatestArticles from "./BlogLatestArticles";
import BlogCategory from "./BlogCategory";
import NewsletterSmall from "./NewsletterSmall";
import newsletterBg from './img/blog/bg_blog_newsletter.svg';
import newsletterBgMobile from "./img/blog/bg_blog_newsletter_mobile.svg";

function BlogCityPage(props) {
    const cityId =  props.match.params.cityId;
    const baseUrl = 'https://blog.travelmeister.eu/wp-json/wp/v2';

    return (
        <Page>
            <BlogCityHeader cityId={cityId} baseUrl={baseUrl} />
            <BlogFeaturedArticles cityId={cityId} baseUrl={baseUrl}/>
            <BlogLatestArticles cityId={cityId} baseUrl={baseUrl}/>
            <BlogCategory cityId={cityId} baseUrl={baseUrl} category="food"/>
            <BlogCategory cityId={cityId} baseUrl={baseUrl} category="bars"/>
            <BlogCategory cityId={cityId} baseUrl={baseUrl} category="nightlife"/>
            <BlogCategory cityId={cityId} baseUrl={baseUrl} category="leisure"/>
            <BlogCategory cityId={cityId} baseUrl={baseUrl} category="shopping"/>
            <NewsletterSmall background={newsletterBg} mobileBg={newsletterBgMobile}/>
        </Page>
    );
}

export default BlogCityPage;