import React, {useEffect, useState} from 'react';
import BlogArticleTeaser from "./BlogArticleTeaser";
import img from './img/berlin/cover.jpg';
import makeStyles from "@material-ui/core/styles/makeStyles";
import BlogFeaturedArticleTeaser from "./BlogFeaturedArticleTeaser";
import axios from "axios";


const useStyles = makeStyles(theme => ({
    root: {
        maxWidth: '80rem',
        margin: '0 auto 8rem',

        [theme.breakpoints.down('xs')]: {
            marginBottom: '3rem'
        }
    },
    title: {
        marginBottom: '2rem'
    },
    articleList: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-between',

        '& .column': {
            width: 'calc(33.3333% - 1rem)',
            marginBottom: '5rem',

            '&.hide': {
                display: 'none'
            },
            [theme.breakpoints.down('sm')]: {
                width: '100%',
                marginBottom: '2rem'
            }
        }
    },
    articleSidebar: {
        '& li': {
            marginBottom: '0.75rem',
            height: '7.5rem'
        }
    }
}));

function BlogLatestArticles(props) {
    const classes = useStyles();
    const {cityId, baseUrl} = props;
    const amount = props.full ? 9 : 5;
    const [articles, setArticles] = useState([]);

    useEffect(() => {
        getLatestPosts();
    }, [])

    const getLatestPosts = async () => {
        let filters = '';
        if (typeof cityId !== 'undefined') {
            const cityTagId = await getCityTagId();
            filters = `&tags=${cityTagId}`;
        }
        const url = `${baseUrl}/posts?per_page=${amount}${filters}`;

        axios.get(url)
            .then((response) => {
                setArticles(response.data);
            })
            .catch(function (error) {
                // handle error
                console.log(error);
            });
    };

    const getCityTagId = async () => {
        return axios.get(`${baseUrl}/tags?slug=${cityId}`)
            .then(response => {
                return response.data[0].id
            })
            .catch(function (error) {
                // handle error
                console.log(error);
            });
    };

    return (
        <section className={classes.root}>
            <h3 className={classes.title}>Latest Articles</h3>
            {props.full ? (
                <ul className={classes.articleList}>
                    {
                        articles.map((article, index) => (
                            <li className="column">
                                <BlogArticleTeaser
                                    img={article.acf.meta_information.article_image}
                                    title={article.acf.meta_information.title}
                                    citySlug={article.acf.meta_information.city_slug}
                                    slug={article.slug}
                                />
                            </li>
                        ))
                    }
                </ul>
            ) : (
                <ul className={classes.articleList}>
                    {
                        articles.length > 0 &&
                        <li className="column">
                            <BlogArticleTeaser
                                img={articles[0].acf.meta_information.article_image}
                                title={articles[0].acf.meta_information.title}
                                citySlug={articles[0].acf.meta_information.city_slug}
                                slug={articles[0].slug}
                            />
                        </li>
                    }
                    {
                        articles.length > 1 &&
                        <li className="column">
                            <BlogArticleTeaser
                                img={articles[1].acf.meta_information.article_image}
                                title={articles[1].acf.meta_information.title}
                                citySlug={articles[1].acf.meta_information.city_slug}
                                slug={articles[1].slug}
                            />
                        </li>
                    }
                    <li className={`column ${articles.length < 3 ? 'hide' : ''}`}>
                        <ul className={classes.articleSidebar}>
                            {
                                articles.length > 2 &&
                                <li>
                                    <BlogArticleTeaser
                                        img={articles[2].acf.meta_information.article_image}
                                        title={articles[2].acf.meta_information.title}
                                        citySlug={articles[2].acf.meta_information.city_slug}
                                        slug={articles[2].slug}
                                        inline={true}
                                    />
                                </li>
                            }
                            {
                                articles.length > 3 &&
                                <li>
                                    <BlogArticleTeaser
                                        img={articles[3].acf.meta_information.article_image}
                                        title={articles[3].acf.meta_information.title}
                                        citySlug={articles[3].acf.meta_information.city_slug}
                                        slug={articles[3].slug}
                                        inline={true}
                                    />
                                </li>
                            }
                            {
                                articles.length > 4 &&
                                <li>
                                    <BlogArticleTeaser
                                        img={articles[4].acf.meta_information.article_image}
                                        title={articles[4].acf.meta_information.title}
                                        citySlug={articles[4].acf.meta_information.city_slug}
                                        slug={articles[4].slug}
                                        inline={true}
                                    />
                                </li>
                            }
                        </ul>
                    </li>
                </ul>
            )}
        </section>
    );
}

export default BlogLatestArticles;