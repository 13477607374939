import React, {useState} from 'react';
import {makeStyles} from "@material-ui/core";
import Experience from "./Experience";
import CustomCarousel from "./CustomCarousel";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import bg from './img/trip/bg_optional.svg';

const useStyles = makeStyles(theme => ({
    root: {
        maxWidth: '80rem',
        margin: '0 auto',
        padding: '0 0 5rem'
    },
    innerWrapper: {
        position: 'relative',
        maxWidth: '85%',
        margin: '0 auto',
        padding: '3rem 0 0',

        [theme.breakpoints.down('sm')]: {
            maxWidth: '100%'
        }
    },
    header: {
        position: 'relative',
        marginBottom: '3rem',

        [theme.breakpoints.up('sm')]: {
            display: 'flex',
            justifyContent: 'space-between',
        }
    },
    teaserText: {
        [theme.breakpoints.up('sm')]: {
            width: '55%'
        }
    },
    carouselWrapper: {
        position: 'relative',

        '& .slider-slide': {
            padding:  '0 1rem',

            '&:focus': {
                outline: 'none'
            }
        },

        [theme.breakpoints.up('sm')]: {
            width: '90%',
            margin: '0 auto'
        },
        [theme.breakpoints.down('xs')]: {
            marginLeft: '-1rem'
        }
    },
    bgWrapper: {
        position: 'absolute',
        top: 0,
        width: '100%',
        height: '110%',
        backgroundSize: '90%',
        background: `url(${bg}) no-repeat`,
        backgroundPosition: 'top center',
        zIndex: 0,

        [theme.breakpoints.down('sm')]: {
            backgroundSize: '100%',
            backgroundPosition: '50% 70%'
        },
        '@media (max-width: 450px)': {
            display: 'none'
        }
    }
}));

function OptionalExperiences(props) {
    const classes = useStyles();
    const slides = [];
    const matchesTablet = useMediaQuery('(min-width: 450.05px) and (max-width: 1100px)');
    const matchesPhone = useMediaQuery('(max-width: 450px)');
    let slidesToShow = 3;

    if (matchesTablet) {
        slidesToShow = 2;
    } else if (matchesPhone) {
        slidesToShow = 1.3;
    }

    const slidesToScroll = Math.floor(slidesToShow);

    props.experiences.map(exp => {
        slides.push(
            <Experience { ...exp } cityId={props.cityId} className={classes.slide} />
        );
    });



    return (
        <section className={classes.root}  id="extras">
            <div className={classes.innerWrapper}>
                <div className={classes.bgWrapper}></div>
                <header className={classes.header}>
                    <h3 className={classes.title}>Optional Experiences</h3>
                    <p className={classes.teaserText}>
                        We cannot list all the amazing experiences the city offers – if you have a special
                        request, just write us an email or leave a comment in the booking form. We are
                        happy to organize (almost) everything for you ;-)
                    </p>
                </header>
                <div className={classes.carouselWrapper}>
                    <CustomCarousel
                        initialSlide={0}
                        infinite={false}
                        slides={slides}
                        slidesToShow={slidesToShow}
                        slidesToScroll={slidesToScroll}
                    />
                </div>
            </div>
        </section>
    );
}

export default OptionalExperiences;