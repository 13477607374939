import React, {useEffect, useRef} from 'react';
import makeStyles from "@material-ui/core/styles/makeStyles";
import lottie from 'lottie-web';
import animationData from './animations/mainMessageBgAnimation';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        maxWidth: '120rem',
        // paddingTop: '5rem',
        margin: '0 auto',

        '@media (max-width: 1280px)': {
            paddingTop: '2rem'
        },
        [theme.breakpoints.down('xs')]: {
            paddingTop: 0
        }
    },
    textWrapper: {
        position: 'relative',
        width: '80%',
        margin: '0 auto 16rem',
        padding: '7rem 0 6rem',

        [theme.breakpoints.down('md')]: {
            width: '85%',
            marginBottom: '1rem'
        },
        [theme.breakpoints.down('sm')]: {
            width: '85%',
        },
        [theme.breakpoints.down('xs')]: {
            width: '95%',
            margin: '0 auto',

        }
    },
    text: {
        position: 'relative',
        margin: 0,
        textAlign: 'center',
        fontSize: '2.9rem',
        lineHeight: 1.6,
        zIndex: 1,

        '@media (max-width: 1679.95px)': {
            fontSize: '2.6rem'
        },
        [theme.breakpoints.down('md')]: {
            fontSize: '2.2rem'
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: '1.4rem'
        },
        '@media (max-width: 399.95px)': {
            fontSize: '1.3rem'
        }
    },
    backgroundWrapper: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        width: '100%',
        height: '100%',
        transform: 'translate(-50%, -50%)',
        zIndex: 0,
    },
    animationWrapper: {
        width: '100%',
        height: '100%',

        [theme.breakpoints.down('sm')]: {
            width: '120%',
            height: '60%',
            marginTop: '25%',
            marginLeft: '-10%'
        }
    }
}));

function MainMessage(props) {
    const classes = useStyles();
    const ref = useRef(null);

    useEffect(() => {
        const animation = lottie.loadAnimation({
            container: document.getElementById('animation-bg'), // the dom element that will contain the animation
            renderer: 'svg',
            loop: true,
            autoplay: true,
            animationData: animationData,
            rendererSettings: {
                preserveAspectRatio: 'none'
            }
        });
    }, []);

    return (
        <section className={classes.root}>
            <div className={classes.textWrapper}>
                <h3 className={classes.text}>
                    The best way to discover new places is by making a real connection.
                    Travelmeister brings together like-minded co-travelers and local
                    insiders, turning every trip into a unique and authentic experience.
                    Skip the tourist attractions and immerse yourself in your location
                    while sharing exciting moments with new friends!
                </h3>
                <div className={classes.backgroundWrapper}>
                    <div className={classes.animationWrapper} id="animation-bg" ref={ref}></div>
                </div>
            </div>
        </section>
    );
}

export default MainMessage;