import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import boxShadows from './styles/boxShadows';
import colors from "./styles/colors";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {withRouter, Link} from 'react-router-dom';
import FlatButton from "./FlatButton";
import OutlinedButton from "./OutlinedButton";

const useStyles = makeStyles(theme => ({
    root: {
        position: 'relative',
        paddingTop: '145%',
        zIndex: 1,
        backgroundColor: colors.white,
        boxShadow: boxShadows.normal,

        '&:hover': {
            cursor: 'pointer',
            boxShadow: boxShadows.hover
        },
        [theme.breakpoints.down('sm')]: {
            paddingTop: '145%',
            marginBottom: '1.5rem',
        },
        [theme.breakpoints.down('xs')]: {
            paddingTop: '140%',
            marginBottom: '1rem'
        },
        '@media (max-width: 319.95px)':  {
            paddingTop: '140%'
        }
    },
    innerWrapper: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        padding: '1.4rem',

        [theme.breakpoints.down('md')]: {
            padding: '1.25rem',
        },

        [theme.breakpoints.down('xs')]: {
            // position: 'relative',
            padding: '1.1rem'
        },
    },
    imgWrapper: {
        width: '100%',
        overflow: 'hidden',
        justifyContent: 'center',
        margin: 0,
        height: '55%',
        marginBottom: '.8rem',

        '@media (min-width: 600px) and (max-width: 767.95px)':  {
            marginBottom: '0.5rem'
        },
        [theme.breakpoints.down('xs')]: {
            // height: '53%',
            // marginBottom: '0.5rem'
        },
    },
    img: {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
    },
    title: {
        fontWeight: 800,
        marginBottom: '0.75rem',
        fontSize: '1.5rem',

        '@media (max-width: 1300px)': {
            marginBottom: '0.4rem'
        },
        '@media (min-width: 600px) and (max-width: 1199.95px)': {
            fontSize: '1.2rem',
            marginBottom: '0.3rem'
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: '1.1rem',
            // marginBottom: '0.75rem'
        },
        '@media (max-width: 335px)':  {
            fontSize: '1rem'
        }
    },
    summary: {
        fontSize: '0.875rem',

        '@media (min-width: 960px) and (max-width: 1199.95px)': {
            fontSize: '.8rem'
        },
        [theme.breakpoints.down('xs')]: {
            // display: 'none',
            // marginBottom: 0
            fontSize: '.875rem'
        },
        '@media (max-width: 360px)':  {
            fontSize: '.8rem'
        }
    },
    teaserText: {
        lineHeight: 1.5,

        '@media (max-width: 1369.95px)': {
            display: 'none'
        },
        [theme.breakpoints.down('xs')]: {
            // fontSize: '0.875rem'
        }
    },
    teaserTextShort:{
        lineHeight: 1.5,

        '@media (max-width: 319.95px)':  {
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden'
        }
    },
    bottomWrapper: {
        position: 'absolute',
        bottom: 0,
        left: 0,
        width: '100%',

        [theme.breakpoints.down('xs')]: {
            // position: 'relative'
        },
    },
    moreWrapper: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-end',
        padding: '0 1.4rem 1.4rem 1.4rem',

        [theme.breakpoints.down('md')]: {
            padding: '0 1.25rem 1.25rem 1.25rem',
        },
        [theme.breakpoints.down('xs')]: {
            '& button': {
                display: 'none'
            }
        },
    },
    detailsWrapper: {
        display: 'flex',
        justifyContent: 'flex-end',

        '@media (min-width: 600px) and (max-width: 767.95px)':  {
            // display: 'none'
        },
        '@media (max-width: 335px)':  {
            width: '100%',
            // justifyContent: 'space-between'
        }
    },
    detail: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        lineHeight: 1.4,

        [theme.breakpoints.down('xs')]: {
            flexDirection: 'row'
        },

        '&:first-child': {
            [theme.breakpoints.up('sm')]: {
                marginRight: '1rem',
            },
            [theme.breakpoints.down('xs')]: {
                marginRight: '1rem',
                flexDirection: 'row-reverse'
            },
            '@media (min-width: 600px) and (max-width: 767.95px)':  {
                marginRight: '.75rem'
            }
        },
    },
    detailLabel: {
        [theme.breakpoints.down('md')]: {
            fontSize: '0.875rem',
        },
        [theme.breakpoints.down('xs')]: {
            marginRight: '0.375rem'
        },
    },
    detailValue: {
        fontWeight: 800,
        fontSize: '1.1rem',

        [theme.breakpoints.down('md')]: {
            fontSize: '1rem'
        },
        [theme.breakpoints.down('xs')]: {
            marginRight: '0.25rem'
        }
    },
    comingSoon: {
        margin: 0,
        fontSize: '0.875rem',
        fontWeight: 800
    }
}));

function TripTeaserComingSoon(props) {
    const basePath = `./img/`;
    const {id, slug, coverImgAltText, name, teaserText, teaserTextShort, duration, minPrice} = props;
    const url = `/coming-soon/${props.slug}`;
    const classes = useStyles(props);
    const buttonProps = {
        bgColor: colors.black,
        color: colors.white,
        noHover: true
    };

    const handleClick = (e) => {
        e.preventDefault();
        e.stopPropagation();
        props.history.push(url);
    };

    return (
        <article className={classes.root} onClick={handleClick}>
            <div className={classes.innerWrapper}>
                <figure className={classes.imgWrapper}>
                    <picture>
                        <source
                            src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
                            data-srcset={`${require(`${basePath}${slug}/teaser_small.webp`)} 300w, ${require(`${basePath}${slug}/teaser_large.webp`)} 600w`}
                            type="image/webp"
                        />
                        <source
                            src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
                            data-srcset={`${require(`${basePath}${slug}/teaser_small.jpg`)} 300w, ${require(`${basePath}${slug}/teaser_large.jpg`)} 600w`}
                            type="image/jpeg"
                        />
                        <img
                            src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
                            data-src={require(`${basePath}${slug}/teaser_large.jpg`)}
                            alt={coverImgAltText}
                            className={`lazyload ${classes.img}`}
                            data-sizes="auto"
                        />
                    </picture>
                </figure>
                <h3 className={classes.title}>
                    <Link to={url} onClick={handleClick}>{name}</Link>
                </h3>
                <summary className={classes.summary}>
                    {/*<p className={classes.teaserText}>*/}
                    {/*    If you are interested in this trip register to get more info about new trips coming soon!*/}
                    {/*</p>*/}
                    <p className={classes.teaserTextShort}>
                        If you are interested in this trip subscribe now to get more info about new adventures coming soon!
                    </p>
                </summary>
                <div className={classes.bottomWrapper}>
                    <div className={classes.moreWrapper}>
                        <OutlinedButton {...buttonProps}>More</OutlinedButton>
                        <p className={classes.comingSoon}>Coming soon!</p>
                    </div>
                </div>
            </div>
        </article>
    );
}

export default withRouter(TripTeaserComingSoon);