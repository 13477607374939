import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {BrowserRouter, Route} from "react-router-dom";
import ScrollToTop from "./ScrollToTop";
import ReactGA from 'react-ga4';
import ReactPixel from "react-facebook-pixel";
import JSCookies from "js-cookie";

ReactGA.initialize([
    {
        trackingId: "G-5WLHF99WBR",
        gaOptions: {
            'anonymize_ip': true,
            'linker': {
                'domains': ['travelmeister.eu', 'travelmeister.bookinglayer.com']
            }
        },
        gtagOptions: {
            'anonymize_ip': true
        }
    }]);

const fbpOptions = {
    autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
    debug: false, // enable logs
};
ReactPixel.init('1121550655376582', fbpOptions);

if (JSCookies.get('TMCookieConsent') === undefined) {
    ReactPixel.revokeConsent();
}

ReactPixel.pageView(); // For tracking page view
// ReactPixel.track(event, data); // For tracking default events. More info about standard events: https://developers.facebook.com/docs/facebook-pixel/implementation/conversion-tracking#standard-events
// ReactPixel.trackSingle('PixelId', event, data); // For tracking default events.
// ReactPixel.trackCustom(event, data); // For tracking custom events. More info about custom events: https://developers.facebook.com/docs/facebook-pixel/implementation/conversion-tracking#custom-events
// ReactPixel.trackSingleCustom('PixelId', event, data); // For tracking custom events.

ReactDOM.render(
    <div>
        <BrowserRouter>
            <ScrollToTop />
            <Route component={App} />
        </BrowserRouter>
    </div>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
