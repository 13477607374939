import React from 'react';
import makeStyles from "@material-ui/core/styles/makeStyles";
import illu from './img/blog/illu_blog.svg';
import bg from './img/blog/bg_blog_home_header.svg';
import underline from './img/underline.svg';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        height: '80vh',
        maxWidth: '80rem',
        margin: '0 auto',
        justifyContent: 'flex-end',
        alignItems: 'center',

        [theme.breakpoints.down('sm')]: {
            height: '70vh'
        },
        [theme.breakpoints.down('xs')]: {
            height: 'auto',
            paddingTop: '5rem',
            marginBottom: '3rem',
            flexWrap: 'wrap'
        }
    },
    leftColumn: {
        position: 'relative',
        width: '50%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        zIndex: 2,
        marginRight: '2rem',

        [theme.breakpoints.down('sm')]: {
            width: '70%'
        },
        [theme.breakpoints.down('xs')]: {
            width: '100%',
            margin: 0
        }
    },
    title: {
        position: 'relative',
        fontSize: '4rem',
        transform: 'rotate(-3deg)',
        marginBottom: '5rem',
        width: '80%',

        [theme.breakpoints.down('md')]: {
            fontSize: '3rem'
        },
        [theme.breakpoints.down('xs')]: {
            width: '90%',
            fontSize: '2.5rem',
            marginBottom: '3rem'
        },
        '@media (max-width: 335px)': {
            fontSize: '2.3rem',
        },

        '&::after': {
            content: '\"\"',
            position: 'absolute',
            left: 0,
            bottom: '-0.4rem',
            width: '85%',
            height: '0.5rem',
            backgroundImage: `url(${underline})`,
            backgroundRepeat: 'no-repeat',

            [theme.breakpoints.down('xs')]: {
                bottom: 0,
                height: '0.3rem'
            },
        }
    },
    description: {
        width: '80%',
        marginBottom: '3rem',

        [theme.breakpoints.down('xs')]: {
            width: '100%',
            marginBottom: '2rem'
        }
    },
    rightColumn: {
        position: 'relative',
        width: '40%',
        height: '100%',
        marginRight: '-1rem',

        [theme.breakpoints.down('xs')]: {
            display: 'none'
        }
    },
    illu: {
        position: 'absolute',
        bottom: '20%',
        left: '-37%',
        height: '70%',
        zIndex: 1,

        [theme.breakpoints.down('xs')]: {
            position: 'relative',
            width: '80%',
            left: '-5%',
            height: 'auto'
        }
    },
    illuBg: {
        position: 'absolute',
        right: '5%',
        bottom: '10%',
        height: '60%',
        zIndex: 0,

        [theme.breakpoints.down('xs')]: {
            width: '80%',
            height: 'auto',
            right: 0,
            bottom: '-20%',
        }
    },
    mobileIlluWrapper: {
        position: 'relative',
        width: '90%',
        marginBottom: '5rem',

        [theme.breakpoints.up('sm')]: {
            display: 'none'
        }
    }
}));

function BlogHeader(props) {
    const classes = useStyles();

    return (
        <header className={classes.root}>
            <div className={classes.leftColumn}>
                <h1 className={classes.title}>Welcome to our fabulous Blog!</h1>
                <div className={classes.mobileIlluWrapper}>
                    <img src={bg} alt="" className={classes.illuBg}/>
                    <img src={illu} alt="Illustration of a Guy with a giant pencil" className={classes.illu}/>
                </div>
                <p className={classes.description}>
                    Searching the best insider tips from locals for you every day. Restaurants, bars, culture, and
                    much more… Everything worth knowing about our destinations, you'll find here.
                </p>
            </div>
            <div className={classes.rightColumn}>
                <img src={bg} alt="" className={classes.illuBg}/>
                <img src={illu} alt="Illustration of a Guy with a giant pencil" className={classes.illu}/>
            </div>
        </header>
    );
}

export default BlogHeader;