import React, {useEffect, useState} from 'react';
import TripTeaser from './TripTeaser';
import {makeStyles} from "@material-ui/core/styles";
import FlatButton from './FlatButton';
import {withRouter} from 'react-router-dom';
import {ReactComponent as Line} from './img/benefits/benefits_line_4.svg';

import trips from './data/tripData';

import background from './img/bg_trips.svg';
import colors from './styles/colors';
import Fab from "@material-ui/core/Fab";
import arrowLeft from "./img/arrow_left_white.svg";
import arrowRight from "./img/arrow_right_white.svg";
import TripComingSoonTeaser from "./TripTeaserComingSoon";

const useStyles = makeStyles(theme => ({
    root: {
        position: 'relative',
        padding: '0 2rem',
        maxWidth: '74rem',
        margin: '0 auto 20rem',
        color: colors.black,

        [theme.breakpoints.down('md')]: {
            padding: 0,
            marginBottom: '14rem',
            marginTop: '5rem'
        },
        [theme.breakpoints.down('xs')]: {
            marginBottom: '8rem'
        }
    },
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        flexWrap: 'wrap',

        [theme.breakpoints.up('lg')]: {
            marginBottom: '2rem',
        }
    },
    titleWrapper: {
        width: '50%',
        textAlign: 'center',

        [theme.breakpoints.down('sm')]: {
            width: '100%',
            marginBottom: '5rem',
            textAlign: 'left',
        },
        [theme.breakpoints.down('xs')]: {
            marginBottom: '3rem',
        }
    },
    title: {
        textAlign: 'center',
        transform: 'rotate(-3deg)',

        '@media (max-width: 1200px)': {
            fontSize: '3.25rem'
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: '2.5rem'
        }
    },
    headerDescriptionWrapper: {
        width: '45%',

        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
        [theme.breakpoints.down('xs')]: {
            marginBottom: '2rem'
        }
    },
    description: {
        marginBottom: '3rem',

        '@media (max-width: 1024px)': {
            marginBottom: '2rem'
        },
    },
    scrollBoxWrapper: {
        position: 'relative',
        margin: '0 -1rem'
    },
    scrollBox: {
        position: 'relative',
        overflow: 'hidden'
    },
    scrollBoxItem: {
        width: 'calc(30%)',
        zIndex: 1,
        marginRight: '1rem',
        flexShrink: 0,

        '&:last-child': {
            marginRight: 0
        },
        [theme.breakpoints.down('sm')]: {
            width: '40%'
        },
        '@media (max-width: 800px)': {
            width: '60%'
        },
        '@media (max-width: 600px)': {
            width: '70%'
        },
        '@media (max-width: 450px)': {
            width: '82%'
        },
        '@media (max-width: 350px)': {
            width: '85%'
        },
    },
    arrowBtn: {
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-52%)',
        backgroundColor: colors.black,
        zIndex: 2,

        '&:hover': {
            backgroundColor: colors.black,
        },

        [theme.breakpoints.down('xs')]: {
            display: 'none'
        }
    },
    next: {
        right: '-1.75rem',

        [theme.breakpoints.down('sm')]: {
            right: '-2rem'
        }
    },
    prev: {
        left: '-1.75rem',

        [theme.breakpoints.down('sm')]: {
            left: '-2rem'
        }
    },
    arrow: {
        width: '26%',
    },
    tripsWrapper: {
        display: 'flex',
        padding: '6rem 1.5rem 8rem',
        alignItems: 'flex-start',
        overflowX: 'scroll',

        [theme.breakpoints.down('xs')]: {
            padding: '3rem 1.5rem'
        },
        '@media (max-width: 400px)': {
            padding: '2rem 1.25rem'
        }
    },
    tripsBg: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        zIndex: 0,

        [theme.breakpoints.up('sm')]: {
            left: '-2rem',
            width: 'calc(100% + 4rem)',
        },
        [theme.breakpoints.down('xs')]: {
            // left: '-10px',
            // width: 'calc(100% + 20px)',
            display: 'none'
        }
    },
    animationLine: {
        display: 'block',
        width: '27%',
        transform: 'translateX(-11rem)',
        margin: '-18rem auto 3.5rem',

        [theme.breakpoints.down('md')]: {
            margin: '-3rem auto 3rem'
        },
        '@media (min-width: 960px) and (max-width: 1024px)': {
            margin: '-3rem auto 3rem',
            transform: 'translateX(-8rem)',
        },

        '& .path': {
            strokeDasharray: 1000,
            animation: '$dash 3s linear'
        },
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        }
    },
}));

function Trips(props) {
    const classes = useStyles();

    let tripsArray = [];

    for(let i in trips) {
        tripsArray.push(trips[i]);
    }

    const handleClick = (e) => {
        e.preventDefault();
        props.history.push(`/trips`);
    };

    let scrollBox = null;
    let tripItems = null;
    let initialPadding = 0;

    useEffect(() => {
        scrollBox = document.querySelectorAll('.js-scrollbox')[0];
        tripItems = scrollBox.querySelectorAll('.js-scrollbox-item');
        initialPadding = tripItems[0].offsetLeft;
        setTimeout(hideScrollBars, 1000);
    });

    const handleScroll = (scrollAmount) => {
        scrollBox.scrollBy({left: scrollAmount, behavior: 'smooth'});
    };

    const getCurrentImageIndex = () => {
        const scrollLeft = scrollBox.scrollLeft;

        return Math.round(scrollLeft / tripItems[0].offsetWidth);
    };

    const scrollRight = () => {
        let index = getCurrentImageIndex();
        index += 2;
        index = index >= tripItems.length ? tripItems.length - 1 : index;
        let scrollAmount = tripItems[index].offsetLeft - scrollBox.scrollLeft - initialPadding;

        handleScroll(scrollAmount);
    };

    const scrollLeft = () => {
        let index = getCurrentImageIndex();
        index -= 2;
        index = index < 0 ? 0 : index;
        let scrollAmount =  (scrollBox.scrollLeft - tripItems[index].offsetLeft + initialPadding) * -1;

        handleScroll(scrollAmount);
    };

    const hideScrollBars = () => {
        if (scrollBox.offsetHeight > scrollBox.clientHeight) {
            scrollBox.style.paddingBottom = '15px';
        }
    };

    const updateArrows = () => {
        if (scrollBox.scrollLeft > 0) {
            setLeftArrowState(true);
        }

        if (scrollBox.scrollLeft === 0) {
            setLeftArrowState(false);
        }

        if ((Math.ceil(scrollBox.scrollLeft)+1) >= (scrollBox.scrollWidth - scrollBox.offsetWidth)) {
            setRightArrowState(false);
        }

        if ((Math.ceil(scrollBox.scrollLeft)+1) < (scrollBox.scrollWidth - scrollBox.offsetWidth)) {
            setRightArrowState(true);
        }
    };

    const [showLeftArrow, setLeftArrowState] = useState(false);
    const [showRightArrow, setRightArrowState] = useState(true);

    return (
        <section className={classes.root}>
            <Line className={classes.animationLine} />
            <header className={classes.header}>
                <div className={classes.titleWrapper}>
                    <h2 className={classes.title}>
                        Decide where your
                        <span className="line-break">adventure starts!</span>
                    </h2>
                </div>
                <div className={classes.headerDescriptionWrapper}>
                    <p className={classes.description}>
                        Select your favorite destination and start exploring the world with new friends and our local
                        Travelmeisters.
                    </p>
                    <FlatButton bgColor={colors.black} color={colors.white} className={classes.button} onClick={handleClick}>
                        See all trips
                    </FlatButton>
                </div>
            </header>
            <div className={classes.scrollBoxWrapper}>
                <div className={classes.scrollBox} onScroll={updateArrows}>
                    <ul className={`${classes.tripsWrapper} js-scrollbox`}>
                        {tripsArray.map((trip, index) => {
                            if (trip.active) {
                                return (
                                    <li className={`${classes.scrollBoxItem} js-scrollbox-item`} key={index}>
                                        <TripTeaser
                                            key={trip.id}
                                            // type={index === 0 ? 'big' : 'small'}
                                            // type={'big'}
                                            {...trip}
                                            index={index}
                                        />
                                    </li>
                                );
                            } else {
                                return (
                                    <li className={`${classes.scrollBoxItem} js-scrollbox-item`} key={index}>
                                        <TripComingSoonTeaser
                                            key={trip.id}
                                            type={index === 0 ? 'big' : 'small'}
                                            // type={'big'}
                                            {...trip}
                                            index={index}
                                        />
                                    </li>
                                );
                            }
                        })}
                    </ul>
                    <img src={background} alt="Orange background for decorative reasons" className={classes.tripsBg}/>
                </div>
                <Fab
                    className={`${classes.arrowBtn} ${classes.prev}`}
                    onClick={scrollLeft}
                    style={{opacity: showLeftArrow ? 1 : 0, pointerEvents: showLeftArrow ? 'inherit' : 'none'}}
                    aria-label="Swipe to previous page"
                >
                    <img className={`${classes.arrow} ${classes.arrowLeft}`} src={arrowLeft} alt=""/>
                </Fab>
                <Fab
                    className={`${classes.arrowBtn} ${classes.next}`}
                    onClick={scrollRight}
                    style={{opacity: showRightArrow ? 1 : 0, pointerEvents: showRightArrow ? 'inherit' : 'none'}}
                    aria-label="Swipe to next page"
                >
                    <img className={`${classes.arrow} ${classes.arrowRight}`} src={arrowRight} alt=""/>
                </Fab>
            </div>
        </section>
    );
}
export default withRouter(Trips);