import React from 'react';
import FlatButton from "./FlatButton";
import colors from "./styles/colors";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles(theme => ({
    bookingWrapper: {
        [theme.breakpoints.up('sm')]: {
            display: 'none'
        },

        position: 'fixed',
        bottom: 0,
        left: 0,
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        height: '10vh',
        padding: '0 1rem',
        zIndex: 1000,
        color: colors.white,
        backgroundColor: colors.black,
        transform: props => props.show ? 'translateY(0)' : 'translateY(100%)',
        transition: 'transform 200ms ease-out',

        '& .price': {
            margin: '0 1rem 0 0',
            fontWeight: 800
        }
    }
}));

function StickyBookingBar(props) {
    const classes = useStyles();
    const {price, show, bookingLink} = props;
    const translate = show ? 'translateY(0)' : 'translateY(100%)';

    return (
        <div className={classes.bookingWrapper} style={{transform: translate}}>
            <p className="price">from <span className={classes.price}>{price}</span>€</p>
            <FlatButton color={colors.black} bgColor={colors.orange} href={bookingLink}>See dates</FlatButton>
        </div>
    );
}

export default StickyBookingBar;