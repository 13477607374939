import React from 'react';
import {makeStyles} from "@material-ui/core/styles";
import hostVideoMP4 from './video/video_alex.mp4';
import hostVideoWebm from './video/teaser_video.webm';
import {isSafari} from "react-device-detect";
import posterJpeg from "./img/posterHost.jpg";
import posterWebP from "./img/posterHost.webp";

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        maxWidth: '80rem',
        margin: '0 auto',
        padding: '10rem 0 0',

        [theme.breakpoints.down('xs')]: {
            flexWrap: 'wrap',
            padding: '4rem 0 2rem'
        }
    },
    title: {
        width: '35%',

        [theme.breakpoints.down('xs')]: {
            width: '100%',
            marginBottom: '1.5rem'
        }
    },
    videoContainer: {
        width: '60%',

        [theme.breakpoints.down('xs')]: {
            width: '100%'
        }
    }
}));

function TripHost() {
    const classes = useStyles();
    const posterImg = isSafari ? posterJpeg : posterWebP;

    return (
        <section className={classes.root} id="overview">
            <h3 className={classes.title}>Meet your host</h3>
            <div className={classes.videoContainer}>
                <video width="100%" controls poster={posterImg}>
                    <source src={hostVideoMP4} type="video/mp4" />
                    <source src={hostVideoWebm} type="video/webm" />
                    Your browser does not support the video tag.
                </video>
            </div>
        </section>
    );


}

export default TripHost;