import React from 'react';
import makeStyles from "@material-ui/core/styles/makeStyles";
import colors from "./styles/colors";
import Dialog from "@material-ui/core/Dialog/Dialog";
import CloseIcon from "@material-ui/icons/Close";
import {Fab} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    root: {
        position: 'relative',
        padding: '3rem 2rem',

        [theme.breakpoints.down('xs')]: {
            padding: '2rem 1rem',
        }
    },
    priceTitle: {
        marginBottom: '1rem'
    },
    priceListWrapper: {
        '&:first-child': {
            marginBottom: '2rem'
        }
    },
    priceList: {
        paddingLeft: '1rem'
    },
    priceListElement: {
        marginBottom: '1rem',
        listStyle: 'disc'
    },
    closeBtn: {
        position: 'absolute',
        top: '1rem',
        right: '1rem',
        zIndex: 3,
        backgroundColor: '#000',

        '&:hover': {
            backgroundColor: '#000',
        },
        '& svg': {
            color: colors.white,
            fontSize: '2rem'
        },

        [theme.breakpoints.down('xs')]: {
            position: 'fixed',
            top: '0.5rem',
            right: '0.5rem'
        }
    }
}));

function PriceDetails(props) {
    const classes = useStyles();
    const { open, togglePriceDetails, priceDetailsText, activitiesPrice, guidePrice } = props;

    return (
        <Dialog open={open} maxWidth="md" onClose={togglePriceDetails}>
            <div className={classes.root}>
                <h3>Price Details</h3>
                <p>
                    We used to include more activities, as well as food and drinks, in our base price. But we realized
                    that some people may not want to participate in all activities, or one person drinks less than
                    another one, or you prefer to order a whisky instead of a beer ;-)
                    <br/><br/>
                    That’s why we now prefer to include less and decide together as a group. That doesn't mean that we
                    will fight to decide what to do, or that everybody will hate you if you don’t want to participate in
                    some activities. Our trips are still planned through, we select our activities carefully, and some
                    of them are included for everybody and listed in the “What’s included”-section. {priceDetailsText}
                    <br/><br/>
                    The money pool includes a mandatory part for your local Travelmeister, that will accompany the
                    group during all activities. The rest is optional, but we are confident that you will love the
                    experiences ;-)
                </p>
                <br/>
                <div>
                    <div className={classes.priceListWrapper}>
                        <h4 className={classes.priceTitle}>Money pool</h4>
                        <ul className={classes.priceList}>
                            <li className={classes.priceListElement}>
                                {guidePrice}€ for the guide (not optional)
                            </li>
                            <li className={classes.priceListElement}>
                                {activitiesPrice}
                            </li>
                        </ul>
                    </div>
                    <div className={classes.priceListWrapper}>
                        <h4 className={classes.priceTitle}>Deposit</h4>
                        <ul className={classes.priceList}>
                            <li className={classes.priceListElement}>
                                20% of the base price now to reserve your spot
                            </li>
                            <li className={classes.priceListElement}>
                                80% of the base price 30 days before the trip starts
                            </li>
                        </ul>
                    </div>
                </div>
                <Fab className={classes.closeBtn} color={colors.black} onClick={togglePriceDetails}>
                    <CloseIcon/>
                </Fab>
            </div>
        </Dialog>
    );
}

export default PriceDetails;