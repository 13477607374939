import React from 'react';
import Page from "./Page";
import PageHeader from "./PageHeader";
import TripsList from "./TripsList";
import NewDestinationForm from "./NewDestinationForm";
import NewsletterSmall from "./NewsletterSmall";
import newsletterBg from './img/trips/bg_newsletter_trips.svg';
import newsletterBgMobile from './img/trips/bg_newsletter_trips_mobile.svg';
import {Helmet} from "react-helmet";

import headerImgJpegSmall from "./img/trips/trips_header_small.jpg";
import headerImgJpegMedium from "./img/trips/trips_header_medium.jpg";
import headerImgJpegLarge from "./img/trips/trips_header_large.jpg";
import headerImgWebpSmall from "./img/trips/trips_header_small.webp";
import headerImgWebpMedium from "./img/trips/trips_header_medium.webp";
import headerImgWebpLarge from "./img/trips/trips_header_large.webp";

const pageData = {
    headerImg: {
        sources: {
            jpeg: {
                small: headerImgJpegSmall,
                medium: headerImgJpegMedium,
                large: headerImgJpegLarge
            },
            webp: {
                small: headerImgWebpSmall,
                medium: headerImgWebpMedium,
                large: headerImgWebpLarge
            }
        },
        altText: 'Friends playing volleyball at the beach'
    }
};

function TripsOverviewPage(props) {
    return (
        <Page>
            <Helmet>
                <title>Travelmeister Trips | Decide where your adventure starts!</title>
                <meta name="description"
                      content="Set out to discover the world in a local way! Choose from different locations all over
                      Europe for your new and authentic experience."
                />
            </Helmet>
            <PageHeader headerImg={pageData.headerImg} objectPos="50% 30%" />
            <TripsList />
            <NewDestinationForm/>
            <NewsletterSmall background={newsletterBg} mobileBg={newsletterBgMobile}/>
        </Page>
    );
}

export default TripsOverviewPage;