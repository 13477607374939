import React, {useEffect, useState} from 'react';
import makeStyles from "@material-ui/core/styles/makeStyles";
import { navHeight } from './NavBar';
import FlatButton from "./FlatButton";
import colors from "./styles/colors";
import _ from "lodash";
import StickyDetailsAndNav from "./StickyDetailsAndNav";
import StickyBookingBar from "./StickyBookingBar";
import useToggleState from "./hooks/useToggleState";
import Dialog from "@material-ui/core/Dialog";
import NewsletterForm from "./NewsletterForm";
import infoIcon from "./img/information.png"
import ReactPixel from "react-facebook-pixel";
import {useMediaQuery} from "@material-ui/core";
import DepositText from "./DepositText";
import PriceDetails from "./PriceDetails";

const useStyles = makeStyles(theme => ({
    root: {

        [theme.breakpoints.up('lg')]: {
            height: `calc(100vh - ${navHeight.xl})`,
        },
        [theme.breakpoints.only('md')]: {
            height: `calc(100vh - ${navHeight.md})`,
        },
        [theme.breakpoints.down('xs')]: {
            height: 'auto'
        }
    },
    imgWrapper: {
        [theme.breakpoints.up('md')]: {
            height: '75%'
        },
        [theme.breakpoints.down('sm')]: {
            marginBottom:  '2rem'
        },
        [theme.breakpoints.down('xs')]: {
            marginBottom: '0.5rem'
        }
    },
    headerImg: {
        height: '100%',
        objectFit: 'cover'
    },
    detailsWrapper: {
        position: 'relative',

        [theme.breakpoints.up('md')]: {
            height: '25%',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
        }
    },
    leftColumn: {
        [theme.breakpoints.up('md')]: {
            paddingTop: '2rem'
        },
        [theme.breakpoints.down('sm')]: {
            marginBottom: '3rem'
        },
        [theme.breakpoints.down('xs')]: {
            marginBottom: '2rem'
        }
    },
    rightColumn: {
        [theme.breakpoints.up('md')]: {
            paddingTop: '2rem'
        },
        // '@media (min-width: 960px) and (max-width: 1024px)': {
        //     paddingTop: '1rem'
        // },
    },
    detailListWrapper: {
        display: 'flex',
        alignItems: 'center',

        [theme.breakpoints.up('md')]: {
            justifyContent: 'flex-end'
        },
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column',
            alignItems: 'flex-start'
        }
    },
    tripName: {
        fontSize: '2.5rem',
        fontFamily: 'Open Sans, sans-serif',
        fontWeight: 800,
        marginBottom: '0.2rem',

        [theme.breakpoints.down('md')]: {
            fontSize: '2rem'
        },
        '@media (min-width: 960px) and (max-width: 1199px)': {
            fontSize: '1.5rem'
        },
    },
    region: {
        margin: 0
    },
    detailList: {
        display: 'flex',
        justifyContent: 'space-between',

        [theme.breakpoints.down('sm')]: {
            width: '100%',
            marginBottom: '1rem'
        },
        [theme.breakpoints.down('xs')]: {
            marginBottom: '3rem'
        }
    },
    detail: {
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'center',

        '@media (min-width: 960px) and (max-width: 1199px)': {
            marginRight: '2rem'
        },
        '@media (min-width: 1024.05px)': {
            marginRight: '3rem'
        },
        '&.priceColumn': {
            [theme.breakpoints.down('xs')]: {
                display: 'none'
            }
        }
    },
    detailLabel: {
        marginBottom: '0.4rem',

        [theme.breakpoints.down('md')]: {
            fontSize: '1rem'
        },
        '@media (min-width: 960px) and (max-width: 1199px)': {
            fontSize: '0.875rem'
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: '0.875rem'
        }
    },
    detailValue: {
        fontSize: '1.4rem',
        fontWeight: 800,

        '&.price': {
            position: 'relative',
            color: colors.orange,
            marginBottom: '0.5rem',
            cursor: 'pointer',

            '&:after': {
                content: '\"\"',
                position: 'absolute',
                top: '10px',
                right: '18px',
                width: '0.8rem',
                height: '0.8rem',
                backgroundImage: `url(${infoIcon})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'contain',

                [theme.breakpoints.down('md')]: {
                    top: '7px',
                    right: '25px',
                },
            }
        },

        [theme.breakpoints.down('md')]: {
            fontSize: '1.2rem'
        },
        // '@media (min-width: 960px) and (max-width: 1024px)': {
        //     fontSize: '1.2rem'
        // },
        [theme.breakpoints.down('xs')]: {
            fontSize: '1rem'
        },
    },
    moneyPot: {
        fontSize: '0.9rem',
        fontStyle: 'italic',
        cursor: 'pointer'
    },
    buttonWrapper: {
        flexShrink: 0,

        [theme.breakpoints.only('sm')]: {
            position: 'absolute',
            top: 0,
            right: 0,
        },
        [theme.breakpoints.down('xs')]: {
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
        }
    },
    mobilePriceWrapper: {
        position: 'relative',
        margin: '0 2rem 0 0',

        [theme.breakpoints.up('sm')]: {
            display: 'none',
        },

        '&:after': {
            content: '\"\"',
            position: 'absolute',
            top: '10px',
            right: '-30px',
            width: '1rem',
            height: '1rem',
            backgroundImage: `url(${infoIcon})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'contain',
        }
    },
    mobilePrice: {
        marginLeft: '0.3rem',
        fontSize: '1.4rem',
        fontWeight: 800,
        color: colors.orange
    }
}));

function TripHeader(props) {
    const classes = useStyles();
    const matchesXsDown = useMediaQuery('(max-width: 599.95px)');

    const { id, slug, name, city, country, duration, groupSize, languages, minPrice, coverImgAltText, bookingLink,
        priceDetailsText, activitiesPrice, guidePrice } = props;
    const basePath = `./img/${slug}/`;
    const [showSticky, setShowSticky] = useState(false);
    const [showPriceDetails, togglePriceDetails] = useToggleState(false);

    const handleScrollDown = () => {
        if (window.scrollY > (window.innerHeight - 170)) {
            setShowSticky(true);
            document.addEventListener('scroll', throttleScrollUp, false);
            document.removeEventListener('scroll', throttleScrollDown, false);
        }
    };

    const handleScrollUp = () => {
        if (window.scrollY < window.innerHeight - 170) {
            setShowSticky(false);
            document.addEventListener('scroll', throttleScrollDown, false);
            document.removeEventListener('scroll', throttleScrollUp, false);
        }
    };


    const throttleScrollDown = _.throttle(handleScrollDown, 100);
    const throttleScrollUp = _.throttle(handleScrollUp, 100);

    useEffect(() => {
        document.addEventListener('scroll', throttleScrollDown, false);
        return () => {
            document.removeEventListener('scroll', throttleScrollDown, false);
            document.removeEventListener('scroll', throttleScrollUp, false);
        }
    }, []);

    const handleBookingClick = () => {
        ReactPixel.track('CustomizeProduct', {destination: name});
        window.location.href = bookingLink;
    };

    return (
        <section className={classes.root}>
            <div className={classes.imgWrapper}>
                <picture>
                    <source
                        src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
                        data-srcset={`${require(`${basePath}teaser_small.webp`)} 420w, ${require(`${basePath}teaser_large.webp`)} 900w, ${require(`${basePath}cover.webp`)} 1200w`}
                        type="image/webp"
                    />
                    <source
                        src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
                        data-srcset={`${require(`${basePath}teaser_small.jpg`)} 420w, ${require(`${basePath}teaser_large.jpg`)} 900w, ${require(`${basePath}cover.jpg`)} 1200w`}
                        type="image/jpeg"
                    />
                    <img
                        src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
                        data-src={require(`${basePath}cover.jpg`)}
                        alt={coverImgAltText}
                        className={`lazyload ${classes.headerImg}`}
                        data-sizes="auto"
                    />
                </picture>
            </div>
            <div className={classes.detailsWrapper}>
                <div className={classes.leftColumn}>
                    <h1 className={classes.tripName}>{name}</h1>
                    <p>{city}, {country}</p>
                </div>
                <div className={classes.rightColumn}>
                    <div className={classes.detailListWrapper}>
                        <ul className={classes.detailList}>
                            <li className={classes.detail}>
                                <span className={classes.detailLabel}>Duration</span>
                                <span className={classes.detailValue}>{duration} days</span>
                            </li>
                            <li className={classes.detail}>
                                <span className={classes.detailLabel}>Max. Group size</span>
                                <span className={classes.detailValue}>{groupSize}</span>
                            </li>
                            <li className={classes.detail}>
                                <span className={classes.detailLabel}>Hosted in</span>
                                <span className={classes.detailValue}>
                                {
                                    languages.map((language, i) => (i === 0 ? language : `, ${language}`))
                                }
                            </span>
                            </li>
                            <li className={`${classes.detail} priceColumn`}>
                                <span className={classes.detailLabel}>from</span>
                                <span className={`${classes.detailValue} price`} onClick={togglePriceDetails}>{minPrice}€</span>
                                <span className={classes.moneyPot} onClick={togglePriceDetails}>+ money pool on-site</span>
                            </li>
                            {/*<li className={`${classes.detail} depositColumn`}>*/}
                            {/*    <span className={classes.detailLabel}>Deposit: Pay only 20% now</span>*/}
                            {/*    <span className={classes.detailLabel}>Balance: 30 days before start</span>*/}
                            {/*</li>*/}
                        </ul>
                        <div className={classes.buttonWrapper}>
                            <p className={classes.mobilePriceWrapper} onClick={togglePriceDetails}>from <span className={classes.mobilePrice}>{minPrice}€</span></p>
                            <FlatButton color={colors.black} bgColor={colors.orange} onClick={handleBookingClick}>See dates</FlatButton>
                        </div>
                        {
                            matchesXsDown && <DepositText openPriceDetails={togglePriceDetails}/>
                        }
                    </div>
                    {
                        !matchesXsDown && <DepositText  openPriceDetails={togglePriceDetails}/>
                    }
                </div>
            </div>
            <StickyBookingBar show={showSticky} price={minPrice} bookingLink={bookingLink} />
            <StickyDetailsAndNav show={showSticky} price={minPrice} bookingLink={bookingLink} />
            <PriceDetails
                open={showPriceDetails}
                togglePriceDetails={togglePriceDetails}
                priceDetailsText={priceDetailsText}
                activitiesPrice={activitiesPrice}
                guidePrice={guidePrice}
            />
        </section>
    );
}

export default TripHeader;