import React, {createRef, useEffect, useState} from 'react';
import BlogLane from "./BlogLane";
import makeStyles from "@material-ui/core/styles/makeStyles";
import BlogFeaturedArticleTeaser from "./BlogFeaturedArticleTeaser";
import axios from "axios";

const useStyles = makeStyles(theme => ({
    root: {
        maxWidth: '80rem',
        margin: '0 auto 5rem'
    },
    articleList: {
        display: 'flex',
        justifyContent: 'flex-start',
        overflowX: 'scroll',
    },
    column: {
        position: 'relative',
        width: 'calc(33.3333% - 1.5rem)',
        height: '16rem',
        flexShrink: 0,
        marginRight: '2.25rem',
        overflow: 'hidden',

        [theme.breakpoints.down('xs')]: {
            width: '80%',
            height: '50vw',
            marginRight: '1rem',
        }
    }
}));

function BlogCategory(props) {
    const classes = useStyles();
    const {cityId, category, baseUrl} =  props;
    const laneRef = createRef();
    const [articles, setArticles] = useState([]);
    const [isLoaded, setIsloaded] = useState(false);
    const [title, setTitle] = useState('');

    useEffect(() => {
        getFeaturedPosts();
    }, []);

    const getFeaturedPosts = async () => {
        const filters = await getFilters();
        const url = `${baseUrl}/posts?${filters}`;

        axios.get(url)
            .then((response) => {
                setArticles(response.data);
                setIsloaded(true);
            })
            .catch(function (error) {
                // handle error
                console.log(error);
            });
    };

    const getFilters = async () => {
        let cityTagId = '';
        let categoryTagId = '';

        if (typeof cityId !== 'undefined') {
            cityTagId = await getCityTagId();
        }

        return axios.get(`${baseUrl}/categories?slug=${category}`)
            .then(response => {
                categoryTagId = response.data[0].id;
                const categoryTitle = response.data[0].name;
                let filters = `categories=${categoryTagId}`;
                setTitle(categoryTitle);

                if (cityTagId !== '') {
                    filters += `&tags=${cityTagId}`
                }
                return filters;
            })
            .catch(function (error) {
                // handle error
                console.log(error);
            });
    };

    const getCityTagId = async () => {
        return axios.get(`${baseUrl}/tags?slug=${cityId}`)
            .then(response => {
                return response.data[0].id
            })
            .catch(function (error) {
                // handle error
                console.log(error);
            });
    };

    return (
        <React.Fragment>
        {
            articles.length > 0 &&
                <section className={classes.root}>
                    <BlogLane title={title} laneRef={laneRef} isLoaded={isLoaded}>
                        <ul className={`${classes.articleList} no-scrollbar`} ref={laneRef}>
                            {
                                articles.map((article, index) => {
                                    return (
                                        <li className={`${classes.column} js-lane-item`} key={index}>
                                            <BlogFeaturedArticleTeaser
                                                title={article.acf.meta_information.title}
                                                cityId={cityId}
                                                slug={article.slug}
                                                showArrows={articles.length > 3}
                                                img={article.acf.meta_information.article_image}
                                            />
                                        </li>
                                    )
                                })
                            }
                        </ul>
                    </BlogLane>
                </section>
        }
        </React.Fragment>
    );
}

export default BlogCategory;