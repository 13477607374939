import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import ScrollBox from './ScrollBox';
import Fade from 'react-reveal/Fade';
import Plx from 'react-plx';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',

        [theme.breakpoints.up('md')]: {
            flexDirection: props => (props.order),
        },
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            marginBottom: '15rem'
        },
        [theme.breakpoints.down('xs')]: {
            marginBottom: '11rem'
        }
    },
    leftColumn: {
        width: '50%',

        [theme.breakpoints.only('sm')]: {
            width: '90%',
            marginBottom: '5rem'
        },
        [theme.breakpoints.down('xs')]: {
            width: '100%',
            marginBottom: '3rem'
        },
    },
    rightColumn: {
        position: 'relative',
        width: '50%',

        [theme.breakpoints.up('sm')]: {
            marginLeft: props => (props.id === 1 ? '-2rem' : 0),
        },
        [theme.breakpoints.down('sm')]: {
            width: '100%'
        },
        [theme.breakpoints.down('xs')]: {
            width: '100%'
        },
    },
    contentWrapper: {
        width: '90%',
        margin: '0 auto',

        [theme.breakpoints.down('xs')]: {
            width: '100%'
        },
    },
    illuWrapper: {
        position: 'relative',
        width: '36rem',
        height: '26rem',
        display: 'flex',
        alignItems: 'center',
        backgroundImage: props => (`url(${props.illustration.bg})`),
        backgroundSize: '100%',
        backgroundPosition: 'right bottom',
        backgroundRepeat: 'no-repeat',


        [theme.breakpoints.down('sm')]: {
            width: '75vw',
            height: '55vw'
        },
        [theme.breakpoints.down('xs')]: {
            width: '90vw',
            height: '70vw'
        },

        '&.lean': {
            backgroundSize: '90%',

            [theme.breakpoints.up('lg')]: {
                marginLeft: '-2rem'
            },
            '@media (min-width: 1025px) and (max-width: 1280px)': {
                width: '31rem',
                backgroundPosition: 'right 80%',
                marginLeft: '-1rem'
            },
            '@media (min-width: 960px) and (max-width: 1024px)': {
                backgroundPosition: 'right 70%',
                width: '25rem',
                height: '27rem'
            },
        },

        '&.clique': {
            marginLeft: 'auto',
            width: '35rem',
            height: '30rem',
            backgroundPosition: 'left bottom',

            [theme.breakpoints.down('sm')]: {
                marginLeft: 0
            },
            '@media (min-width: 1025px) and (max-width: 1280px)': {
                width: '30rem',
                height: '27rem',
                margin: '0 2rem 0 auto'
            },
            '@media (min-width: 960px) and (max-width: 1024px)': {
                width: '25rem',
                height: '24rem',
                margin: '0 1rem 0 auto'
            },
            [theme.breakpoints.down('sm')]: {
                width: '73vw',
                height: '63vw',
                marginLeft: 0
            },
            [theme.breakpoints.down('xs')]: {
                width: '90vw',
                height: '80vw'
            }
        },
        '&.fun': {
            height: '35rem',
            backgroundSize: '100%',
            backgroundPosition: 'left bottom',

            [theme.breakpoints.up('lg')]: {
                marginLeft: '-1rem'
            },
            '@media (min-width: 1025px) and (max-width: 1280px)': {
                width: '30rem',
                height: '31rem',
            },
            '@media (min-width: 960px) and (max-width: 1024px)': {
                backgroundPosition: 'right 70%',
                width: '25rem',
                height: '24rem'
            },
            [theme.breakpoints.only('md')]: {
                backgroundPosition: 'left 80%',
            },
            [theme.breakpoints.down('sm')]: {
                height: '73vw',
            },
            [theme.breakpoints.down('xs')]: {
                height: '90vw',
                marginBottom: '2rem'
            }
        },
    },
    illustration: {
        width: '92%',
        height: '92%',

        '&.clique': {
            position: 'absolute',
            top: 0,
            right: '-0.5rem',

            [theme.breakpoints.up('sm')]: {
                right: '-3rem'
            }
        },
        '&.fun': {
            position: 'absolute',
            top: 0,
            right: 0,
            width: '100%',
            height: '100%'
        },
    },
    illuExtra: {
        position: 'absolute',

        '&.livin-local': {
            width: '30%',
            top: 0,
            left: 0,

            '@media (min-width: 1025px) and (max-width: 1280px)': {
                width: '45%',
            },
            '@media (min-width: 960px) and (max-width: 1024px)': {
                top: '1rem',
                width: '50%',
            },
            [theme.breakpoints.down('xs')]: {
                width: '35%',
                top: '-0.75rem',
                left: 0
            }
        },
        '&.enjoy-the-ride': {
            width: '25%',
            bottom: '95%',
            right: '15%',

            [theme.breakpoints.only('sm')]: {
                width: '23%',
                bottom: '88%',
                right: '7%',
            },
            [theme.breakpoints.down('xs')]: {
                width: '30%',
                bottom: '95%',
                right: 0,
            },
        },

    },
    title: {
    },
    description: {
        marginBottom: '2.5rem',

        '@media (min-width: 960px) and (max-width: 1024px)': {
            fontSize: '0.875rem'
        },
    }
}));

function Benefit(props) {
    const classes = useStyles(props);
    const { id, title, text, imgNames, imgAltTexts, illustration, extras } = props;

    const parallaxData = {
        lean: [
            {
                start: 'self',
                duration: '100vh',
                properties: [
                    {
                        startValue: 3,
                        endValue: -3,
                        property: 'rotate',
                    },
                    {
                        startValue: -40,
                        endValue: 30,
                        property: 'translateY',
                    }
                ],
            }
        ],
        clique: [
            {
                start: 'self',
                duration: '100vh',
                properties: [
                    {
                        startValue: -3,
                        endValue: 3,
                        property: 'rotate',
                    },
                    {
                        startValue: -30,
                        endValue: 30,
                        property: 'translateY',
                    }
                ],
            }
        ],
        fun: [
            {
                start: 'self',
                duration: '100vh',
                properties: [
                    {
                        startValue: -3,
                        endValue: 3,
                        property: 'rotate',
                    },
                    {
                        startValue: -40,
                        endValue: 30,
                        property: 'translateY',
                    }
                ],
            }
        ]
    };

    return (
        <article className={classes.root}>
            <div className={classes.leftColumn}>
                <div className={`${classes.illuWrapper} ${illustration.className}`}>
                    { typeof illustration.extras !== 'undefined' ?
                        illustration.extras.map((extra, index) =>
                            <img
                                key={index}
                                src={extra.path}
                                alt=""
                                className={`${classes.illuExtra} ${extra.className}`}/>
                                ) : ''
                    }
                    <Plx parallaxData={parallaxData[illustration.className]} style={{width: '100%', height: '100%'}}>
                        <img src={illustration.path} alt={illustration.altText} className={`${classes.illustration} ${illustration.className}`} />
                    </Plx>
                </div>
            </div>
            <div className={classes.rightColumn}>
                { typeof extras !== 'undefined' ?
                    extras.map((extra, index) =>
                        <img
                            key={index}
                            src={extra.path}
                            alt=""
                            className={`${classes.illuExtra} ${extra.className}`}/>
                    ) : ''
                }
                <div className={classes.contentWrapper}>
                    <h3 className={classes.title}>{title}</h3>
                    <p className={classes.description}>{text}</p>
                    {/*<ScrollBox benefitId={id} imgAltTexts={imgAltTexts} imgNames={imgNames}/>*/}
                </div>
            </div>
        </article>
    );
}

export default Benefit;