import React from 'react';
import makeStyles from "@material-ui/core/styles/makeStyles";
import colors from "./styles/colors";
import {Link} from 'react-router-dom';
import twitterIcon from './img/icon_twitter.svg';
import facebookIcon from './img/icon_facebook.svg';
import instagramIcon from './img/icon_instagram.svg';

const useStyles = makeStyles(theme => ({
    root: {
        position: 'relative',
        margin: '0 -5vw',
        padding: '0 5vw',
        backgroundColor: colors.lightBgGrey,

        [theme.breakpoints.down('md')]: {
            margin: '0 -6vw',
            padding: '0 6vw',
        },
        [theme.breakpoints.down('xs')]: {
            margin: '0 -5vw',
            padding: '0 5vw',
        }
    },
    innerWrapper: {
        width: '100%',
        maxWidth: '80rem',
        margin: '0 auto ',
        padding: '3rem 7rem 2rem 0',
        display: 'flex',
        justifyContent: 'space-between',

        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column',
            padding: '3rem 0 6rem'
        }
    },
    leftColumn: {
        display: 'flex',

        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column',
            marginBottom: '2rem'
        }
    },
    title: {
        fontSize: '1rem',
        marginBottom: '1rem'
    },
    copyright: {
        color: colors.fontGrey,
        fontSize: '0.875rem',

        [theme.breakpoints.down('xs')]: {
            marginBottom: '2rem'
        }
    },
    innerColumn: {
        display: 'flex'
    },
    linkList: {
        margin: '1.9rem 0 0 5rem',
        fontSize: '0.875rem',

        [theme.breakpoints.down('sm')]: {
            marginLeft: '3rem'
        },
        [theme.breakpoints.down('xs')]: {
            '&:first-child': {
                marginLeft: 0,
                marginRight: '3rem'
            }
        },
    },
    footerLink: {
        display: 'block',
        marginBottom: '0.75rem',
        color: colors.black,
        textDecoration: 'none',

        '&:visited, &:hover, &:focus': {
            color: colors.black
        }
    },
    socialLink: {
        display: 'flex',
        alignItems: 'center'
    },
    shareIcon: {
        display: 'block',
        width: '30px',
        height: '30px',
        marginRight: '0.5rem',

        [theme.breakpoints.down('xs')]: {
            width: '20px',
            height: '20px'
        }
    }
}));

function Footer(props) {
    const classes = useStyles();
    const year = new Date().getFullYear();

    return (
        <footer className={classes.root}>
            <div className={classes.innerWrapper}>
                <div className={classes.leftColumn}>
                    <div className={classes.column}>
                        <h4 className={classes.title}>Travelmeister</h4>
                        <p className={classes.copyright}>Copyright © {year} Travelmeister</p>
                    </div>
                    <div className={classes.innerColumn}>
                        <ul className={classes.linkList}>
                            <li><Link to="/imprint" rel="nofollow" className={classes.footerLink}>Imprint</Link></li>
                            {/*<li>Contact</li>*/}
                            <li><Link rel="nofollow" to="/terms-conditions" className={classes.footerLink}>Terms & Conditions</Link></li>
                            <li><Link to="/privacy" rel="nofollow" className={classes.footerLink}>Privacy Policy</Link></li>
                        </ul>
                        <nav>
                            <ul className={classes.linkList}>
                                <li><Link to="/trips" className={classes.footerLink}>Trips</Link></li>
                                <li><Link to="/about" className={classes.footerLink}>About Us</Link></li>
                                <li><Link to="/how-it-works" className={classes.footerLink}>How it works</Link></li>
                                <li><Link to="/blog" target="_blank" className={classes.footerLink}>Blog</Link></li>
                            </ul>
                        </nav>
                    </div>
                </div>
                <div className={classes.rightColumn}>
                    <h4 className={classes.title}>Social Media</h4>
                    <ul>
                        {/*<li>*/}
                        {/*    <a href="http://twitter.com" target="_blank" className={`${classes.footerLink} ${classes.socialLink}`}>*/}
                        {/*        <span className={classes.shareIcon}>*/}
                        {/*            <img src={twitterIcon} alt="Twitter Logo"/>*/}
                        {/*        </span>*/}
                        {/*        Twitter*/}
                        {/*    </a>*/}
                        {/*</li>*/}
                        <li>
                            <a href="https://www.facebook.com/Travelmeister-101559075246287" target="_blank" className={`${classes.footerLink} ${classes.socialLink}`}>
                                <span className={classes.shareIcon}>
                                    <img src={facebookIcon} alt="Facebook Logo"/>
                                </span>
                                Facebook
                            </a>
                        </li>
                        <li>
                            <a href="https://www.instagram.com/travelmeister_eu/" target="_blank" className={`${classes.footerLink} ${classes.socialLink}`}>
                                <span className={classes.shareIcon}>
                                    <img src={instagramIcon} alt="Instagram Logo"/>
                                </span>
                                Instagram
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </footer>
    );
}

export default Footer;