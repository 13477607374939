import React, {useEffect, useState} from 'react';
import BlogArticleHeader from "./BlogArticleHeader";
import BlogArticleRanking from "./BlogArticleRanking";
import makeStyles from "@material-ui/core/styles/makeStyles";
import BlogRelatedArticles from "./BlogRelatedArticles";
import newsletterBg from './img/blog/bg_blog_newsletter.svg';
import NewsletterSmall from "./NewsletterSmall";
import axios from "axios";
import newsletterBgMobile from "./img/blog/bg_blog_newsletter_mobile.svg";
import colors from "./styles/colors";
import BlogTripTeaserBig from "./BlogTripTeaserBig";
import BlogTripTeaserSmall from "./BlogTripTeaserSmall";
import {Helmet} from "react-helmet";

const useStyles = makeStyles(theme => ({
    pageContainer: {
        padding: '5rem 5vw 0',
        backgroundColor: colors.white,
        transition: 'padding-top 200ms linear',

        [theme.breakpoints.down('md')]: {
            padding: '4rem 6vw 0',
        },
        [theme.breakpoints.down('xs')]: {
            padding: '3rem 5vw 0',
        }
    },
    content: {
        maxWidth: '80rem',
        margin: '0 auto',

        [theme.breakpoints.up('md')]: {
            display: 'flex',
            justifyContent: 'space-between',
        }
    },
    sidebar: {
        [theme.breakpoints.up('sm')]: {
            position: 'sticky',
            top: '10%',
            width: '25%',
            alignSelf: 'flex-start',
            flexShrink: 0
        },

        [theme.breakpoints.down('sm')]: {
            position: 'relative',
            width: '100%'
        },
    }
}));

function BlogArticlePage(props) {
    const classes = useStyles();
    const [articleData, setArticleData] = useState(null);
    const cityId =  props.match.params.cityId;
    const slug =  props.match.params.articleId;

    const url = `https://blog.travelmeister.eu/wp-json/wp/v2/posts?slug=${slug}`;

    useEffect(() => {
        axios.get(url)
            .then((response) => {
                setArticleData(response.data[0]);
            })
            .catch(function (error) {
                // handle error
                console.log(error);
            });
    }, [slug]);

    if (articleData !== null) {
        return (

            <div className={classes.pageContainer}>
                <Helmet>
                    <title>{articleData.acf.meta_information.title} | Travelmeister</title>
                    <meta name="description"
                          content={articleData.acf.meta_information.description}
                    />
                    <meta property="og:title" content={articleData.acf.meta_information.title} />
                    <meta property="og:type" content="website" />
                    <meta property="og:url" content={`https://travelmeister.eu/blog/${cityId}/${slug}`} />
                    <meta property="og:image" content={articleData.acf.meta_information.article_image.sizes.thumbnail} />
                </Helmet>
                <BlogArticleHeader
                    slug={slug}
                    img={articleData.acf.meta_information.article_image.url}
                    cityId={cityId}
                    cityName={articleData.acf.meta_information.city_name}
                    title={articleData.acf.meta_information.title}
                    introText={articleData.acf.meta_information.description}
                />
                <section className={classes.content}>
                    {
                        articleData.acf.place_lists &&
                        articleData.acf.place_lists.map((placeList, index) => (
                            <React.Fragment>
                                <BlogArticleRanking
                                    ranking={placeList.places}
                                    rankingTitle={placeList.list_title}
                                    cityId={cityId}
                                    cityName={articleData.acf.meta_information.city_name}
                                    adCityName={articleData.acf.ad_content.cityName}
                                    tripName={articleData.acf.ad_content.tripName}
                                    smallAdContent={articleData.acf.ad_content.smallAdContent}
                                    bigAdContent={articleData.acf.ad_content.bigAdContent}
                                    displayAd={articleData.acf.place_lists.length === 1}
                                    lastList={(articleData.acf.place_lists.length - 1) === index}
                                />
                                {
                                    (index === 0) && (articleData.acf.place_lists.length > 1) &&
                                    <BlogTripTeaserSmall
                                        citySlug={cityId}
                                        cityName={articleData.acf.ad_content.cityName}
                                        tripName={articleData.acf.ad_content.tripName}
                                        adContent={articleData.acf.ad_content.smallAdContent}
                                    />
                                }
                            </React.Fragment>
                        ))
                    }
                    <aside className={classes.sidebar}>
                        <h3>Related Articles</h3>
                        <BlogRelatedArticles
                            cityId={cityId}
                            categories={articleData.categories}
                            currentArticleSlug={slug}
                        />
                    </aside>
                </section>
                <NewsletterSmall background={newsletterBg} mobileBg={newsletterBgMobile}/>
            </div>
        )
    }

    return 'Loading...';
}

export default BlogArticlePage;