import React from 'react';
import makeStyles from "@material-ui/core/styles/makeStyles";
import colors from './styles/colors';

const useStyles = makeStyles({
    root: {
        marginBottom: '5rem'
    },
    img: {

    },
    description: {
        marginBottom: '2rem',

        '& a': {
            textDecoration: 'underline',

            '&:hover': {
                color: colors.green
            }
        }
    },
    question: {
        fontSize: '1rem',
        fontWeight: 800,
        marginBottom: '0.2rem'
    },
    answer: {
        '& a': {
            textDecoration: 'underline',

            '&:hover': {
                color: colors.green
            }
        }
    },
    imageSource: {
        display: 'block',
        fontSize: '0.875rem',
        marginBottom: '2rem'
    },
    location: {
        '& a': {
            textDecoration: 'underline'
        }
    },
    website: {
        display: 'block',
        marginBottom: '0.5rem'
    }
});

function BlogArticleEntry(props) {
    const classes = useStyles();
    const {title, image_group, description, question_and_answer, location} = props;

    return (
        <article className={classes.root}>
            <h3>{title}</h3>
            {
                image_group.image &&
                    <React.Fragment>
                        <figure>
                            <a href={image_group.image_link} target="_blank">
                                <img src={image_group.image.sizes.medium_large} alt={image_group.image.alt} className={classes.img} />
                            </a>
                        </figure>
                        <a href={image_group.image_source.url} className={classes.imageSource} target="_blank">{image_group.image_source.title}</a>
                    </React.Fragment>
            }
            {
                description && <p dangerouslySetInnerHTML={{__html: description}} className={classes.description}></p>
            }
            {
                question_and_answer &&
                question_and_answer.map(pair => (
                    <React.Fragment>
                        <h4 className={classes.question}>{pair.question}</h4>
                        <p dangerouslySetInnerHTML={{__html: pair.answer}} className={classes.answer}></p>
                    </React.Fragment>
                ))
            }
            {
                location &&
                <p className={classes.location}>
                    { location.website && <a href={location.website.url} target="_blank" rel="noreferrer" className={classes.website}>{location.website.title}</a> }
                    { location.address && <a href={location.address_link} target="_blank" rel="noreferrer">{location.address}</a> }
                    { location.subway && <span> | {location.subway}</span> }

                </p>
            }
        </article>
    );
}

export default BlogArticleEntry;