import React from 'react';
import BlogArticleEntry from "./BlogArticleEntry";
import makeStyles from "@material-ui/core/styles/makeStyles";
import BlogTripTeaserSmall from "./BlogTripTeaserSmall";
import BlogTripTeaserBig from "./BlogTripTeaserBig";

const useStyles = makeStyles(theme => ({
    root: {
        [theme.breakpoints.up('md')]: {
            maxWidth: '47rem',
            marginRight: '5rem'
        }
    },
    rankingTitle: {
        fontSize: '3rem',
        marginBottom: '3rem',
        transform: 'none',

        '&:after': {
            height: 0
        }
    }
}));

function BlogArticleRanking(props) {
    const classes = useStyles();
    const {cityId, ranking, rankingTitle, displayAd, adCityName, tripName, smallAdContent, bigAdContent, lastList} = props;

    return (
        <article className={classes.root}>
            {
                rankingTitle && <h2 className={classes.rankingTitle}>{rankingTitle}</h2>
            }
            <ul>
                {
                    ranking.map((entry, i) => {
                        if(i === Math.round((ranking.length/2)-1)) {
                            return (
                                <React.Fragment>
                                    <li key={i}>
                                        <BlogArticleEntry {...entry} />
                                        {
                                            displayAd &&
                                            <BlogTripTeaserSmall
                                                citySlug={cityId}
                                                cityName={adCityName}
                                                tripName={tripName}
                                                adContent={smallAdContent}
                                            />
                                        }
                                    </li>
                                </React.Fragment>
                            )
                        }
                        return (
                            <li key={i}>
                                <BlogArticleEntry {...entry} />
                            </li>
                        )
                    })
                }
            </ul>
            {
                lastList &&
                <BlogTripTeaserBig
                    citySlug={cityId}
                    cityName={adCityName}
                    tripName={tripName}
                    adContent={bigAdContent}
                />
            }
        </article>
    );
}

export default BlogArticleRanking;