import React from 'react';
import makeStyles from "@material-ui/core/styles/makeStyles";

import berlinJpegLarge from './img/berlin/teaser_large.jpg';
import berlinJpegSmall from './img/berlin/teaser_small.jpg';
import berlinWebpLarge from './img/berlin/teaser_large.webp';
import berlinWebpSmall from './img/berlin/teaser_small.webp';

import socialJpegLarge from './img/about/values/about_social_large.jpg';
import socialWebpLarge from './img/about/values/about_social_large.webp';
import smallGroupsJpegLarge from './img/about/values/about_small_groups_large.jpg';
import smallGroupsWebpLarge from './img/about/values/about_small_groups_large.webp';
import localTravelmeistersJpegLarge from './img/about/values/about_local_travelmeisters_large.jpg';
import localTravelmeistersWebpLarge from './img/about/values/about_local_travelmeisters_large.webp';
import uniqueExperiencesJpegLarge from './img/about/values/about_experiences_large.jpg';
import uniqueExperiencesWebpLarge from './img/about/values/about_experiences_large.webp';
import sustainableJpegLarge from './img/about/values/about_sustainable_large.jpg';
import sustainableWebpLarge from './img/about/values/about_sustainable_large.webp';
import tailorMadeJpegLarge from './img/about/values/about_tailor_made_large.jpg';
import tailorMadeWebpLarge from './img/about/values/about_tailor_made_large.webp';

import socialJpegSmall from './img/about/values/about_social_small.jpg';
import socialWebpSmall from './img/about/values/about_social_small.webp';
import smallGroupsJpegSmall from './img/about/values/about_small_groups_small.jpg';
import smallGroupsWebpSmall from './img/about/values/about_small_groups_small.webp';
import localTravelmeistersJpegSmall from './img/about/values/about_local_travelmeisters_small.jpg';
import localTravelmeistersWebpSmall from './img/about/values/about_local_travelmeisters_small.webp';
import uniqueExperiencesJpegSmall from './img/about/values/about_experiences_small.jpg';
import uniqueExperiencesWebpSmall from './img/about/values/about_experiences_small.webp';
import sustainableJpegSmall from './img/about/values/about_sustainable_small.jpg';
import sustainableWebpSmall from './img/about/values/about_sustainable_small.webp';
import tailorMadeJpegSmall from './img/about/values/about_tailor_made_small.jpg';
import tailorMadeWebpSmall from './img/about/values/about_tailor_made_small.webp';

import bg1 from './img/about/bg_values_1.svg';
import bg2 from './img/about/bg_values_2.svg';
import bg3 from './img/about/bg_values_3.svg';
import illu from './img/about/illu_bike.svg';

const bgImages = {
    'value_2': bg1,
    'value_3': bg2,
    'value_7': [bg3, illu]
};

const useStyles = makeStyles(theme => ({
    root: {
        maxWidth: '80rem',
        margin: '0 auto 8rem'
    },
    header: {
        marginBottom: '5rem',

        [theme.breakpoints.down('xs')]: {
            marginBottom: '3rem'
        },
    },
    valuesList: {
        display: 'flex',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
        padding: '0 5rem',

        [theme.breakpoints.down('md')]: {
            padding: '0 3rem'
        },
        [theme.breakpoints.down('sm')]: {
            padding: 0
        }
    },
    valueItem: {
        position: 'relative',
        width: '47%',
        marginBottom: '8rem',

        [theme.breakpoints.down('xs')]: {
            width: '100%',
            marginBottom: '3rem'
        },
    },
    valueTitle: {
        fontSize: '1.2rem'
    },
    imgWrapper: {
        position: 'relative',
        marginBottom: '2rem',
        paddingBottom: '72%',
        overflow: 'hidden'
    },
    valueImg: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        zIndex: 1,

        [theme.breakpoints.down('xs')]: {
            marginBottom: '1rem'
        },
    },
    valueBg: {
        position: 'absolute',
        zIndex: 0,

        '&.value-2-1': {
            top: '-25%',
            right: '-50%',
            width: '75%'
        },
        '&.value-3-1': {
            top: '-17%',
            left: '-25%',
            width: '45%'
        },
        '&.value-7-1': {
            bottom: '21%',
            right: '-48%',
            width: '80%'
        },
        '&.value-7-2': {
            bottom: '28%',
            right: '-95%',
            width: '85%'
        }
    }
}));

const values = [
    {
        title: 'Social travel',
        description: 'Travel is about meeting people from all over the world and having a great time together. ' +
            'Making new friends creates unique memories that last way longer than the trip itself.',
        img: {
            sources: {
                jpeg: {
                    small: socialJpegSmall,
                    large: socialJpegLarge
                },
                webp: {
                    small: socialWebpSmall,
                    large: socialWebpLarge
                }
            },
            altText: '2 friends making a break while traveling'
        }
    },
    {
        title: 'Local travelmeisters',
        description: 'Our local experts have all the insider information you need to immerse yourself in the local ' +
            'culture, all whilst being part of the group.',
        img: {
            sources: {
                jpeg: {
                    small: localTravelmeistersJpegSmall,
                    large: localTravelmeistersJpegLarge
                },
                webp: {
                    small: localTravelmeistersWebpSmall,
                    large: localTravelmeistersWebpLarge
                }
            },
            altText: 'A local showing the city to friends traveling'
        },
        illus: [bg1]
    },
    {
        title: 'Small groups',
        description: 'Travelling in a small group allows for more fun than going solo, without diminishing the ' +
            'individual experience. With a maximum group size of 12 everything stays personal and familiar.',
        img: {
            sources: {
                jpeg: {
                    small: smallGroupsJpegSmall,
                    large: smallGroupsJpegLarge
                },
                webp: {
                    small: smallGroupsWebpSmall,
                    large: smallGroupsWebpLarge
                }
            },
            altText: 'A group of friends hugging while traveling'
        },
        illus: [bg2]
    },
    {
        title: 'Handpicked accommodations',
        description: 'For every trip we carefully select the best possible accommodations, varying from ' +
            'houses at the beach to centrally located boutique hotels or premium hostels.',
        img: {
            sources: {
                jpeg: {
                    small: berlinJpegSmall,
                    large: berlinJpegLarge
                },
                webp: {
                    small: berlinWebpSmall,
                    large: berlinWebpLarge
                }
            },
            altText: 'People in Berlin'
        }
    },
    {
        title: 'Authentic experiences',
        description: 'Our local insiders offer unique activities like excursions, tours, local food, wine/beer' +
            'tastings and nights out, whilst leaving you enough freedom to explore on your own.',
        img: {
            sources: {
                jpeg: {
                    small: uniqueExperiencesJpegSmall,
                    large: uniqueExperiencesJpegLarge
                },
                webp: {
                    small: uniqueExperiencesWebpSmall,
                    large: uniqueExperiencesWebpLarge
                }
            },
            altText: 'A group of travelers doing stand-up paddle in the sea in the basque country'
        }
    },
    {
        title: 'Sustainable Travel',
        description: 'Our local partners are thoughtfully selected to keep our ecological footprint as low as ' +
            'possible and to support the local economy in a responsible way.',
        img: {
            sources: {
                jpeg: {
                    small: sustainableJpegSmall,
                    large: sustainableJpegLarge
                },
                webp: {
                    small: sustainableWebpSmall,
                    large: sustainableWebpLarge
                }
            },
            altText: 'A girl enjoying the nature while traveling'
        }
    },
    {
        title: 'Tailor-made for you',
        description: 'Choose your preferred room type, add extra nights or book optional activities to customize ' +
            'your trip according to your wishes.',
        img: {
            sources: {
                jpeg: {
                    small: tailorMadeJpegSmall,
                    large: tailorMadeJpegLarge
                },
                webp: {
                    small: tailorMadeWebpSmall,
                    large: tailorMadeWebpLarge
                }
            },
            altText: 'A group of friends on skateboards at Berlin Tempelhof'
        },
        illus: [bg3, illu]
    }
];

function Values(props) {
    const classes = useStyles();

    return (
        <section className={classes.root}>
            <header className={classes.header}>
                <h2>Our values</h2>
            </header>
            <ul className={classes.valuesList}>
                {values.map((value, i) => (
                    <li className={classes.valueItem}>
                        <article>
                            <figure className={classes.imgWrapper}>
                                <picture>
                                    <source
                                        src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
                                        data-srcset={`${value.img.sources.webp.small} 400w, ${value.img.sources.webp.large} 600w`}
                                        type="image/webp"
                                    />
                                    <source
                                        src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
                                        data-srcset={`${value.img.sources.jpeg.small} 400w, ${value.img.sources.jpeg.large} 600w`}
                                        type="image/jpeg"
                                    />
                                    <img
                                        src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
                                        data-src={value.img.sources.jpeg.large}
                                        alt={value.img.altText}
                                        className={`lazyload ${classes.valueImg}`}
                                        data-sizes="auto"
                                    />
                                </picture>
                            </figure>
                            {
                                typeof value.illus !== 'undefined'
                                    ?
                                    value.illus.map((illu, j) => <img src={illu} alt="" className={`${classes.valueBg} value-${i+1}-${j+1}`}/>)
                                    :
                                    ''
                            }
                            <h3>{value.title}</h3>
                            <p>{value.description}</p>
                        </article>
                    </li>
                ))}
            </ul>
        </section>
    );
}

export default Values;