import React, { lazy, Suspense } from 'react';
import NavBar from "./NavBar";
import {Switch, Route, useLocation} from 'react-router-dom';
import './App.css';
import smoothScroll from 'smoothscroll-polyfill';
import Page from "./Page";
import lazySizes from 'lazysizes';
import PreRegistrationForm from "./PreRegistrationForm";
import HowItWorksPage from "./HowItWorksPage";
import CookieConsent from './CookieConsent';
import PrivacyPage from "./PrivacyPage";
import ConditionsPage from "./ConditionsPage";
import ReactPixel from 'react-facebook-pixel';
import JSCookies from "js-cookie";
import TripDetailPageComingSoon from "./TripDetailPageComingSoon";

const TripsOverviewPage = lazy(() => import('./TripsOverviewPage'));
const AboutPage = lazy(() => import('./AboutPage'));
const HomePage = lazy(() => import('./HomePage'));
const TripDetailPage = lazy(() => import('./TripDetailPage'));
const BlogPage = lazy(() => import('./BlogPage'));
const BlogCityPage = lazy(() => import('./BlogCityPage'));
const BlogArticlePage = lazy(() => import('./BlogArticlePage'));
const ImprintPage = lazy(() => import('./ImprintPage'));



const renderLoader = () => <p>Loading...</p>;

function App() {
    // kick off the smooth scroll polyfill!
    smoothScroll.polyfill();

    // initialize lazysizes for lazy load
    lazySizes.init();

  return (
      <div className="App">
          <NavBar />
          <Switch>
              <Route exact path='/trip/:id' render={(routeProps) => <Suspense fallback={renderLoader()}><TripDetailPage {...routeProps} /></Suspense>} />
              <Route exact path='/coming-soon/:id' render={(routeProps) => <Suspense fallback={renderLoader()}><TripDetailPageComingSoon {...routeProps} /></Suspense>} />
              <Route exact path='/trips' component={() => <Suspense fallback={renderLoader()}><TripsOverviewPage /></Suspense>} />
              <Route exact path='/how-it-works' component={() => <Suspense fallback={renderLoader()}><HowItWorksPage /></Suspense>} />
              <Route exact path='/about' component={() => <Suspense fallback={renderLoader()}><AboutPage /></Suspense>} />
              <Route exact path='/blog/:cityId/:articleId' render={(routeProps) => <Suspense fallback={renderLoader()}><BlogArticlePage {...routeProps} /></Suspense>} />
              <Route exact path='/blog/:cityId' render={(routeProps) => <Suspense fallback={renderLoader()}><BlogCityPage {...routeProps} /></Suspense>} />
              <Route exact path='/blog' component={() => <Suspense fallback={renderLoader()}><BlogPage /></Suspense>} />
              <Route exact path='/imprint' component={() => <Suspense fallback={renderLoader()}><ImprintPage /></Suspense>} />
              <Route exact path='/privacy' component={() => <Suspense fallback={renderLoader()}><PrivacyPage /></Suspense>} />
              <Route exact path='/terms-conditions' component={() => <Suspense fallback={renderLoader()}><ConditionsPage /></Suspense>} />
              <Route exact path='/' component={() => <Suspense fallback={renderLoader()}><HomePage /></Suspense>} />
              <Route path="*">
                  <NoMatch />
              </Route>
          </Switch>
          {/*<PreRegistrationForm/>*/}
          <CookieConsent />
      </div>
  );
}

function NoMatch() {
    let location = useLocation();

    return (
        <Page>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <h3>
                No match for <code>{location.pathname}</code>
            </h3>
        </Page>
    );
}

export default App;
