import React, {useEffect, useState} from 'react';
import img from './img/berlin/cover.jpg';
import makeStyles from "@material-ui/core/styles/makeStyles";
import underline from "./img/underline.svg";
import bg from "./img/blog/bg_blog_city_header.svg";
import axios from "axios";

const useStyles = makeStyles(theme => ({
    root: {
        [theme.breakpoints.up('lg')]: {
            height: 'calc(100vh - 5rem)'
        },
        [theme.breakpoints.down('md')]: {
            height: 'auto',
            marginBottom: '5rem'
        },
        [theme.breakpoints.down('xs')]: {
            marginBottom: '3rem'
        }
    },
    headerImg: {
        position: 'relative',
        height: '60%',

        [theme.breakpoints.down('md')]: {
            height: '40vh',
            marginBottom: '2rem'
        },
        [theme.breakpoints.down('xs')]: {
            height: '20vh',
            marginBottom: '2rem'
        },
        '& .img': {
            position: 'relative',
            height: '100%',
            objectFit: 'cover',
            zIndex: 1
        }
    },
    background: {
        position: 'absolute',
        right: '-10%',
        bottom: '-15%',
        width: '50%',
        zIndex: 0
    },
    textWrapper: {
        height: '40%',
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'center',
        flexWrap: 'wrap',
        maxWidth: '80rem',
        margin: '0 auto',

        [theme.breakpoints.down('md')]: {
            height: '30vh',
        },
        [theme.breakpoints.down('xs')]: {
            height: 'auto',
        },
    },
    title: {
        position: 'relative',
        fontSize: '4rem',
        transform: 'rotate(-3deg)',

        [theme.breakpoints.down('xs')]: {
            fontSize: '3rem',
            marginBottom: '2rem'
        },
        '&::after': {
            content: '\"\"',
            position: 'absolute',
            left: 0,
            bottom: '-0.3rem',
            width: '100%',
            height: '0.5rem',
            backgroundImage: `url(${underline})`,
            backgroundRepeat: 'no-repeat',

            [theme.breakpoints.down('xs')]: {
                bottom: 0,
                height: '0.3rem'
            },
        }
    },
    description: {
        width: '60%',

        [theme.breakpoints.down('md')]: {
            width: '100%'
        },
    }
}));

function BlogCityHeader(props) {
    const classes = useStyles();
    const {cityId, baseUrl} = props;
    const [cityInfo, setCityInfo] = useState(false);

    useEffect(() => {
        getCityInfo();
    }, []);

    const getCityInfo = async () => {
        const url = `${baseUrl}/pages?slug=${cityId}`;

        axios.get(url)
            .then((response) => {
                setCityInfo(response.data[0]);
            })
            .catch(function (error) {
                // handle error
                console.log(error);
            });
    };

    return (
        cityInfo &&
        <header className={classes.root}>
            <figure className={classes.headerImg}>
                <img src={cityInfo.acf.image.sizes['1536x1536']} alt={cityInfo.acf.image.altText} className="img"/>
                {/*<img src={bg} alt="" className={classes.background}/>*/}
            </figure>
            <div className={classes.textWrapper}>
                <h1 className={classes.title}>{cityInfo.acf.name}</h1>
                <p className={classes.description}>{cityInfo.acf.description}</p>
            </div>
        </header>
    );
}

export default BlogCityHeader;