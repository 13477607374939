import React from 'react';
import makeStyles from "@material-ui/core/styles/makeStyles";
import NewsletterForm from "./NewsletterForm";

const useStyles = makeStyles(theme => ({
    root: {
        backgroundImage: props => (`url(${props.background})`),
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'top center',
        margin: '8rem -5vw 0',
        padding: '5rem 5vw 4rem',

        [theme.breakpoints.down('md')]: {
            margin: '0 -6vw',
            padding: '5rem 6vw 4rem',
        },
        [theme.breakpoints.down('xs')]: {
            margin: '0 -5vw',
            padding: '3rem 5vw',
            backgroundImage: props => (`url(${props.mobileBg})`)
        }
    },
    outerWrapper: {
        maxWidth: '80rem',
        margin: '0 auto'
    },
    innerWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',

        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column',
            alignItems: 'flex-start'
        }
    },
    leftColumn: {
        width: '40%',

        [theme.breakpoints.down('xs')]: {
            marginBottom: '1.5rem',
            width: '100%'
        }
    },
    rightColumn: {
        width: '50%',

        [theme.breakpoints.down('xs')]: {
            width: '100%'
        }
    },
    title: {
        marginBottom: '1rem'
    },
    description: {
        marginBottom: 0
    }
}));

function NewsletterSmall(props) {
    const classes = useStyles(props);
    const { location } = props;

    return (
        <section className={classes.root}>
            <div className={classes.outerWrapper}>
                <div className={classes.innerWrapper}>
                    <div className={classes.leftColumn}>
                        <h3 className={classes.title}>Stay informed</h3>
                        <p className={classes.description}>
                            Register to our newsletter to always be informed about our latest offers and new
                            exciting destinations.
                        </p>
                    </div>
                    <div className={classes.rightColumn}>
                        <NewsletterForm buttonText="Subscribe" location={location}/>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default NewsletterSmall;