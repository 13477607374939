import React, {useEffect, useState} from 'react';
import BlogArticleTeaser from "./BlogArticleTeaser";
import makeStyles from "@material-ui/core/styles/makeStyles";
import axios from "axios";


const useStyles = makeStyles(theme => ({
    root: {
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'space-between'
        }
    },
    row: {
        marginBottom: '2rem',

        [theme.breakpoints.down('sm')]: {
            width: '45%'
        },
        [theme.breakpoints.down('xs')]: {
            width: '100%'
        }
    }
}));

function BlogRelatedArticles(props) {
    const classes = useStyles();
    const {cityId, categories, currentArticleSlug} = props;
    const [relatedArticles, setRelatedArticles] = useState(null);
    const [alreadyFilled, setAlreadyFilled] = useState(false);
    const [cityTagId, setCityTagId] = useState(null);

    const baseUrl = `https://blog.travelmeister.eu/wp-json/wp/v2`;

    useEffect(() => {
        getRelatedArticles();
    }, [currentArticleSlug]);

    useEffect(() => {
        if (relatedArticles && !alreadyFilled) {
            fillUpRelatedArticles();
        }
    }, [relatedArticles]);

    const getRelatedArticles = async () => {
        console.log('get related articles');
        const cityTagId = await getCityTagId();
        setCityTagId(cityTagId);

        const url = `${baseUrl}/posts?tags=${cityTagId}&categories=${categories[0]}&per_page=6`;

        axios.get(url)
            .then((response) => {
                let articles = response.data;
                articles.splice(articles.findIndex((article) => article.slug === currentArticleSlug), 1);
                setAlreadyFilled(false);
                setRelatedArticles(articles);
            })
            .catch(function (error) {
                // handle error
                console.log(error);
            });
    };

    const fillUpRelatedArticles = async () => {
        let mergedArticles = [];

        if (relatedArticles.length >= 5) {
            return;
        }

        if (relatedArticles.length < 5) {
            const newArticles = await getCityArticles();
            const slugs = new Set(relatedArticles.map(article => article.slug));
            mergedArticles = [...relatedArticles, ...newArticles.filter(article => !slugs.has(article.slug))];
        }

        if (mergedArticles.length < 5) {
            const slugs = new Set(mergedArticles.map(article => article.slug));
            let categoryArticles = await getCategoryArticles();
            mergedArticles = [...mergedArticles, ...categoryArticles.filter(article => !slugs.has(article.slug))];
        }

        setAlreadyFilled(true);
        setRelatedArticles(mergedArticles);
    };

    const getCityArticles = async () => {
        const url = `${baseUrl}/posts?tags=${cityTagId}&per_page=6`;

        return axios.get(url)
            .then((response) => {
                let cityArticles = response.data;
                cityArticles.splice(cityArticles.findIndex((article) => article.slug === currentArticleSlug), 1);

                return cityArticles;
            })
            .catch(function (error) {
                // handle error
                console.log(error);
            });
    };

    const getCategoryArticles = async () => {
        const url = `${baseUrl}/posts?categories=${categories[0]}&per_page=6`;

        return axios.get(url)
            .then((response) => {
                let categoryArticles = response.data;
                categoryArticles.splice(categoryArticles.findIndex((article) => article.slug === currentArticleSlug), 1);

                return categoryArticles;
            })
            .catch(function (error) {
                // handle error
                console.log(error);
            });
    };

    const getCityTagId = async () => {
        return axios.get(`${baseUrl}/tags?slug=${cityId}`)
            .then(response => {
                return response.data[0].id;
            })
            .catch(function (error) {
                // handle error
                console.log(error);
            });
    };

    return (
        relatedArticles &&
        <ul className={classes.root}>
            {
                relatedArticles.map((article, index) => (
                    <li className={classes.row} key={index}>
                        <BlogArticleTeaser
                            className={classes.relatedArticle}
                            title={article.acf.meta_information.title}
                            citySlug={article.acf.meta_information.city_slug}
                            slug={article.slug}
                            img={article.acf.meta_information.article_image}
                            withoutTeaserText
                        />
                    </li>
                ))
            }
        </ul>
    );
}

export default BlogRelatedArticles;