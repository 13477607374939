import React, {useEffect, useRef} from 'react';
import teaserVideoMP4 from './video/teaser_video.mp4';
// import teaserVideoWebM from './video/teaser_video.webm';
import posterWebP from './img/posterStage.webp';
import posterJpeg from './img/posterStage.jpg';
import {makeStyles} from '@material-ui/core/styles';
import {Fab} from '@material-ui/core';
import VideoCover from 'react-video-cover';
import moodVideo from './video/mood_video.mp4';
import FlatButton from './FlatButton';
import arrowDown from './img/arrow_down_black.svg';
import colors from './styles/colors';
import {navHeight} from './NavBar';
import CloseIcon from '@material-ui/icons/Close';
import useToggleState from "./hooks/useToggleState";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import $ from 'jquery';
import {isSafari} from 'react-device-detect';
import checkbox from "./img/checkbox.svg";
import BenefitsSmall from "./BenefitsSmall";
import underlineSVG from './img/underline_white.svg';
import bgStage from './img/bg_stage.svg';

const polaroidBorder = {
    xs: '15vh',
    lg: '10vh'
};

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        boxSizing: 'border-box',
    },
    stageWrapper: {
        position: 'relative',
        width: '100%',
        height: '100%'
    },
    videoContainer: {
        position: 'relative',
        width: '100vw',
        height: `calc(88vh - ${navHeight.xl})`,
        minHeight: '44vw',
        margin: '0 -5vw',

        [theme.breakpoints.down('md')]: {
            height: `calc(88vh - ${navHeight.md})`,
            margin: '0 -6vw',
        },
        [theme.breakpoints.down('xs')]: {
            height: `calc(80vh - ${navHeight.xs})`,
            margin: '0 -5vw',
        }
    },
    video: {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
    },
    titleOuterWrapper: {
        position: 'absolute',
        bottom: '10%',
        right: '10%',
        width: '40rem',
        height: '28rem',

        [theme.breakpoints.down('md')]: {
            bottom: '5%',
            right: '5%',
        },
        [theme.breakpoints.down('xs')]: {
            bottom: '2%',
            right: '2%',
            width: '96%',
            height: '17rem'
        },
        '@media only screen and (max-width: 400px)': {
            bottom: 0
        }
    },
    titleInnerWrapper: {
        position: 'relative',
        width: '100%',
        height: '100%'
    },
    hGroup: {
        position: 'absolute',
        top: '33%',
        right: '50%',
        display: 'flex',
        width: '25rem',
        flexDirection: 'column',
        alignItems: 'center',
        color: colors.white,
        textAlign: 'center',
        transform: 'translateX(50%)',
        zIndex: 1,

        [theme.breakpoints.down('xs')]: {
            bottom: 0,
            width: '23.5rem',
            maxWidth: '100%',
            transform: 'translateX(50%)',
        }
    },
    stageBg: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        zIndex: 0
    },
    title: {
        position: 'relative',
        fontSize: '3.5rem',
        margin: '0 0 2rem',
        transform: 'rotate(-3deg)',

        [theme.breakpoints.down('md')]: {
            fontSize: '3rem'
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: '2rem',
            marginBottom: '1.5rem'
        },
        '@media (max-width: 320px)': {
            fontSize: '2rem'
        },
        '&:after': {
            content: '\"\"',
            position: 'absolute',
            left: '50%',
            bottom: '-2px',
            width: '90%',
            height: '5px',
            transform: 'translateX(-50%)',
            backgroundImage: `url(${underlineSVG})`,
            backgroundRepeat: 'no-repeat',
            color: colors.white
        }
    },
    subtitle: {
        fontSize: '1.25rem',
        lineHeight: 1.2,
        fontWeight: 800,
        fontFamily: 'Open Sans, sans serif',
        margin: 0,
        transform: 'rotate(0)',

        '&::after': {
            background: 'none'
        },

        [theme.breakpoints.down('xs')]: {
            width: '75%',
            fontSize: '0.875rem'
        }
    },
    scrollDownBtn: {
        position: 'absolute',
        bottom: 0,
        transform: 'translateY(50%)',
        zIndex: 2,
        backgroundColor: colors.white,

        '&:hover': {
            backgroundColor: colors.white,
        },
        '& img': {
            width: '48%',
            transform: 'translateY(2px)'
        }
    },
    closeBtn: {
        position: 'absolute',
        top: '1rem',
        right: '1rem',
        zIndex: 3,
        backgroundColor: colors.black,

        '&:hover': {
            backgroundColor: colors.black,
        },
        '& svg': {
            color: colors.white,
            fontSize: '2rem'
        }
    },
    trustBar: {
        height: '2.5rem',
        margin: '0 -5vw',
        background: colors.black,
        color: colors.white,

        [theme.breakpoints.down('md')]: {
            margin: '0 -6vw',
        },
        [theme.breakpoints.down('xs')]: {
            height: '4.5rem',
            margin: '0 -5vw',
        },
    },
    trustList: {
        height: '100%',
        display: 'flex',
        justifyContent: 'space-evenly',
        alignItems: 'center'
    },
    trustElement: {
        position: 'relative',
        paddingLeft: '1.5rem',
        fontSize: '0.875rem',

        // '&:last-child': {
        //     [theme.breakpoints.down('sm')]: {
        //         display: 'none'
        //     }
        // },

        [theme.breakpoints.down('xs')]: {
            padding: '1.5rem 0.5rem 0.3rem',
            textAlign: 'center',
            fontSize: '0.75rem'
        },

        '&:before': {
            content: '\"\"',
            position: 'absolute',
            left: '2px',
            top: '0.2rem',
            width: '15px',
            height: '15px',
            backgroundImage: `url(${checkbox})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'contain',

            [theme.breakpoints.down('xs')]: {
                top: '7px',
                left: '52%',
                width: '0.9rem',
                height: '0.9rem',
                transform: 'translateX(-50%)'
            }
        }
    }
}));

function Stage(props) {
    const classes = useStyles();
    const matchesSmUp = useMediaQuery('(min-width: 600px)');
    const [ showMoodVideo, toggleMoodVideo ] = useToggleState(false);
    const videoRefTeaser = useRef(null);
    const videoRefMood = useRef(null);

    const scrollDown = () => {
        if (matchesSmUp) {
            window.scrollTo({ top: (window.innerHeight - (window.innerHeight * 0.05)), behavior: 'smooth' });
        } else {
            $("html, body").animate({ scrollTop: window.innerHeight });
        }
    };

    const posterImg = isSafari ? posterJpeg : posterWebP;

    const videoOptionsTeaser = {
        src: teaserVideoMP4,
        autoPlay: 'autoplay',
        loop: 'loop',
        poster: posterImg,
        muted: 'muted',
        ref: videoRefTeaser,
        playsInline: 'playsinline',
        preload: 'auto'
    };

    const videoOptionsMood = {
        src: moodVideo,
        autoPlay: true,
        controls: true,
        ref: videoRefMood
    };

    useEffect(() => {
        if (showMoodVideo) {
            videoRefMood.current.addEventListener("webkitendfullscreen", toggleMoodVideo, false);
        }
    }, [showMoodVideo]);

    const handleVideoChange = () => {
        toggleMoodVideo();
    };

    return (
        <section className={classes.root}>
            <div className={classes.stageWrapper}>
                <div className={classes.videoContainer}>
                    <div className={classes.titleOuterWrapper}>
                        <div className={classes.titleInnerWrapper}>
                            <img src={bgStage} alt="" className={classes.stageBg}/>
                            <hgroup className={classes.hGroup}>
                                <h1 className={classes.title}>
                                    The social way
                                    <span className="line-break">of traveling</span>
                                </h1>
                                <h2 className={classes.subtitle}>Explore the world with new friends on our organized small-group adventures</h2>
                            </hgroup>
                        </div>
                    </div>
                        {/*<FlatButton*/}
                        {/*    variant="contained"*/}
                        {/*    className={classes.button}*/}
                        {/*    bgColor={colors.black}*/}
                        {/*    color={colors.white}*/}
                        {/*    onClick={handleVideoChange}*/}
                        {/*>*/}
                        {/*    Watch Video*/}
                        {/*</FlatButton>*/}
                    <VideoCover
                        videoOptions={videoOptionsTeaser}
                    />
                </div>
                <BenefitsSmall />
                {/*{showMoodVideo &&*/}
                {/*    <div className={classes.videoContainer}>*/}
                {/*        <VideoCover videoOptions={videoOptionsMood}/>*/}
                {/*        <Fab className={classes.closeBtn} color={colors.black} onClick={handleVideoChange}>*/}
                {/*            <CloseIcon/>*/}
                {/*        </Fab>*/}
                {/*    </div>*/}
                {/*}*/}
                {/*<Fab className={classes.scrollDownBtn} onClick={scrollDown} disableRipple>*/}
                {/*    <img src={arrowDown} alt="Scroll Down"/>*/}
                {/*</Fab>*/}

                {/*<div className={classes.trustBar}>*/}
                {/*    <ul className={classes.trustList}>*/}
                {/*        <li className={classes.trustElement}>Free cancellation</li>*/}
                {/*        <li className={classes.trustElement}>Insured Travel</li>*/}
                {/*        <li className={classes.trustElement}>Small Groups</li>*/}
                {/*        <li className={classes.trustElement}>Low deposit</li>*/}
                {/*        <li className={classes.trustElement}>5.0 Google Rating</li>*/}
                {/*    </ul>*/}
                {/*</div>*/}
            </div>
        </section>
    );
}

export default Stage;