import React from 'react';
import makeStyles from "@material-ui/core/styles/makeStyles";
import missionJpegLarge from './img/about/about_mission_large.jpg';
import missionWebpLarge from './img/about/about_mission_large.webp';
import missionJpegSmall from './img/about/about_mission_small.jpg';
import missionWebpSmall from './img/about/about_mission_small.webp';
import boxShadows from "./styles/boxShadows";
import bg from './img/about/bg_mission.svg';

const useStyles = makeStyles(theme => ({
    root: {
        margin: '8rem auto 15rem',
        maxWidth: '80rem',

        [theme.breakpoints.down('sm')]: {
            margin: '5rem auto 10rem'
        },
        [theme.breakpoints.down('xs')]: {
            margin: '4rem 0 6rem'
        },
    },
    title: {
        marginBottom: '5rem',

        [theme.breakpoints.down('sm')]: {
            marginBottom: '4rem',
            fontSize: '2.5rem'
        },
        [theme.breakpoints.down('xs')]: {
            marginBottom: '3rem'
        }
    },
    content: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',

        [theme.breakpoints.down('xs')]: {
            flexWrap: 'wrap'
        },
    },
    textWrapper: {
        width: '50%',
        padding: '0 3rem',

        [theme.breakpoints.down('sm')]: {
            padding: '0 3rem 0 0'
        },
        [theme.breakpoints.down('xs')]: {
            width: '100%',
            padding: 0,
            marginBottom: '4rem'
        },
    },
    imgWrapper: {
        position: 'relative',
        width: '47%',

        [theme.breakpoints.down('xs')]: {
            width: '90%',
            margin: '0 auto'
        },
    },
    img: {
        position: 'relative',
        zIndex: 1,
        boxShadow: boxShadows.normal
    },
    background: {
        position: 'absolute',
        top: '-40%',
        right: '-20%',
        width: '130%',
        zIndex: 0,

        [theme.breakpoints.down('md')]: {
            right: '-10%',
            width: '120%'
        },
        [theme.breakpoints.down('xs')]: {
            top: '-25%'
        },

    },
    illu: {
        position: 'absolute',
        top: '-89%',
        right: '15%',
        width: '50%'
    }
}));

function Mission(props) {
    const classes = useStyles();

    return (
        <section className={classes.root}>
            <h2 className={classes.title}>Travel with style</h2>
            <article className={classes.content}>
                <div className={classes.textWrapper}>
                    <p>
                        Our goal is to offer you the best possible way of enjoying your destination, bringing
                        you closer to the local way of life, its people and their culture.
                    </p>
                    <p>
                        That's why we organize trips in small groups for young and young-minded travellers,
                        that want to experience the world with local insiders off the beaten track.
                    </p>
                    <p>
                        We carefully design every trip individually according to the particularities of the
                        location, always including loads of local and unique travel experiences that make for
                        lasting memories.
                    </p>
                </div>
                <figure className={classes.imgWrapper}>
                    <picture>
                        <source
                            src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
                            data-srcset={`${missionWebpSmall} 350w, ${missionWebpLarge} 600w`}
                            type="image/webp"
                        />
                        <source
                            src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
                            data-srcset={`${missionJpegSmall} 350w, ${missionJpegLarge} 600w`}
                            type="image/jpeg"
                        />
                        <img
                            src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
                            data-src={missionJpegLarge}
                            alt="A group of young travelers exploring the city together"
                            className={`lazyload ${classes.img}`}
                            data-sizes="auto"
                        />
                    </picture>
                    <img src={bg} alt="" className={classes.background}/>
                </figure>
            </article>
        </section>
    );
}

export default Mission;