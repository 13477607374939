import React, {useEffect, useRef} from 'react';
import makeStyles from "@material-ui/core/styles/makeStyles";
import colors from "./styles/colors";
import { withRouter, Link } from 'react-router-dom';
import _ from 'lodash';

const useStyles = makeStyles(theme => ({
    root: {
        position: 'relative',
        paddingTop: '40%',
        backgroundImage: props => `url(${props.img.large})`,
        backgroundPositionX: 'center',
        backgroundPositionY: '100%',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        transition: 'transform 300ms ease',

        [theme.breakpoints.down('sm')]: {
            fontSize: '3rem',
            backgroundImage: props => `url(${props.img.medium})`,
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: '1rem',
            paddingTop: '50%',
            backgroundSize: '110%',
        },
        '@media (max-width: 300px)': {
            backgroundImage: props => `url(${props.img.small})`,
        },
        '&:hover': {
            cursor: 'pointer',
        }
    },
    innerWrapper: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    title: {
        color: colors.black,
        transform: 'none',
        // textShadow: '1px 1px 2px rgba(0,0,0,0.3)',

        [theme.breakpoints.up('xl')]: {
            fontSize: '6rem'
        },

        '&::after': {
            background: 'none'
        },
        '& .trip-link': {
            color: colors.black,
            textDecoration: 'none',

            '&:visited, &:focus': {
                color: colors.black
            }
        }
    }
}));

function TripParallaxTeaser(props) {
    const classes = useStyles(props);
    const {slug, title} = props;
    const parallax = useRef(null);
    const url = `/trip/${slug}`;

    const handleClick = () => {
        props.history.push(url);
    };

    const handleLinkClick = (e) => {
        e.stopPropagation();
    };

    const handleScroll = () => {
        const element = parallax.current;
        const elementOffsetTop = element.offsetTop;
        const elementHeight = element.offsetHeight;

        if (
            (window.scrollY + window.innerHeight) > elementOffsetTop &&
            (window.scrollY + window.innerHeight) < (elementOffsetTop + window.scrollY + elementHeight)
        ) {
            const scrollHeight = elementHeight + window.innerHeight;
            const step = scrollHeight / 100;
            let pos = ((elementOffsetTop - window.scrollY + elementHeight) / step) > 0 ?  ((elementOffsetTop - window.scrollY + elementHeight) / step) : 0;
            element.style.backgroundPositionY = pos + '%';

        } else if (window.innerHeight > elementOffsetTop) {
            const step = (elementOffsetTop + elementHeight) / 100;
            let pos = window.scrollY / step < 100 ? (100 - (window.scrollY / step)) : 0;
            element.style.backgroundPositionY = pos + '%';
        }
    };

    const throttleScroll = _.throttle(handleScroll, 16);

    useEffect(() => {
        document.addEventListener('scroll', throttleScroll, false);
        return () => {
            document.removeEventListener('scroll', throttleScroll, false);
        }
    });

    return (
            <article className={classes.root} onClick={handleClick} ref={parallax}>
                <div className={classes.innerWrapper}>
                    <h2 className={classes.title}>
                        <Link to={url} className="trip-link" onClick={handleLinkClick}>{title}</Link>
                    </h2>
                </div>
            </article>
    );
}

export default withRouter(TripParallaxTeaser);