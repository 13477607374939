import React, {createRef, useEffect, useRef, useState} from 'react';
import CityTeaser from "./BlogCityTeaser";
import img from './img/berlin/cover.jpg';
import makeStyles from "@material-ui/core/styles/makeStyles";
import arrowLeft from './img/arrow_left.svg';
import arrowRight from './img/arrow_right.svg';

const useStyles = makeStyles(theme => ({
    header: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    title: {
        marginBottom: '2rem'
    },
    lane: {
        overflow: 'hidden',

        '& .no-scrollbar': {
            '&::-webkit-scrollbar': {
                height: 0
            },
            '&::-webkit-scrollbar-track': {
                background: 'transparent'
            },
            '&::-webkit-scrollbar-thumb': {
                background: 'transparent'
            },
        },
        [theme.breakpoints.up('sm')]: {
            margin: props => props.margin
        },
        [theme.breakpoints.down('xs')]: {
            margin: props => props.marginMobile
        }
    },
    arrow: {
        border: 0,
        background: 'none',
        cursor: 'pointer',
        outline: 0,

        '& .img': {
            width: 'auto',
            height: '1.5rem'
        }
    }
}));

function BlogLane(props) {
    const classes = useStyles(props);
    const {title, showArrows, isLoaded, laneRef, children} = props;
    let lane = null;
    let laneItems = null;
    let initialPadding = 0;
    let columnsToScroll = 1;
    let columnMargin = 0;

    useEffect(() => {
        if (isLoaded) {
            lane = laneRef.current;
            laneItems = lane.querySelectorAll('.js-lane-item');
            columnsToScroll = Math.floor(lane.offsetWidth / laneItems[0].offsetWidth);
            initialPadding = laneItems[0].offsetLeft;
            columnMargin = parseInt(window.getComputedStyle(laneItems[0]).getPropertyValue('margin-right'));
            setTimeout(hideScrollBars, 1000);
        }
    }, [isLoaded]);

    const handleScroll = (scrollAmount) => {
        lane.scrollBy({left: scrollAmount, behavior: 'smooth'});
    };

    const getCurrentImageIndex = () => {
        const scrollLeft = lane.scrollLeft - initialPadding;

        return Math.round(scrollLeft / (laneItems[0].offsetWidth + columnMargin));
    };

    const scrollRight = () => {
        let index = getCurrentImageIndex();
        index += columnsToScroll;
        index = index >= laneItems.length ? laneItems.length - 1 : index;
        let scrollAmount = laneItems[index].offsetLeft - lane.scrollLeft - initialPadding;

        handleScroll(scrollAmount);
    };

    const scrollLeft = () => {
        let index = getCurrentImageIndex();
        index -= columnsToScroll;
        index = index < 0 ? 0 : index;
        let scrollAmount =  (lane.scrollLeft - laneItems[index].offsetLeft + initialPadding) * -1;

        handleScroll(scrollAmount);
    };

    const hideScrollBars = () => {
        if (lane.offsetHeight > lane.clientHeight) {
            lane.style.paddingBottom = '15px';
        }
    };

    return (
        <React.Fragment>
            <header className={classes.header}>
                <h3 className={classes.title}>{title}</h3>
                {
                    showArrows &&
                    <div className={classes.arrowWrapper}>
                        <button className={classes.arrow} onClick={scrollLeft}>
                            <img src={arrowLeft} alt="Scroll left" className="img"/>
                        </button>
                        <button className={classes.arrow} onClick={scrollRight}>
                            <img src={arrowRight} alt="Scroll right" className="img"/>
                        </button>
                    </div>
                }
            </header>
            <div className={classes.lane}>
                {children}
            </div>
        </React.Fragment>
    );
}

export default BlogLane;