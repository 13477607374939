import React, {useEffect, useState} from 'react';
import makeStyles from "@material-ui/core/styles/makeStyles";
import BlogFeaturedArticleTeaser from "./BlogFeaturedArticleTeaser";
import colors from "./styles/colors";
import bg from './img/blog/bg_blog_city_featured.svg';
import axios from 'axios';
import BlogArticleTeaser from "./BlogArticleTeaser";

const useStyles = makeStyles(theme => ({
    root: {
        position: 'relative',
        maxWidth: '80rem',
        margin: '0 auto 10rem',

        [theme.breakpoints.down('xs')]: {
            marginBottom: '5rem'
        },
    },
    background: {
        position: 'absolute',
        width: '30%',
        top: '30%',
        left: '-14%'
    },
    title: {
        marginBottom: '2rem'
    },
    articleList: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-between',

        '& .article-wrapper': {
            position:  'relative',
            height: '25rem',
            marginBottom: '1.5rem',
            backgroundColor: colors.lightTransparentBlack,
            overflow: 'hidden',

            [theme.breakpoints.down('xs')]: {
                height: '58vw',
                marginBottom: '0.5rem'
            },
            '&.fullWidth': {
                width: '100%',
            },
            '&.halfWidth': {
                width: 'calc(50% - 0.75rem)',
                height: '22vw',

                [theme.breakpoints.down('md')]: {
                    height: '25vw'
                },

                [theme.breakpoints.down('sm')]: {
                    height: '28vw'
                },

                [theme.breakpoints.down('xs')]: {
                    width: '100%',
                    height: '58vw',
                }
            },
            '&.thirdWidth': {
                width: 'calc(33.333333% - 1rem)',
                height: '16vw',

                [theme.breakpoints.down('md')]: {
                    height: '18vw'
                },

                [theme.breakpoints.down('sm')]: {
                    width: 'calc(50% - 0.75rem)',
                    height: '28vw',

                    '&.first': {
                        width: '100%',
                        height: '25rem',

                        [theme.breakpoints.down('xs')]: {
                            height: '58vw',
                        }
                    }
                },

                [theme.breakpoints.down('xs')]: {
                    width: '100%',
                    height: '58vw',
                }
            }
        },
    },
}));

function BlogFeaturedArticles(props) {
    const classes = useStyles();
    const [isLoading, setLoading] = useState(true);
    const [articles, setArticles] = useState([]);
    const {baseUrl, cityId} =  props;

    useEffect(() => {
        getFeaturedPosts();
    }, []);

    const getFeaturedPosts = async () => {
        const filters = await getFilters();
        const url = `${baseUrl}/posts?per_page=6&${filters}`;

        axios.get(url)
            .then((response) => {
                setArticles(response.data);
                setLoading(false);
            })
            .catch(function (error) {
                // handle error
                console.log(error);
            });
    };

    const getFilters = async () => {
        let cityFilter = '';
        let featuredTagId = '';

        if (typeof cityId !== 'undefined') {
            cityFilter = await getCityTagId();
        }

        return axios.get(`${baseUrl}/tags?slug=featured`)
            .then(response => {
                featuredTagId = response.data[0].id;
                let filters = `tags=${featuredTagId}`;

                if (cityFilter !== '') {
                    filters += `&tags=${cityFilter}`
                }
                return filters;
            })
            .catch(function (error) {
                // handle error
                console.log(error);
            });
    };

    const getCityTagId = async () => {
        return axios.get(`${baseUrl}/tags?slug=${cityId}`)
            .then(response => {
                return response.data[0].id
            })
            .catch(function (error) {
                // handle error
                console.log(error);
            });
    };

    if(isLoading) {
        return <p>Loading...</p>;
    } else {
        return (
            <section className={classes.root}>
                <h3 className={classes.title}>Featured Articles</h3>
                <img src={bg} alt="" className={classes.background}/>
                <ul className={classes.articleList}>
                    {
                        articles.map((article, index) => (
                            <li className={`article-wrapper 
                                ${index === 0 ? 'fullWidth' : ''} 
                                ${(index === 2 || index === 1) ? 'halfWidth' : ''}
                                ${index === 3 ? 'thirdWidth first' : ''} 
                                ${index > 3 ? 'thirdWidth' : ''}`}

                                key={index}
                            >
                                <BlogFeaturedArticleTeaser
                                    title={article.acf.meta_information.title}
                                    cityId={article.acf.meta_information.city_slug}
                                    slug={article.slug}
                                    img={article.acf.meta_information.article_image}
                                    index={index}
                                />
                            </li>
                        ))
                    }
                </ul>
            </section>
        );
    }
}

export default BlogFeaturedArticles;