import React, {useEffect, useState} from 'react';
import useToggleState from './hooks/useToggleState';
import {Link} from 'react-router-dom';
import {makeStyles} from '@material-ui/core/styles';
import burgerIcon from './img/burger_icon.svg';
import FlatButton from "./FlatButton";
import colors from "./styles/colors";
import useMediaQuery from "@material-ui/core/useMediaQuery/useMediaQuery";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import CloseIcon from '@material-ui/icons/Close';
import {withRouter} from 'react-router-dom';

function TopNavigation(props) {
    const scrollTrigger = props.scrollTrigger;
    const [navOpen, toggleNav] = useToggleState(false);
    const [showMenuButton, toggleMenuButton] = useToggleState(true);
    const matchesMdUp = useMediaQuery('(min-width: 960px)');
    const matchesLgUp = useMediaQuery('(min-width: 1024px)');
    const initialNavItems = [
        { type: 'link', name: 'Trips', path: '/trips', linkType: 'internal' },
        { type: 'link', name: 'How it works', path: '/how-it-works', linkType: 'internal' },
        { type: 'link', name: 'About Us', path: '/about', linkType: 'internal' },
        { type: 'link', name: 'Blog', path: '/blog', linkType: 'external' },
        // { type: 'button', name: 'Login', path: 'https://google.com', linkType: 'external' },
    ];

    const [navItems, setNavItems] = useState(initialNavItems);

    useEffect(() => {
        if (props.location.pathname !== '/' && navItems[0].path !== '/') {
            setTimeout(() => {
                let tempNavItems = initialNavItems;
                tempNavItems.unshift({ type: 'link', name: 'Home', path: '/', linkType: 'internal' });
                setNavItems(tempNavItems);
            }, 1000);
        } else if (props.location.pathname === '/' && navItems[0].path === '/') {
            setTimeout(() => {
                let tempNavItems = navItems;
                tempNavItems.shift();
                setNavItems(tempNavItems);
            }, 1000);
        }
    }, [props.location.pathname]);

    useEffect(() => {
        if (matchesLgUp && scrollTrigger && !navOpen) {
            toggleNav();

            if (matchesMdUp) {
                toggleMenuButton();
            }
        } else if (scrollTrigger === false && navOpen) {
            toggleNav();

            if (matchesMdUp) {
                toggleMenuButton();
            }
        }
    }, [scrollTrigger]);

    const handleMenuButtonClick = () => {
        toggleNav();
        if (matchesMdUp) {
            toggleMenuButton();
        }
    };

    const handleLinkClick = () => {
        toggleNav();
        toggleMenuButton();
    };

    const useStyles = makeStyles(theme => ({
        nav: {
            alignItems: 'center',
            display: 'flex',
            overflow: 'hidden'
        },
        navList: {
            transform: navOpen ? 'translateX(0)' : 'translateX(101%)',
            transition: 'transform 500ms ease-out',
            display: 'flex',
            alignItems: 'center',
            listStyle: 'none',
            padding: 0,
            '& li': {
                marginRight: '2rem',
                fontWeight: 800,

                [theme.breakpoints.down('sm')]: {
                    marginRight: '1rem'
                },

                '& a': {
                    color: 'inherit',
                    textDecoration: 'none',
                    '&:hover': {
                        color: '#555'
                    },
                    '&:visited': {
                        color: 'inherit',

                        '&:hover': {
                            color: '#555'
                        },
                    }
                },
                // '&:last-child': {
                //     '& a': {
                //         fontSize: '1rem',
                //         color: colors.white,
                //         minWidth: 0,
                //         padding: '0.3rem 2rem',
                //
                //         '&:visited': {
                //             color: colors.white,
                //
                //             '&:hover': {
                //                 color: colors.white,
                //             }
                //         },
                //         '&:hover': {
                //             color: colors.white,
                //             boxShadow: 'none',
                //         }
                //     }
                // }
            },
            [theme.breakpoints.down('sm')]: {
                flexDirection: 'column',
                padding: '3rem',

                '&.desktop': {
                    position: 'absolute',
                    transform: 'translateX(1000px)'
                },
                '& li': {
                    margin: ' 0 0 1.5rem'
                }
            }
        },
        menuButton: {
            backgroundColor: colors.white,
            padding: '0.5rem',
            border: 'none',
            zIndex: 1,
            cursor: 'pointer',
            marginRight: 0,

            '&:focus': {
                outline: 0
            },
            '& img': {
                height: '1.5rem',
                width: 'auto',

                [theme.breakpoints.down('sm')]: {
                    height: '1.4rem'
                }
            },
            [theme.breakpoints.down('sm')]: {
                padding: 0
            },
        },
        closeIcon: {
            width: '34px',
            height: '24px'
        }
    }));
    const classes = useStyles();

    return (
        <React.Fragment>
            <nav className={classes.nav}>
                {
                    <ul className={`${classes.navList} desktop`}>
                        {navItems.map(item => (
                            <li key={item.name}>
                                {
                                    item.linkType === 'internal' ?
                                        <Link
                                            onClick={handleLinkClick}
                                            title={item.name}
                                            to={item.path}
                                        >
                                            {item.name}
                                        </Link> :
                                        <span>
                                            {
                                                item.type === 'button' ?
                                                    <FlatButton
                                                        className={classes.loginBtn}
                                                        color={colors.white}
                                                        bgColor={colors.black}
                                                        href={item.path}
                                                        title={item.name}
                                                    >
                                                        {item.name}
                                                    </FlatButton> :
                                                    <a
                                                        title={item.name}
                                                        href={item.path}
                                                        target="_blank"
                                                    >
                                                        {item.name}
                                                    </a>
                                            }
                                        </span>
                                }
                            </li>
                        ))}
                    </ul>
                }
                <button
                    className={classes.menuButton}
                    aria-label="Open Navigation"
                    onClick={handleMenuButtonClick}
                >
                    {showMenuButton ?
                        <img src={burgerIcon} alt="Burger Icon"/> :
                        <CloseIcon viewBox={'5 5 14 14'} className={classes.closeIcon}/>
                    }
                </button>
            </nav>
            {
                !matchesMdUp  &&
                <SwipeableDrawer
                    anchor="right"
                    open={navOpen}
                    onClose={toggleNav}
                    onOpen={toggleNav}
                >
                    <ul className={`${classes.navList} mobile`}>
                        {navItems.map(item => (
                            <li key={item.name}>
                                {
                                    item.linkType === 'internal' ?
                                        <Link onClick={toggleNav} title={item.name} to={item.path}>{item.name}</Link> :
                                        <span>
                                            {
                                                item.type === 'button' ?
                                                    <FlatButton
                                                        className={classes.loginBtn}
                                                        color={colors.white}
                                                        bgColor={colors.black}
                                                        href={item.path}
                                                        title={item.name}
                                                    >
                                                        {item.name}
                                                    </FlatButton> :
                                                    <a
                                                        title={item.name}
                                                        href={item.path}
                                                        target="_blank"
                                                    >
                                                        {item.name}
                                                    </a>
                                            }
                                        </span>
                                }
                            </li>
                        ))}
                    </ul>
                </SwipeableDrawer>
            }
        </React.Fragment>
    );
}

export default withRouter(TopNavigation);