import React from 'react';
import Page from "./Page";
import TripHeader from "./TripHeader";
import tripData from './data/tripData';
import TripSummary from './TripSummary';
import TripInclusions from "./TripInclusions";
import Itinerary from "./Itinerary";
import OptionalExperiences from "./OptionalExperiences";
import Hotel from "./Hotel";
import {Helmet} from "react-helmet";
import TripHowItWorks from "./TripHowItWorks";
import TripInfo from "./TripInfo";
import ReactPixel from "react-facebook-pixel";
import TripImageGrid from "./TripImageGrid";
import TripHost from "./TripHost";

function TripDetailPage(props) {
    const id =  props.match.params.id;
    const { name, slug, city, country, duration, groupSize, languages, minPrice, description, inclusions, itinerary,
        optionalExperiences, hotel, teaserText, airport, bookingLink, deposit, priceDetailsText, activitiesPrice,
        guidePrice } = tripData[id];

    ReactPixel.track('ViewContent', {destination: name});

    return (
        <Page>
            <Helmet>
                <title>{name} | Travelmeister</title>
                <meta name="description" content={teaserText} />
            </Helmet>
            <TripHeader
                id={id}
                slug={slug}
                name={name}
                city={city}
                country={country}
                duration={duration}
                groupSize={groupSize}
                languages={languages}
                minPrice={minPrice}
                bookingLink={bookingLink}
                deposit={deposit}
                priceDetailsText={priceDetailsText}
                activitiesPrice={activitiesPrice}
                guidePrice={guidePrice}
            />
            <TripHowItWorks/>
            <TripSummary description={description}/>
            <TripImageGrid cityId={id}/>
            <TripInclusions inclusions={inclusions}/>
            <TripHost/>
            <Itinerary
                id={id}
                days={itinerary}
            />
            {/*{*/}
            {/*    optionalExperiences.length > 0 && <OptionalExperiences experiences={optionalExperiences} cityId={id} />*/}
            {/*}*/}
            <Hotel {...hotel}/>
            {/*<Instagram/>*/}
            <TripInfo
                id={id}
                city={city}
                country={country}
                airport={airport}
                hotel={hotel}
            />
        </Page>
    );
}

export default TripDetailPage;