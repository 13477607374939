import React from 'react';
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        maxWidth: '80rem',
        margin: '0 auto',
        padding: '5rem 0',

        [theme.breakpoints.down('xs')]: {
            flexWrap: 'wrap',
            padding: '4rem 0'
        }
    },
    title: {
        width: '40%',

        [theme.breakpoints.down('xs')]: {
            width: '100%',
            marginBottom: '1.5rem'
        }
    },
    description: {
        width: '50%',

        [theme.breakpoints.down('md')]: {
            width: '60%'
        },
        [theme.breakpoints.down('xs')]: {
            width: '100%'
        }
    }
}));

function TripSummary(props) {
    const classes = useStyles();
    const description = {__html: props.description.replace(new RegExp('\n', 'g'), '<br/>')};

    return (
        <section className={classes.root} id="overview">
            <h3 className={classes.title}>What we'll do</h3>
            <p className={classes.description} dangerouslySetInnerHTML={description}></p>
        </section>
    );


}

export default TripSummary;