import React, {useEffect, useState} from 'react';
import JSCookies from 'js-cookie';
import ReactPixel from 'react-facebook-pixel';
import Snackbar from '@material-ui/core/Snackbar';
import Button from '@material-ui/core/Button';
import {Link} from 'react-router-dom';
import FlatButton from "./FlatButton";
import makeStyles from "@material-ui/core/styles/makeStyles";
import colors from "./styles/colors";


const useStyles = makeStyles(theme => ({
    root: {
        zIndex: 999999,

        '& .MuiSnackbarContent-action': {
            marginRight: 0
        },

        [theme.breakpoints.up('sm')]: {
            width: '75%',

            '& .MuiSnackbarContent-root': {
                flexWrap: 'nowrap'
            },
        },

        [theme.breakpoints.down('xs')]: {
            left: '1rem',
            right: '1rem',
            bottom: '0.8rem',

            '& .MuiSnackbarContent-action': {
                margin: '0.25rem auto 0.5rem',
                padding: 0
            },
            '& .MuiSnackbarContent-message': {
                textAlign: 'center'
            },
        }
    },
    cookieMessage: {

    },
    privacyLink: {
        color: colors.white,
        textDecoration: 'underline'
    },
    cookieButton: {
        position: 'relative',
        borderRadius: '10rem',
        fontFamily: 'Open Sans, sans-serif',
        fontWeight: 800,
        letterSpacing: 0,
        textTransform: 'initial',
        fontSize: '0.8rem',
        padding: '0.6rem 2rem',
        boxShadow: 'none',
        zIndex: 1,
        backgroundColor: colors.white,

        '&:hover, &:focus': {
            backgroundColor: colors.white,
            boxShadow: props => props.noHover ? 'none' : '0px 10px 25px -8px rgba(0,0,0,0.4)'
        },

        [theme.breakpoints.down('xs')]: {
            padding: '0.5rem 1.5rem',
        },
    }
}));

function CookieConsent(props) {
    const classes = useStyles(props);
    const cookieName ='TMCookieConsent';
    const cookieValue = '';
    const expires = 365;
    const [visible, setVisible] = useState(false);
    const acceptOnScrollPercentage = 20;

    useEffect(() => {
        if (JSCookies.get(cookieName) === undefined) {
            setVisible(true);
        }

        if (window && visible) {
            window.addEventListener('scroll', handleScroll, { passive: true });
        }

        return function cleanup() {
            if (window) {
                window.removeEventListener('scroll', handleScroll);
            }
        };
    });

    /**
     * checks whether scroll has exceeded set amount and fire accept if so.
     */
    const handleScroll = () => {

        if (document) {
            const rootNode = document.documentElement || document.body;

            if (rootNode) {
                // (top / (height - height)) * 100
                const percentage =
                    (rootNode.scrollTop /
                        (rootNode.scrollHeight - rootNode.clientHeight)) *
                    100;

                if (percentage > acceptOnScrollPercentage) {
                    handleAccept();
                }
            }
        }
    };

    /**
     * Set a persistent cookie
     */
    const handleAccept = () => {
        if (window) {
            window.removeEventListener('scroll', handleScroll);
        }

        JSCookies.set(cookieName, cookieValue, {expires});

        ReactPixel.grantConsent();

        setVisible(false)
    };


    return (
        <Snackbar
            className={classes.root}
            anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
            open={visible}
            message={<span className={classes.cookieMessage}><b>Notice.</b> Travelmeister uses cookies to provide necessary website functionality, improve your experience and analyze our traffic. By using our website, you agree to our <Link className={classes.privacyLink} to="/privacy">Privacy Policy</Link> and our cookies usage.</span>}
            key={'bottom' + ' center'}
            sx={{flexWrap: 'nowrap'}}
            action={[
                <Button
                    onClick={handleAccept}
                    variant="contained"
                    className={classes.cookieButton}
                    type="button"
                    name="Accept Cookies"
                >
                    Accept
                </Button>
            ]}
        />
    );
}
export default CookieConsent;