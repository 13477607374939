import React, {useEffect} from 'react';
import Benefit from './Benefit';
import useScript from './hooks/useScript';

import illuLeanBack from './img/illus/illu_lean.svg';
import illuTextLeanBack from './img/gif_local.gif';
import illuBgLeanBack from './img/benefits/illu_bg_lean_back.svg';
import illuClique from './img/illus/illu_clique.svg';
import illuBgClique from './img/benefits/illu_bg_clique.svg';
import illuFun from './img/illus/illu_fun.svg';
import illuTextFun from './img/gif_ride.gif';
import illuBgFun from './img/benefits/illu_bg_fun.svg';

import line0 from './img/benefits/benefits_line_0.svg';
import {ReactComponent as Line1} from './img/benefits/benefits_line_1.svg';
import {ReactComponent as Line2} from './img/benefits/benefits_line_2.svg';
import {ReactComponent as Line3} from './img/benefits/benefits_line_3.svg';
import {ReactComponent as Line4} from './img/benefits/benefits_line_4.svg';
import {makeStyles} from "@material-ui/core/styles";
import { ParallaxProvider } from 'react-scroll-parallax';

const useStyles = makeStyles(theme => ({
    root: {
        position: 'relative',
        width: '100%',
        maxWidth: '80rem',
        margin: '0 auto',

        [theme.breakpoints.down('sm')]: {
            marginBottom: '10rem'
        },
        [theme.breakpoints.down('xs')]: {
            marginBottom: '8rem'
        }
    },
    title: {
        transform: 'rotate(-3deg)',
        margin: '0 0 3rem 5rem',

        [theme.breakpoints.down('sm')]: {
            margin: '0 0 7rem 4rem'
        },
        '@media (min-width: 960px) and (max-width: 1024px)': {
            marginLeft: '6rem'
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: '2rem',
            margin: '0 0 5rem 0'
        }
    },
    animationLine: {
        display: 'block',
        width: '35%',
        margin: '0 auto',

        [theme.breakpoints.down('sm')]: {
            display: 'none'
        },
        '&.zero': {
            position: 'absolute',
            width: '20%',
            bottom: '102%',
            left: '27%'
        },

        '&.first': {
            transform: 'translateX(-3rem)',

            '@media (min-width: 960px) and (max-width: 1024px)': {
                marginBottom: '-2rem',
                width: '30%'
            },

        },
        '&.second': {
            width: '53%',
            transform: 'translateX(-10rem)',
            margin: '1rem auto -2rem',

            '@media (min-width: 1025px) and (max-width: 1280px)': {
                margin: '0 auto -2rem'
            },
            '@media (min-width: 960px) and (max-width: 1024px)': {
                width: '50%',
                margin: '-2rem auto -1rem',
                transform: 'translateX(-9rem)',
            },
        },
        '&.third': {
            width: '50%',
            transform: 'translateX(-11rem)',
            margin: '-1rem auto -5rem',

            '@media (min-width: 1025px) and (max-width: 1280px)': {
                marginBottom: '-4rem'
            },
            '@media (min-width: 960px) and (max-width: 1024px)': {
                margin: '0 auto -1rem',
                transform: 'translateX(-9rem)',
            },

        },
        '&.forth': {
            transform: 'translateX(-5rem)',
            margin: '-3rem auto 3rem',

            '@media (min-width: 960px) and (max-width: 1024px)': {
                margin: '-1rem auto 3rem',
            },
        },
        '& .path': {
            strokeDasharray: 1000,
            animation: '$dash 3s linear'
        },
    },
    '@keyframes dash': {
        to: {
            strokeDashoffset: -1000
        }
    }
}));

function Benefits(props) {
    const classes = useStyles();
    const benefits = [
        {
            id: 0,
            title: 'Lean back & enjoy',
            text: 'Our local Travelmeisters are set to show you the true spirit of their hometown. Off the beaten ' +
                'track you\'ll experience hidden gems and local highlights. You don’t need to worry about the ' +
                'boring details - we take care of everything so you can concentrate on having fun!',
            illustration: {
                path: illuLeanBack,
                altText: 'Person in the water enjoying a drink',
                bg: illuBgLeanBack,
                className: 'lean',
                extras: [
                    {
                        path: illuTextLeanBack,
                        className: 'livin-local'
                    }
                ]
            },
            imgNames: [
                'group_of_travelers_having_beers_and_burgers_on_a_terrace_in_berlin',
                'group_of_friends_enjoying_some_street_food_at_a_food_truck_in_the_park_in_berlin',
                'travelers_laying_on_a_bed_in_a_hostel_with_their_backpacks',
                'group_of_friends_having_local_food',
                'travelers_sitting_at_the_terrace_of_a_hip_bar_in_a_backyard'
            ],
            imgAltTexts: [
                    'Group of friends eating a burger and drinking a beer on the terrace of a restaurant',
                    'Group of friends enjoying some street food at a food truck in the park',
                    '2 friends laying on the bed of a hotel with their backpacks',
                    'Friends having variety of local food and drinks on a table',
                    'Travelers sitting at the terrace of a hip bar in a backyard',
            ],
            animationBefore: {
                component: <Line1 title="" alt="" className={`${classes.animationLine} first`}/>,
            },
            animationAfter: {
                component: <Line2 title="" alt="" className={`${classes.animationLine} second`}/>
            }
        },
        {
            id: 1,
            title: 'The best friends experience',
            text: 'Why travel alone and miss out on the fun of being social? Join a small group of like-minded ' +
                'explorers around your age and embark on your adventure together! Meet them even before the trip in ' +
                'our group chat and build lasting friendships during your stay.',
            illustration: {
                path: illuClique,
                altText: 'Selfie of 3 happy friends',
                bg: illuBgClique,
                className: 'clique'
            },
            imgNames: [
                'group_of_friends_having_dinner_on_rooftop',
                'group_of_friends_hugging_in_front_of_berlin_wall',
                'group_of_friends_taking_pictures_in_a_photo_booth_in_berlin',
                'group_of_friends_talking_and_enjoying_some_drinks_on_a_lakeside_jetty',
                'group_of_travellers_crossing_a_tree_trunk_in_a_forest_in_the_basque_country',
                'group_of_travelers_walking_through_the_city_center'

            ],
            imgAltTexts: [
                'Friends having dinner and drinks on a rooftop in Berlin',
                '3 friends hugging in front of Berlin wall',
                '4 friends taking pictures in a photo booth in Berlin',
                'Group of friends at a lakeside jetty talking and having some drinks',
                'Group of young people crossing a tree trunk in a forest',
                'Group of friends walking through the city center'
            ],
            animationAfter: {
                component: <Line3 title="" alt="" className={`${classes.animationLine} third`}/>
            }
        },
        {
            id: 2,
            title: 'No risk, only fun',
            text: 'Free cancellation until 30 days before arrival and low deposit payment make it easy and risk-free ' +
                'to reserve your spot today. During your trip unique experiences, local food, and nights out will ' +
                'keep you busy and fascinated but still leave enough free time to explore on your own.',
            illustration: {
                path: illuFun,
                altText: '2 friends riding on a rollercoaster',
                bg: illuBgFun,
                className: 'fun',
            },
            imgNames: [
                'group_of_friends_having_fun_in_the_pool',
                'group_of_friends_playing_kicker_at_the_terrace_of_a_bar_or_hostel',
                'group_of_friends_jumping_into_the_pool',
                'group_of_travelers_doing_stand_up_paddle',
                'group_of_friends_enjoying_drinks_and_having_fun_in_a_bar',
                'group_of_friends_dancing_and_drinking_on_a_music_festival',
            ],
            imgAltTexts: [
                'Group of friends having fun in the pool',
                'Group of friends playing kicker at the terrace of a bar or hostel',
                'Group of friends jumping into a pool',
                'A group doing stand-up paddle on a lake',
                'Group of friends having fun and enjoying drinks together in a bar',
                'Group of friends dancing and having drinks on a music festival'
            ],
            // animationAfter: {
            //     component: <Line4 title="" alt="" className={`${classes.animationLine} forth`}/>
            // },
            extras: [
                {
                    path: illuTextFun,
                    className: 'enjoy-the-ride'
                }
            ]
        },
    ];

    return (
        <ParallaxProvider>
            <section className={classes.root} id="benefits">
                <img src={line0} className={`${classes.animationLine} zero`} alt=""/>
                <h2 className={classes.title}>Why Travelmeister ?</h2>
                {
                    benefits.map((benefit, i) => (
                        <div key={i}>
                            { benefit.animationBefore ? benefit.animationBefore.component : '' }
                            <Benefit
                                id={benefit.id}
                                order={(i % 2 === 0) ? 'row' : 'row-reverse'}
                                {...benefit}
                            />
                            { benefit.animationAfter ? benefit.animationAfter.component : '' }
                        </div>
                    ))
                }
            </section>
        </ParallaxProvider>
    );
}

export default Benefits;