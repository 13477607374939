import React from 'react';
import makeStyles from "@material-ui/core/styles/makeStyles";
import colors from "./styles/colors";
import FlatButton from "./FlatButton";
import gif_food from './img/gif_food.gif';
import bg from './img/blog/bg_blog_trip_teaser_small.svg';
import { withRouter } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
    root: {
        [theme.breakpoints.up('sm')]: {
            padding: '0 3rem',
        },

        marginBottom: '5rem',
        background: `url(${bg}) no-repeat 58% 30%`,
        backgroundSize: '58%',

        [theme.breakpoints.down('xs')]: {
            backgroundPosition: '50% 15%',
            backgroundSize: '55%',
        },
    },
    headlineWrapper: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    titleImage: {
        width: 'calc(25% + 2rem)',
        transform: 'rotate(-5deg)',
        margin: '0 1rem 0 -2rem',

        [theme.breakpoints.down('xs')]: {
            margin: '0 0 0 -1.5rem'
        },
    },
    title: {
        [theme.breakpoints.down('xs')]: {
            fontSize: '2.5rem'
        },
        '@media (min-width: 600.05px) and (max-width: 1124px)': {
            fontSize: '3rem'
        },
        '@media (max-width: 400px)': {
            fontSize: '2rem'
        },
        '@media (max-width: 340px)': {
            fontSize: '1.5rem'
        }
    },
    textWrapper: {
        [theme.breakpoints.up('sm')]: {
            display: 'flex',
            justifyContent: 'space-around',
            alignItems: 'center'
        },
        [theme.breakpoints.down('sm')]: {
            textAlign: 'center'
        }
    },
    text: {
        [theme.breakpoints.up('sm')]: {
            width: '50%',
            margin: 0,
        },

        fontSize: '1.1rem',
        lineHeight: '1.5',
        fontWeight: '800'
    }
}));

function BlogTripTeaserSmall(props) {
    const classes = useStyles();
    const {tripName, citySlug, cityName, adContent} = props;
    const headline = adContent.smallAdHeadline;
    let teaserText = adContent.smallAdText;
    teaserText = teaserText.replace('%TRIP_NAME%', tripName);
    teaserText = teaserText.replace('%CITY_NAME%', cityName);

    const handleClick = (e) => {
        e.preventDefault();
        props.history.push(`/trip/${citySlug}`);
    };

    return (
        <article className={classes.root}>
            <div className={classes.headlineWrapper}>
                <img className={classes.titleImage} src={gif_food} alt="Burger with fries"/>
                <h2 className={classes.title}>{headline}</h2>
            </div>
            <div className={classes.textWrapper}>
                <p className={classes.text}>{teaserText}</p>
                <FlatButton small bgColor={colors.black} color={colors.white} onClick={handleClick}>Learn more</FlatButton>
            </div>
        </article>
    );
}

export default withRouter(BlogTripTeaserSmall);