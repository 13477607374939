import React from 'react';
import Page from "./Page";
import PageHeader from "./PageHeader";
import headerImgJpegSmall from './img/about/about_header_small.jpg';
import headerImgWebpSmall from './img/about/about_header_small.jpg';
import headerImgJpegMedium from './img/about/about_header_medium.jpg';
import headerImgWebpMedium from './img/about/about_header_medium.webp';
import headerImgJpegLarge from './img/about/about_header_large.jpg';
import headerImgWebpLarge from './img/about/about_header_large.webp';
import Mission from "./Mission";
import Story from "./Story";
import Values from "./Values";
import NewsletterSmall from "./NewsletterSmall";
import bgNewsletter from './img/about/bg_newsletter_about.svg';
import bgNewsletterMobile from './img/about/bg_newsletter_about_mobile.svg';
import {Helmet} from "react-helmet";

const pageData = {
    headerImg: {
        sources: {
            jpeg: {
                small: headerImgJpegSmall,
                medium: headerImgJpegMedium,
                large: headerImgJpegLarge
            },
            webp: {
                small: headerImgWebpSmall,
                medium: headerImgWebpMedium,
                large: headerImgWebpLarge
            }
        },
        altText: 'Friends playing volleyball at the beach'
    }
};

function AboutPage(props) {
    return (
        <Page>
            <Helmet>
                <title>About Travelmeister | Travel in Style</title>
                <meta name="description"
                      content="Our goal is to offer you the best possible way of enjoying your destination, bringing
                      you closer to the local way of life, its people and their culture."
                />
            </Helmet>
            <PageHeader headerImg={pageData.headerImg} />
            <Mission/>
            <Story/>
            <Values/>
            <NewsletterSmall background={bgNewsletter} mobileBg={bgNewsletterMobile}/>
        </Page>
    );
}

export default AboutPage;