import React from 'react';
import makeStyles from "@material-ui/core/styles/makeStyles";
import useToggleState from "./hooks/useToggleState";
import {useMediaQuery} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    benefitsImage: {
        height: '100%',
        width: 'auto',

        [theme.breakpoints.up('sm')]: {
            '&:hover': {
                transform: 'scale(1.05)',
                transition: 'transform 300ms ease'
            }
        },
    },
    zoom: {
        position: 'fixed',
        width: '95%',
        height: 'auto',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        zIndex: 101,
    },
    zoomOverlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100vw',
        height: '100vh',
        zIndex:  100,
        background: 'rgba(0,0,0,0.9)',
    }
}));

function ZoomImage(props) {
    const matchesXsDown = useMediaQuery('(max-width: 600px)');
    const classes = useStyles(props);
    const {imgName, altText, index} = props;
    const [imageZoom, toggleImageZoom] = useToggleState(false);

    const handleImageZoom = () => {
        if (matchesXsDown) {
            toggleImageZoom();
        }
    };

    return (
        <React.Fragment>
            <picture>
                <source
                    src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
                    data-srcset={`${require(`./img/benefits/small/1x/webp/${imgName}.webp`)} 240w, ${require(`./img/benefits/small/2x/webp/${imgName}.webp`)} 480w`}
                    type="image/webp"
                />
                <source
                    src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
                    data-srcset={`${require(`./img/benefits/small/1x/jpeg/${imgName}.jpg`)} 240w, ${require(`./img/benefits/small/2x/jpeg/${imgName}.jpg`)} 480w`}
                    type="image/jpeg"
                />
                <img
                    src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
                    data-src={require(`./img/benefits/small/1x/jpeg/${imgName}.jpg`)}
                    alt={altText}
                    className={`lazyload ${classes.benefitsImage}`}
                    data-index={index}
                    data-sizes="auto"
                    onClick={handleImageZoom}
                />
            </picture>
            {
                imageZoom &&
                <div
                    className={classes.zoomOverlay}
                    onClick={toggleImageZoom}
                >
                    <picture>
                        <source
                            src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
                            srcSet={`${require(`./img/benefits/medium/1x/webp/${imgName}.webp`)} 1x, ${require(`./img/benefits/medium/2x/webp/${imgName}.webp`)} 2x`}
                            type="image/webp"
                        />
                        <source
                            src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
                            srcSet={`${require(`./img/benefits/medium/1x/jpeg/${imgName}.jpg`)} 1x, ${require(`./img/benefits/medium/2x/jpeg/${imgName}.jpg`)} 2x`}
                            type="image/jpeg"
                        />
                        <img
                            src={require(`./img/benefits/medium/1x/jpeg/${imgName}.jpg`)}
                            alt={altText}
                            className={classes.zoom}
                            data-index={index}
                            onClick={handleImageZoom}
                        />
                    </picture>
                </div>
            }
        </React.Fragment>
    );
}

export default ZoomImage;