import React from 'react';
import storyJpeg from './img/berlin/cover.jpg';
import storyWebp from './img/berlin/cover.webp';
import makeStyles from "@material-ui/core/styles/makeStyles";
import bg from './img/about/bg_story.svg';
import colors from "./styles/colors";

const useStyles = makeStyles(theme => ({
    root: {
        marginBottom: '10rem',

        [theme.breakpoints.down('xs')]: {
            display: 'flex',
            flexDirection: 'column-reverse'
        },
    },
    header: {
        marginBottom: '5rem',
        height: '50vh',

        // '@media (min-width: 1600px)': {
        //     height: '60vh'
        // },
        '@media (max-width: 1024px) and (orientation: portrait)': {
            height: '30vh'
        },
        [theme.breakpoints.down('sm')]: {
            marginBottom: '4rem'
        },
        [theme.breakpoints.down('xs')]: {
            height: '70vw',
            marginBottom: 0
        },
    },
    titleWrapper: {
        position: 'relative',
        // paddingLeft: '5rem',

        // [theme.breakpoints.down('md')]: {
        //     paddingLeft: '2rem'
        // },
        // [theme.breakpoints.down('xs')]: {
        //     marginBottom: '3rem',
        //     paddingLeft: 0
        // },
        // [theme.breakpoints.up('xl')]: {
        //     paddingLeft: 0
        // },
        [theme.breakpoints.down('sm')]: {
            marginBottom: '3rem',
            width: '100%'
        },

        width: '50%'
    },
    title: {
        zIndex: 1,
        // [theme.breakpoints.down('sm')]: {
        //     fontSize: '2.5rem'
        // }
    },
    imgWrapper: {
        position: 'relative',
        width: '100%',
        height: '100%'
    },
    img: {
        position: 'relative',
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        zIndex: 1
    },
    background: {
        position: 'absolute',
        left: '-4.5%',
        bottom: '-60%',
        width: '45%',
        zIndex: 0,

        [theme.breakpoints.down('sm')]: {
            bottom: '-40%',
        },
        '@media (max-width: 1024px) and (orientation: portrait)': {
            bottom: '-60%'
        },
        [theme.breakpoints.down('xs')]: {
            left: '-5%',
            bottom: '-25%',
            width: '70%',
        },
        [theme.breakpoints.up('xl')]: {
            bottom: '-40%'
        },
    },
    content: {
        display: 'flex',
        // justifyContent: 'space-between',
        maxWidth: '80rem',
        margin: '0 auto',

        // [theme.breakpoints.down('xs')]: {
        //     flexWrap: 'wrap'
        // },
        [theme.breakpoints.down('sm')]: {
            flexWrap: 'wrap'
        },
    },
    textWrapper: {
        // width: '50%',
        width: '70%',

        // [theme.breakpoints.down('xs')]: {
        //     width: '100%',
        //     marginBottom: '1rem'
        // },
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            marginBottom: '1rem'
        },
    },
    textLink: {
        color: colors.purple,
        textDecoration: 'none'
    }
}));

function Story(props) {
    const classes = useStyles();

    return (
        <section className={classes.root}>
            {/*<header className={classes.header}>*/}
            {/*    <figure className={classes.imgWrapper}>*/}
            {/*        <picture>*/}
            {/*            <source*/}
            {/*                src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="*/}
            {/*                data-srcset={storyWebp}*/}
            {/*                type="image/webp"*/}
            {/*            />*/}
            {/*            <source*/}
            {/*                src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="*/}
            {/*                data-srcset={storyJpeg}*/}
            {/*                type="image/jpeg"*/}
            {/*            />*/}
            {/*            <img*/}
            {/*                src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="*/}
            {/*                data-src={storyJpeg}*/}
            {/*                alt="A group of young travelers in Berlin at the vitory column"*/}
            {/*                className={`lazyload ${classes.img}`}*/}
            {/*                data-sizes="auto"*/}
            {/*            />*/}
            {/*        </picture>*/}
            {/*        <img src={bg} alt="" className={classes.background}/>*/}
            {/*    </figure>*/}
            {/*</header>*/}
            <div className={classes.content}>
                <div className={classes.titleWrapper}>
                    <h2 className={classes.title}>Who we are</h2>
                </div>
                <div className={classes.textWrapper}>
                    <p>
                        Travelmeister was founded in 2019 by Naiara, Noé and Alejandro. After 10 years of hosting
                        and organizing the stays of our friends in Berlin, we decided to share our knowledge of the
                        city with everyone. Having all grown up in the Basque Country in Spain, our second
                        destination was very easy to decide ;)
                    </p>
                    <p>
                        To enhance our offer, we are constantly looking for new destinations and inspiring people
                        that share our vision and values. If you think your city should be part of Travelmeister
                        don’t hesitate to <a target="_blank" href="mailto:contact@travelmeister.eu" className={classes.textLink}>
                        get in touch with us</a>.
                    </p>
                </div>
            </div>
        </section>
    );
}

export default Story;