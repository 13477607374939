import useToggleState from "./hooks/useToggleState";
import Drawer from "@material-ui/core/Drawer/Drawer";
import React, {useEffect} from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import bgImage from "./img/blog/bg_blog_newsletter.svg";
import mobileBgImage from "./img/blog/bg_blog_newsletter_mobile.svg";
import NewsletterForm from "./NewsletterForm";
import illu from "./img/illus/illu_newsletter.svg";

const useStyles = makeStyles(theme => ({
    drawer: {
        '& .paper': {
            background: 'none',
            boxShadow: 'none'
        }
    },
    root: {
        position: 'relative',
        backgroundImage: `url(${bgImage})`,
        backgroundSize: '101%',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'top center',
        padding: '6rem 5vw 4rem',

        [theme.breakpoints.down('md')]: {
            margin: '0',
            padding: '6rem 6vw 4rem',
        },
        [theme.breakpoints.down('xs')]: {
            margin: '0',
            padding: '3rem 5vw',
            backgroundImage: `url(${mobileBgImage})`
        },
    },
    outerWrapper: {
        maxWidth: '80rem',
        margin: '0 auto'
    },
    innerWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',

        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column',
            alignItems: 'flex-start'
        }
    },
    leftColumn: {
        width: '40%',

        [theme.breakpoints.down('xs')]: {
            marginBottom: '1.5rem',
            width: '100%'
        }
    },
    rightColumn: {
        width: '50%',

        [theme.breakpoints.down('xs')]: {
            width: '100%'
        }
    },
    title: {
        marginBottom: '2rem'
    },
    description: {
        marginBottom: 0
    }
}));

function PreRegistrationForm(props) {
    const [drawerOpen, toggleDrawer] = useToggleState(false);
    const classes = useStyles(props);

    useEffect(() => {
        setTimeout(toggleDrawer, 1000)
    }, []);


    return (
        <Drawer
            className={classes.drawer}
            open={drawerOpen}
            anchor="bottom"
            onClose={toggleDrawer}
            classes={{paper: 'paper'}}
        >
            <section className={classes.root}>
                <div className={classes.outerWrapper}>
                    <div className={classes.innerWrapper}>
                        <div className={classes.leftColumn}>
                            <h2 className={classes.title}>Stay updated!</h2>
                            <p className={classes.description}>
                                Be the first one to know when our trips start and secure your spot in one of our amazing adventures!
                            </p>
                        </div>
                        <div className={classes.rightColumn}>
                            <NewsletterForm buttonText="Subscribe"/>
                        </div>
                    </div>
                </div>
            </section>

            {/*<Newsletter/>*/}
        </Drawer>
    )
}

export default PreRegistrationForm;