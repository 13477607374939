import React from 'react';
import makeStyles from "@material-ui/core/styles/makeStyles";

import charmJpegLarge from './img/how-it-works/how_it_works_charm_large.jpg';
import charmJpegSmall from './img/how-it-works/how_it_works_charm_small.jpg';
import charmWebpLarge from './img/how-it-works/how_it_works_charm_large.webp';
import charmWebpSmall from './img/how-it-works/how_it_works_charm_small.webp';

import localJpegLarge from './img/how-it-works/how_it_works_local_large.jpg';
import localWebpLarge from './img/how-it-works/how_it_works_local_large.webp';
import localJpegSmall from './img/how-it-works/how_it_works_local_small.jpg';
import localWebpSmall from './img/how-it-works/how_it_works_local_small.webp';

import hottestPlacesJpegLarge from './img/how-it-works/how_it_works_hottest_places_large.jpg';
import hottestPlacesWebpLarge from './img/how-it-works/how_it_works_hottest_places_large.webp';
import hottestPlacesJpegSmall from './img/how-it-works/how_it_works_hottest_places_small.jpg';
import hottestPlacesWebpSmall from './img/how-it-works/how_it_works_hottest_places_small.webp';

import teamJpegLarge from './img/how-it-works/how_it_works_team_large.jpg';
import teamWebpLarge from './img/how-it-works/how_it_works_team_large.webp';
import teamJpegSmall from './img/how-it-works/how_it_works_team_small.jpg';
import teamWebpSmall from './img/how-it-works/how_it_works_team_small.webp';

import bg1 from './img/how-it-works/bg_good_things.svg';

const useStyles = makeStyles(theme => ({
    root: {
        maxWidth: '80rem',
        margin: '0 auto 5rem',

        [theme.breakpoints.down('xs')]: {
            marginBottom: '2rem'
        }
    },
    header: {
        marginBottom: '6rem',

        [theme.breakpoints.down('xs')]: {
            marginBottom: '3rem'
        },
    },
    title: {
        [theme.breakpoints.down('xs')]: {
            fontSize: '2rem'
        }
    },
    valuesList: {
        display: 'flex',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
        // padding: '0 5rem',
        //
        // [theme.breakpoints.down('md')]: {
        //     padding: '0 3rem'
        // },
        // [theme.breakpoints.down('sm')]: {
        //     padding: 0
        // }
    },
    valueItem: {
        width: '47%',
        marginBottom: '8rem',

        [theme.breakpoints.down('xs')]: {
            width: '100%',
            marginBottom: '3rem'
        },
    },
    valueTitle: {

        [theme.breakpoints.down('sm')]: {
            fontSize: '1.5rem',
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: '1.3rem',
            marginBottom: '0.75rem'
        }
    },
    imgWrapper: {
        position: 'relative'
    },
    valueImg: {
        position: 'relative',
        marginBottom: '3rem',
        zIndex: 1,

        [theme.breakpoints.down('xs')]: {
            marginBottom: '1rem'
        },
    },
    valueBg: {
        position: 'absolute',
        zIndex: 0,

        '&.value-4-1': {
            top: '-35%',
            right: '-20%',
            width: '65%'
        }
    }
}));

const goodThings = [
    {
        title: '12 makes for a great team',
        description: 'In a small group, it is easy to get to know each other and form true friendships.',
        img: {
            sources: {
                jpeg: {
                    small: teamJpegSmall,
                    large: teamJpegLarge
                },
                webp: {
                    small: teamWebpSmall,
                    large: teamWebpLarge
                }
            },
            altText: '2 friends making a break while traveling'
        }
    },
    {
        title: '12th time’s a charm',
        description: 'With a minimum of 8 people, you can be sure your future best friend is on board.',
        img: {
            sources: {
                jpeg: {
                    small: charmJpegSmall,
                    large: charmJpegLarge
                },
                webp: {
                    small: charmWebpSmall,
                    large: charmWebpLarge
                }
            },
            altText: 'A local showing the city to friends traveling'
        }
    },
    {
        title: '12 gets you into anything',
        description: 'Our maximum group size assures entry into even the hottest places in town.',
        img: {
            sources: {
                jpeg: {
                    small: hottestPlacesJpegSmall,
                    large: hottestPlacesJpegLarge
                },
                webp: {
                    small: hottestPlacesWebpSmall,
                    large: hottestPlacesWebpLarge
                }
            },
            altText: 'A group of friends hugging while traveling'
        }
    },
    {
        title: '12 still make a local',
        description: 'Our groups are just small enough to be a birthday party and not a tourist bus.',
        img: {
            sources: {
                jpeg: {
                    small: localJpegSmall,
                    large: localJpegLarge
                },
                webp: {
                    small: localWebpSmall,
                    large: localWebpLarge
                }
            },
            altText: 'People in Berlin'
        },
        illus: [bg1]
    }
];

function GoodThings(props) {
    const classes = useStyles();

    return (
        <section className={classes.root}>
            <header className={classes.header}>
                <h2 className={classes.title}>All good things ARE 12</h2>
            </header>
            <ul className={classes.valuesList}>
                {goodThings.map((value, i) => (
                    <li className={classes.valueItem}>
                        <article>
                            <figure className={classes.imgWrapper}>
                                <picture>
                                    <source
                                        src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
                                        data-srcset={`${value.img.sources.webp.small} 400w, ${value.img.sources.webp.large} 600w`}
                                        type="image/webp"
                                    />
                                    <source
                                        src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
                                        data-srcset={`${value.img.sources.jpeg.small} 400w, ${value.img.sources.jpeg.large} 600w`}
                                        type="image/jpeg"
                                    />
                                    <img
                                        src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
                                        data-src={value.img.sources.jpeg.large}
                                        alt={value.img.altText}
                                        className={`lazyload ${classes.valueImg}`}
                                        data-sizes="auto"
                                    />
                                </picture>
                                {
                                    typeof value.illus !== 'undefined'
                                        ?
                                        value.illus.map((illu, j) => <img src={illu} alt="" className={`${classes.valueBg} value-${i+1}-${j+1}`}/>)
                                        :
                                        ''
                                }
                            </figure>
                            <h3 className={classes.valueTitle}>{value.title}</h3>
                            <p>{value.description}</p>
                        </article>
                    </li>
                ))}
            </ul>
        </section>
    );
}

export default GoodThings;