import { useEffect } from 'react';

function useScript(url, id) {
    useEffect(() => {
        const script = document.createElement('script');

        script.src = url;
        script.async = false;
        script.id = id;

        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        }
    }, [url]);
};

export default useScript;