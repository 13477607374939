import React, {useEffect, useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import useToggleState from "./hooks/useToggleState";
import ImageGallery from "./ImageGallery";
import arrowLeft from './img/arrow_left.svg';
import arrowRight from './img/arrow_right.svg';
import {useMediaQuery} from "@material-ui/core";
import ZoomImage from "./ZoomImage";

const scrollBoxHeight = {
    xs: '10rem',
    sm: '8.2rem',
    sm_md: '6.8rem',
    md: '5.4rem',
    lg: '7.75rem',
};

const useStyles = makeStyles(theme => ({
    root: {
        position: 'relative',
        display: 'flex',
    },
    scrollBoxWrapper: {
        height: scrollBoxHeight.xl,
        display: 'flex',
        overflow: 'hidden',
        alignItems: 'flex-start',
        justifyContent: 'space-between',

        [theme.breakpoints.up('lg')]: {
            height: scrollBoxHeight.lg
        },
        [theme.breakpoints.down('md')]: {
            height: scrollBoxHeight.md
        },
        '@media (min-width: 1025px) and (max-width: 1280px)': {
            height: scrollBoxHeight.sm_md
        },
        [theme.breakpoints.down('sm')]: {
            height: scrollBoxHeight.sm
        },
        [theme.breakpoints.down('xs')]: {
            height: scrollBoxHeight.xs
        }
    },
    scrollBox: {
        display: 'flex',
        flexWrap: 'nowrap',
        overflowX: 'scroll',

        '&::-webkit-scrollbar': {
            height: 0
        },
        '&::-webkit-scrollbar-track': {
            background: 'transparent'
        },
        '&::-webkit-scrollbar-thumb': {
            background: 'transparent'
        }
    },
    imgWrapper: {
        height: scrollBoxHeight.xl,
        overflow: 'hidden',
        flexShrink: 0,

        '&:not(:last-child)': {
            marginRight: '0.5rem'
        },
        '&:hover': {
            cursor: 'pointer'
        },
        [theme.breakpoints.up('lg')]: {
            height: scrollBoxHeight.lg
        },
        [theme.breakpoints.down('md')]: {
            height: scrollBoxHeight.md
        },
        '@media (min-width: 1025px) and (max-width: 1280px)': {
            height: scrollBoxHeight.sm_md
        },
        [theme.breakpoints.down('sm')]: {
            height: scrollBoxHeight.sm
        },
        [theme.breakpoints.down('xs')]: {
            height: scrollBoxHeight.xs
        },
    },
    arrow: {
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
        cursor: 'pointer',
        color: '#000',
        width: '1rem',
        transition: 'opacity 100ms linear',

        '&:first-child': {
            left: '-2rem',
            opacity: 0,
        },
        '&:last-child': {
            right: '-2rem',
            opacity: 1
        },
        [theme.breakpoints.down('xs')]: {
            display: 'none'
        }
    }
}));

export default function ScrollBox(props) {
    const classes = useStyles(props);
    const matchesSmUp = useMediaQuery('(min-width: 600px)');

    let scrollBox = null;
    let images = null;
    let initialPadding = 0;

    useEffect(() => {
        scrollBox = document.querySelectorAll('.js-scrollbox')[props.benefitId];
        images = scrollBox.querySelectorAll('.js-scrollbox-item');
        // initialPadding = images[0].offsetLeft;
        setTimeout(hideScrollBars, 1000);
    });

    const handleScroll = (scrollAmount) => {
        scrollBox.scrollBy({left: scrollAmount, behavior: 'smooth'});
    };

    const getCurrentImageIndex = () => {
        const scrollLeft = scrollBox.scrollLeft;

        return Math.round(scrollLeft / images[0].offsetWidth);
    };

    const scrollRight = () => {
        let index = getCurrentImageIndex();
        index += 1;
        index = index >= images.length ? images.length - 1 : index;
        let scrollAmount = images[index].offsetLeft - scrollBox.scrollLeft - initialPadding;

        handleScroll(scrollAmount);
    };

    const scrollLeft = () => {
        let index = getCurrentImageIndex();
        index -= 1;
        index = index < 0 ? 0 : index;
        let scrollAmount =  (scrollBox.scrollLeft - images[index].offsetLeft + initialPadding) * -1;

        handleScroll(scrollAmount);
    };

    const hideScrollBars = () => {
        if (scrollBox.offsetHeight > scrollBox.clientHeight) {
            scrollBox.style.paddingBottom = '15px';
        }
    };

    const updateArrows = () => {
        if (scrollBox.scrollLeft > 0) {
            setLeftArrowState(true);
        }

        if (scrollBox.scrollLeft === 0) {
            setLeftArrowState(false);
        }

        if (scrollBox.scrollLeft >= (scrollBox.scrollWidth - scrollBox.offsetWidth)) {
            setRightArrowState(false);
        }

        if (scrollBox.scrollLeft < (scrollBox.scrollWidth - scrollBox.offsetWidth)) {
            setRightArrowState(true);
        }
    };

    const [showLeftArrow, setLeftArrowState] = useState(false);
    const [showRightArrow, setRightArrowState] = useState(true);
    const [showCarousel, toggleCarousel] = useToggleState(false);
    const [currentSlide, setCurrentSlide] = useState(0);

    const openCarousel = (e) => {
        if (matchesSmUp) {
            setCurrentSlide(parseInt(e.target.dataset.index));
            toggleCarousel();
        }
    };

    return (
        <React.Fragment>
            <div className={classes.root}>
                <img
                    src={arrowLeft}
                    alt=""
                    className={classes.arrow}
                    onClick={scrollLeft}
                    style={{opacity: showLeftArrow ? 1 : 0, pointerEvents: showLeftArrow ? 'inherit' : 'none'}}
                />
                <div className={classes.scrollBoxWrapper}>
                    <div className={`${classes.scrollBox} js-scrollbox`} onScroll={updateArrows}>
                        {props.imgAltTexts.map((altText, i) => (
                            <div key={i} className={`${classes.imgWrapper} js-scrollbox-item`} onClick={openCarousel}>
                                <ZoomImage
                                    imgName={props.imgNames[i]}
                                    altText={altText}
                                    index={i}
                                />
                            </div>
                        ))}
                    </div>
                </div>
                <img
                    src={arrowRight}
                    alt=""
                    className={classes.arrow}
                    onClick={scrollRight}
                    style={{opacity: showRightArrow ? 1 : 0, pointerEvents: showRightArrow ? 'inherit' : 'none'}}
                />
            </div>
            {
                // showCarousel &&
                <ImageGallery
                    open={showCarousel}
                    handleClose={toggleCarousel}
                    imgAltTexts={props.imgAltTexts}
                    imgNames={props.imgNames}
                    currentSlide={currentSlide}
                />
            }
        </React.Fragment>
    );
}