import React from 'react';
import {Button} from '@material-ui/core';
import makeStyles from "@material-ui/core/styles/makeStyles";
import colors from './styles/colors';
import boxShadows from "./styles/boxShadows";

const useStyles = makeStyles(theme => ({
    root: {
        border: `2px solid ${colors.black}`,
        borderRadius: '10rem',
        color: colors.black,
        fontFamily: 'Open Sans, sans-serif',
        fontWeight: 800,
        letterSpacing: 0,
        textTransform: 'initial',
        boxShadow: 'none',
        backgroundColor: colors.white,
        padding: '0.3rem 2rem',
        fontSize: '1rem',

        '&:hover, &:focus': {
            backgroundColor: colors.white,
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: '0.875rem',
            padding: '0.3rem 1.5rem'
        }
    }
}));


function OutlinedButton(props) {
    const classes = useStyles(props);
    return (
        <Button variant="outlined" className={classes.root}>
            {props.children}
        </Button>
    );
}

export default OutlinedButton;