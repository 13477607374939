import React from 'react';
import Stage from './Stage';
import Benefits from './Benefits';
import Page from './Page';
import Trips from './Trips';
import About from './About';
import Instagram from "./Instagram";
import Newsletter from "./Newsletter";
import MainMessage from "./MainMessage";
import {Helmet} from "react-helmet";
import ReactPixel from "react-facebook-pixel";

function HomePage(props) {
    return (
        <React.Fragment>
            <Page>
                <Helmet>
                    <title>Travelmeister | The Social Way of Traveling</title>
                    <meta name="description"
                          content="Group trips that bring together like-minded co-travelers and local insiders, turning
                          every trip into a unique and authentic experience. Skip the tourist attractions and immerse
                          yourself in your location while sharing exciting moments with new friends!"
                    />
                </Helmet>
                <Stage />
                <MainMessage/>
                <Trips/>
                <Benefits />
                {/*<About/>*/}
                <Instagram/>
                <Newsletter/>
            </Page>
        </React.Fragment>
    );
}

export default HomePage;