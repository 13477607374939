import React from 'react';
import {withRouter} from 'react-router-dom';
import makeStyles from "@material-ui/core/styles/makeStyles";
import FlatButton from "./FlatButton";
import colors from './styles/colors';
import background from './img/bg_about.svg';
import illu from './img/illus/illu_about.svg';

const useStyles = makeStyles(theme => ({
    root: {
        margin: ' 0 auto',
        maxWidth: '80rem'
    },
    title: {
        maxWidth: '60%',
        transform: 'rotate(-3deg)',
        margin: '0 0 7rem 3rem',
        zIndex: 1,

        [theme.breakpoints.down('md')]: {
            marginLeft: 0
        },
        [theme.breakpoints.down('sm')]: {
            marginBottom: '3rem'
        },
        [theme.breakpoints.down('xs')]: {
            margin: '0 0 1rem 0',
            maxWidth: '100%'
        },
    },
    content: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-start',

        '@media (max-width: 1024px)': {
            flexDirection: 'column-reverse'
        }
    },
    textWrapper: {
        width: '35%',
        paddingTop: '2rem',

        '@media (max-width: 1024px)': {
            width: '65%',
            marginBottom: '5rem'
        },
        [theme.breakpoints.down('xs')]: {
            width: '100%',
            marginBottom: 0
        },
    },
    subtitle: {
    },
    descriptionWrapper: {
        marginBottom: '5rem',

        '@media (max-width: 1024px)': {
            marginBottom: '3rem'
        }
    },
    description: {
    },
    imgWrapper: {
        position: 'relative',
        width: '55%',

        '@media (max-width: 1024px)': {
            width: '75%',
            margin: '0 auto 10rem'
        },
        [theme.breakpoints.down('xs')]: {
            width: '80%',
            margin: '10rem auto 4rem'
        },
    },
    img: {
        position: 'relative',
        width: '100%',
        boxShadow: '0px 30px 23px -10px rgba(0,0,0,0.4)',
        zIndex: 1
    },
    background: {
        position: 'absolute',
        width: '105%',
        top: '-7.5rem',
        left: '-5rem',
        zIndex: 0,

        '@media (max-width: 1024px)': {
            width: '110%',
            top: '-8.5rem',
            left: '-5rem',
            transform: 'rotate(10deg)'
        },
        '@media (max-width: 768px)': {
            width: '113%',
            top: '-8rem',
            left: '-4rem',
            transform: 'rotate(10deg)'
        },
        [theme.breakpoints.down('xs')]: {
            top: '-4rem',
            left: '-2.2rem',
            transform: 'rotate(0)'
        },
    },
    illu: {
        position: 'absolute',
        width: '35%',
        top: '-18rem',
        right: '13%',

        '@media (min-width: 1024.05px) and (max-width: 1280px)': {
            top: '-16rem',
        },
        '@media (max-width: 1024px)': {
            width: '30%',
            top: '-13rem',
            right: 0,
        },
        '@media (max-width: 768px)': {
            width: '30%',
            top: '-15rem',
            right: '1rem',
        },
        [theme.breakpoints.down('xs')]: {
            top: '-75%',
            width: '43%'
        },
    }
}));

function About(props) {
    const classes = useStyles();

    const handleClick = (e) => {
        e.preventDefault();
        props.history.push(`/about`);
    };

    return (
        <section className={classes.root}>
            <header>
                <h2 className={classes.title}>
                    Your fun is
                    <span className="line-break">our command</span>
                </h2>
            </header>
            <div className={classes.content}>
                <div className={classes.textWrapper}>
                    <div className={classes.descriptionWrapper}>
                        <p className={classes.description}>
                            We are very passionate about travel, believing that it’s not only about visiting new
                            places but also immersing yourself into new cultures, making a real connection with
                            the people around you.
                        </p>
                        <p className={classes.description}>
                            That’s why we bring together travelers from all over the world to experience new
                            destinations through the eyes of our local Travelmeisters. Our goal is for everyone to
                            have the best time, sharing authentic fun with fellow explorers.
                        </p>
                    </div>
                    <FlatButton bgColor={colors.black} color={colors.white} href="/about" onClick={handleClick}>More</FlatButton>
                </div>
                <div className={classes.imgWrapper}>
                    <picture>
                        <source
                            src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
                            data-srcset={`${require('./img/about_home_small.webp')} 350w, ${require('./img/about_home_large.webp')} 500w`}
                            type="image/webp"
                        />
                        <source
                            src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
                            data-srcset={`${require('./img/about_home_small.jpg')} 350w, ${require('./img/about_home_large.jpg')} 500w`}
                            type="image/jpeg"
                        />
                        <img
                            src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
                            data-src={require('./img/about_home_large.jpg')}
                            alt="Group of friends taking a selfie at the beach"
                            className={`lazyload ${classes.img}`}
                            data-sizes="auto"
                        />
                    </picture>
                    <img src={background} alt="" className={classes.background}/>
                    <img src={illu} alt="" className={classes.illu}/>
                </div>
            </div>
        </section>
    );
}

export default withRouter(About);