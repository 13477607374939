import React from 'react';
import makeStyles from "@material-ui/core/styles/makeStyles";
import FlatButton from "./FlatButton";
import colors from "./styles/colors";

const useStyles = makeStyles(theme => ({
    root: {
        maxWidth: '80rem',
        margin: '5rem auto 12rem',

        [theme.breakpoints.down('xs')]: {
            margin: '2rem auto 5rem',
        }
    },
    title: {
        marginBottom: '6rem',

        [theme.breakpoints.down('xs')]: {
            marginBottom: '3rem',
            fontSize: '2rem'
        }
    },
    innerWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',

        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column-reverse'
        }
    },
    leftColumn: {
        width: '45%',

        [theme.breakpoints.down('sm')]: {
            width: '100%'
        }
    },
    rightColumn: {
        width: '45%',

        [theme.breakpoints.down('sm')]: {
            width: '100%',
            marginBottom: '2rem'
        },
        [theme.breakpoints.down('xs')]: {
            marginBottom: '1rem'
        },

    },
    hotelTitle: {
        fontSize: '1.4rem',
    },
    subtitle: {
        fontWeight: 800
    },
    description: {
        marginBottom: '3rem',

        [theme.breakpoints.down('xs')]: {
            marginBottom: '2rem',
        }
    },
    hotelLink: {
        color: colors.white,
        textDecoration: 'none',

        '&:visited, &:hover': {
            color: colors.white
        }
    }
    //    "hotel": {
//      "title": "Right in the middle, out of the ordinary",
//      "description": "The <strong>Pfefferbett Berlin</strong> is located in a 19th-century brewery complex, along with restaurants, a theater and artsy workshops with lots of creative minds behind them.\n\nIt lies in Prenzlauer Berg, one of Berlin’s hippest and trendiest districts and is only 2 stations from Alexanderplatz, the city center.\n\nRight in the very lively heart of Berlin but still like a little oasis…\n",
//      "link": "https://pfefferbett.de/",
//      "image": {
//        "url": "./img/berlin/hotel_1.jpg",
//        "altText": "Garden and terrace of Pfefferbett Hostel"
//      }
//    }
}));

function Hotel(props) {
    const classes = useStyles();
    const { title, description, image, link } = props;
    const descriptionHTML = {__html: description.replace(new RegExp('\n', 'g'), '<br/>')};

    return (
        <section id="hotel" className={classes.root}>
            <h2 className={classes.title}>Your accommodations</h2>
            <div className={classes.innerWrapper}>
                <div className={classes.leftColumn}>
                    <h3 className={classes.hotelTitle}>{title}</h3>
                    <p className={classes.description}
                       dangerouslySetInnerHTML={descriptionHTML}>
                    </p>
                    {/*<FlatButton bgColor={colors.black} color={colors.white}>*/}
                    {/*    <a href={link} target="_blank" className={classes.hotelLink}>More</a>*/}
                    {/*</FlatButton>*/}
                </div>
                <div className={classes.rightColumn}>
                    <img src={require(`${image.url}`)} alt={image.altText}/>
                </div>
            </div>
        </section>
    );
}

export default Hotel;