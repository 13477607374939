import makeStyles from "@material-ui/core/styles/makeStyles";
import React from "react";
import icon_diversity from "./img/how-it-works/icon_diversity.svg"
import icon_respect from "./img/how-it-works/icon_respect.svg"
import icon_openness from "./img/how-it-works/icon_openness.svg"

const useStyles = makeStyles(theme => ({
    root: {
        maxWidth: '80rem',
        margin: '0 auto'
    },
    header: {
        marginBottom: '5rem',

        [theme.breakpoints.up('lg')]: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
        },
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column',
            marginBottom: '3rem'
        }
    },
    title: {
        [theme.breakpoints.down('md')]: {
            marginBottom: '3rem'
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: '1.9rem',
        }
    },
    description: {

        [theme.breakpoints.up('lg')]: {
            width: '35%'
        }
    },
    column: {
        textAlign: 'center',
        width: '33%',

        [theme.breakpoints.down('xs')]: {
            width: '100%',
            marginBottom: '2rem'
        }
    },
    values: {
        display: 'flex',

        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column'
        }
    },
    value: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    valueIcon: {
        width: '50%',
        marginBottom: '1rem'
    },
    valueText: {
        width: '80%'
    }
}));

function TravelmeisterCode(props) {
    const classes = useStyles(props);

    return (
        <section className={classes.root}>
            <header className={classes.header}>
                <h2 className={classes.title}>The Travelmeister Code</h2>
                <p className={classes.description}>
                    We want this trip to be unforgettable for everyone. We might not make you take a test before you
                    book, but as a Travelmeister, you should share these three values.
                </p>
            </header>
            <ul className={classes.values}>
                <li className={classes.column}>
                    <article className={classes.value}>
                        <img src={icon_diversity} alt="World with a heart representing diversity" className={classes.valueIcon}/>
                        <h3>Diversity</h3>
                        <p className={classes.valueText}>
                            Everybody is welcome at Travelmeister. Make sure they feel this way. Always.
                        </p>
                    </article>
                </li>
                <li className={classes.column}>
                    <article className={classes.value}>
                        <img src={icon_respect} alt="World with a heart representing diversity" className={classes.valueIcon}/>
                        <h3>Respect</h3>
                        <p className={classes.valueText}>
                            You don’t have to like everyone the same. But we all respect each other.
                        </p>
                    </article>
                </li>
                <li className={classes.column}>
                    <article className={classes.value}>
                        <img src={icon_openness} alt="World with a heart representing diversity" className={classes.valueIcon}/>
                        <h3>Openness</h3>
                        <p className={classes.valueText}>
                            Travel is all about making new experiences.Try to stay open-minded.
                        </p>
                    </article>
                </li>
            </ul>
        </section>
    )

}

export default TravelmeisterCode;