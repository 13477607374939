import React from 'react';
import makeStyles from "@material-ui/core/styles/makeStyles";
import Breadcrumbs from "./Breadcrumbs";
import Share from "./Share";
import colors from './styles/colors';

const useStyles = makeStyles(theme => ({
    root: {
        marginBottom: '7rem',

        [theme.breakpoints.down('xs')]: {
            marginBottom: '5rem'
        }
    },
    headerImgWrapper: {
        height: 'calc(60vh - 5rem)',
        marginBottom: '1rem',

        [theme.breakpoints.down('xs')]: {
            height: 'calc(33vh - 5rem)',
            marginBottom: '0.5rem'
        }
    },
    headerImg: {
        height: '100%',
        objectFit: 'cover'
    },
    breadcrumbsShareWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: '5rem',

        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'flex-start'
        }
    },
    textWrapper: {
        maxWidth: '80rem',
        margin: '0 auto'
    },
    title: {
        fontFamily: 'Open Sans, sans-serif',
        fontWeight: 800,
        marginBottom: '2rem',
        lineHeight: 1.4,

        [theme.breakpoints.down('xs')]: {
            fontSize: '1.5rem'
        }
    },
    introText: {
        width: '60%',

        [theme.breakpoints.down('sm')]: {
            width: '90%'
        },
        [theme.breakpoints.down('xs')]: {
            width: '100%'
        },
        '& a': {
            textDecoration: 'underline',

            '&:hover': {
                color: colors.green
            }
        }
    }
}));

function BlogArticleHeader(props) {
    const classes = useStyles();
    const {slug, img, title, cityId, cityName, introText} = props;

    return (
        <header className={classes.root}>
            <figure className={classes.headerImgWrapper}>
                <img src={img} alt="" className={classes.headerImg}/>
            </figure>
            <div className={classes.breadcrumbsShareWrapper}>
                <Breadcrumbs cityId={cityId} cityName={cityName} articleTitle={title}/>
                <Share image={img}/>
            </div>
            <div className={classes.textWrapper}>
                <h1 className={classes.title}>{title}</h1>
                <p dangerouslySetInnerHTML={{__html: introText}} className={classes.introText}></p>
            </div>
        </header>
    );
}

export default BlogArticleHeader;