import React from 'react';
import makeStyles from "@material-ui/core/styles/makeStyles";
import colors from "./styles/colors";



const useStyles = makeStyles({

});

function PaginationDot(props) {
    const classes = useStyles(props);

    return (
        <span
            data-index={props.index}
            className={classes.dot}
            onClick={props.handleDotClick}
        ></span>
    );
}

export default PaginationDot;