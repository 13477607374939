import React from 'react';
import makeStyles from "@material-ui/core/styles/makeStyles";
import {Link, withRouter} from 'react-router-dom';
import colors from "./styles/colors";

const useStyles = makeStyles({
    root: {
        display: props => (props.inline ? 'flex' : 'block'),
        height: '100%',
        cursor: 'pointer'
    },
    imgOuterWrapper: {
        position: 'relative',
        width: props => (props.inline ? '50%' : '100%'),
        marginBottom: props => (props.inline ? 0 : '1rem'),
        marginRight: props => (props.inline ? '1rem' : 0),
        paddingBottom: props => (props.inline ? 0 : '66.666%'),
        overflow: 'hidden',
        flexShrink: 0
    },
    imgWrapper: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        transition: 'transform 300ms ease',

        '&:hover': {
            transform: 'scale(1.05)'
        }
    },
    title: {
        fontSize: '1rem',
        marginBottom: '0.2rem'
    },
    teaserText: {
        lineHeight: 1.4
    },
    articleLink: {
        color: colors.black,
        textDecoration: 'none'
    }
});

function BlogArticleTeaser(props) {
    const classes = useStyles(props);
    const {citySlug, slug, img, title} = props;
    const url = `/blog/${citySlug}/${slug}`;

    const handleClick = () => {
        console.log('Hallo');
        props.history.push(url);
    };

    return (
        <article className={classes.root} onClick={handleClick}>
            <figure className={classes.imgOuterWrapper}>
                <span className="visually-hidden">{img.alt}</span>
                <div className={classes.imgWrapper} style={{backgroundImage: `url(${props.img.sizes.medium_large})`}}></div>
            </figure>
            <div className={classes.textWrapper}>
                <h4 className={classes.title}>
                    <Link to={url} className={classes.articleLink} onClick={(e) => {e.stopPropagation()}}>
                        {title}
                    </Link>
                </h4>
                {/*{*/}
                {/*    !props.withoutTeaserText && teaserText && <p className={classes.teaserText}>{teaserText}</p>*/}
                {/*}*/}
            </div>
        </article>
    );
}

export default withRouter(BlogArticleTeaser);