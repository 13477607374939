import React from 'react';
import {makeStyles} from "@material-ui/core";
import bgDay1 from './img/trip/bg_day_1.svg';
import bgDay2 from './img/trip/bg_day_2.svg';
import bgDay3 from './img/trip/bg_day_3.svg';

const bgImages = {
    'day_1': bgDay1,
    'day_2': bgDay2,
    'day_5': bgDay3,
};

const useStyles = makeStyles(theme => ({
    imgWrapper: {
        position: 'relative',
        width: '46%',

        [theme.breakpoints.down('sm')]:{
            width: '70%',
            marginBottom: '3rem'
        },
        [theme.breakpoints.down('xs')]:{
            width: '100%',
            marginBottom: '1rem'
        }
    },
    dayImg: {
        position: 'relative',
        zIndex: 1,

        [theme.breakpoints.down('sm')]: {
            width: '100%'
        }
    },
    dayBg: {
        position: 'absolute',
        width: 'auto',
        zIndex: 0,

        '&.day-1': {
            height: '80%',
            left: '-20%',
            bottom: '-35%'
        },
        '&.day-2': {
            height: '200%',
            top: '-10%',
            right: '-88%'
        },
        '&.day-5': {
            height: '143%',
            top: '-8%',
            left: '-31%'
        }
    },
    dayContent: {
        position: 'relative',
        width: '47%',

        [theme.breakpoints.down('sm')]:{
            width: '90%'
        },
        [theme.breakpoints.down('xs')]:{
            width: '100%'
        },
    },
    dayLabel: {
        fontSize: '1rem',
    },
    dailyInclusions: {
        '& span': {
            fontWeight: 800
        }
    },
}));

function Day(props) {
    const classes = useStyles(props);
    const {index, imgName, imgAltText, name, inclusions, cityId} = props;
    const description = {__html: props.description.replace(new RegExp('\n', 'g'), '<br/>')};
    const basePath = `./img/${cityId}/itinerary`;

    return (
        <React.Fragment>
            <div className={classes.imgWrapper}>
                <picture>
                    <source
                        src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
                        data-srcset={`${require(`${basePath}/1x/${imgName}.webp`)} 350w, ${require(`${basePath}/2x/${imgName}.webp`)} 500w`}
                        type="image/webp"
                    />
                    <source
                        src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
                        data-srcset={`${require(`${basePath}/1x/${imgName}.jpg`)} 350w, ${require(`${basePath}/2x/${imgName}.jpg`)} 500w`}
                        type="image/jpeg"
                    />
                    <img
                        src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
                        data-src={require(`${basePath}/2x/${imgName}.jpg`)}
                        alt={imgAltText}
                        className={`lazyload ${classes.dayImg}`}
                        data-sizes="auto"
                    />
                </picture>
                {
                    typeof bgImages[`day_${index+1}`] !== 'undefined'
                        ?
                        <img src={bgImages[`day_${index+1}`]} alt="" className={`${classes.dayBg} day-${index+1}`}/>
                        :
                        ''
                }
            </div>
            <div className={classes.dayContent}>
                <h4 className={classes.dayLabel}>Day {index+1}</h4>
                <h3>{name}</h3>
                <p dangerouslySetInnerHTML={description}></p>
                {
                    inclusions &&
                    <p className={classes.dailyInclusions}>
                        <span>Includes:</span> {inclusions}
                    </p>
                }
            </div>
        </React.Fragment>
    );
}

export default Day;