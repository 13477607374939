import React from 'react';
import {withRouter, Link} from 'react-router-dom';
import makeStyles from "@material-ui/core/styles/makeStyles";
import img from './img/berlin/cover.jpg';
import colors from "./styles/colors";

const useStyles = makeStyles(theme => ({
    root: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'center',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '1rem',
        backgroundSize: 'cover',
        backgroundPosition: 'center center',
        cursor: 'pointer',
        transition: 'transform 300ms ease',

        '&:hover': {
            transform: 'scale(1.05)'
        },
        '&:after': {
            content: '""',
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            background: 'rgba(0,0,0,0.3)',
            zIndex: 1
        }
    },
    textWrapper: {
        position: 'relative',
        zIndex: 2
    },
    title: {
        lineHeight: 1.3,

        fontSize: props => {
            if (props.index === 0) {
                return '1.5rem';
            } else if (props.index === 2 || props.index === 1) {
                return '1.3rem'
            }
            else if (props.index >= 3) {
                return '1.2rem'
            }
        },

        [theme.breakpoints.down('sm')]: {
            fontSize: props => {
                if (props.index === 0) {
                    return '1.3rem';
                } else if (props.index === 2 || props.index === 1) {
                    return '1.1rem'
                }
                else if (props.index === 3) {
                    return '1.3rem'
                } else if (props.index > 3) {
                    return '1.1rem'
                }
            },
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: '1.2rem'
        }
    },
    teaserText: {
        margin: 0
    },
    articleLink: {
        color: colors.white,
        textDecoration: 'none'
    }
}));

function BlogFeaturedArticleTeaser(props) {
    const classes = useStyles(props);
    const {cityId, title, slug, img} = props;
    const url = `/blog/${cityId}/${slug}`;

    const handleClick = () => {
        props.history.push(url);
    };

    return (
        <article className={classes.root} onClick={handleClick} style={{backgroundImage: `url(${img.sizes['1536x1536']})`}}>
            <div className={classes.textWrapper}>
                <h4 className={classes.title}>
                    <Link to={url} className={classes.articleLink} onClick={(e) => {e.stopPropagation()}}>
                        {title}
                    </Link>
                </h4>
                {/*{*/}
                {/*    typeof teaserText !== 'undefined' && teaserText &&*/}
                {/*    <p className={classes.teaserText}>{teaserText}</p>*/}
                {/*}*/}
            </div>
        </article>
    );
}

export default withRouter(BlogFeaturedArticleTeaser);