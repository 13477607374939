import Page from "./Page";
import {Helmet} from "react-helmet";
import NewsletterSmall from "./NewsletterSmall";
import bgNewsletter from "./img/how-it-works/bg_newsletter_how_it_works.svg";
// import bgNewsletterMobile from "./img/how-it-worls/bg_newsletter_how_it_works_mobile.svg";
import React from "react";
import SmallGroups from "./SmallGroups";
import GoodThings from "./GoodThings";
import SmallSteps from "./SmallSteps";
import TravelmeisterCode from "./TravelmeisterCode";

function HowItWorksPage(props) {
    return (
        <Page>
            <Helmet>
                <title>How it works | Travelmeister</title>
                <meta name="description"
                      content="With Travelmeister, you always travel in groups of minimum 8 and maximum 12 people.
                      You decide if you wanna travel by yourself or with some of your friends, and we will fill the
                      open spots for you."
                />
            </Helmet>

            <SmallGroups/>
            <GoodThings/>
            <SmallSteps/>
            <TravelmeisterCode/>

            <NewsletterSmall background={bgNewsletter} mobileBg={bgNewsletter}/>
        </Page>
    );
}

export default HowItWorksPage;