import React from 'react';
import {makeStyles} from "@material-ui/core/styles";
import FlatButton from "./FlatButton";
import colors from "./styles/colors";
import {Link, withRouter} from 'react-router-dom';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        maxWidth: '80rem',
        margin: '0 auto',
        padding: '5rem 0',

        [theme.breakpoints.down('xs')]: {
            flexWrap: 'wrap',
            padding: '4rem 0'
        }
    },
    title: {
        width: '40%',

        [theme.breakpoints.down('xs')]: {
            width: '100%',
            marginBottom: '1.5rem'
        }
    },
    wrapper: {
        width: '50%',

        [theme.breakpoints.down('md')]: {
            width: '60%'
        },
        [theme.breakpoints.down('xs')]: {
            width: '100%'
        }
    },
    description: {
        marginBottom: '2rem'
    }
}));

function TripHowItWorks(props) {
    const classes = useStyles();

    const handleClick = (e) => {
        e.preventDefault();
        // props.history.push(`/how-it-works`);
        const win = window.open("/how-it-works", "_blank");
        win.focus();
    };

    return (
        <section className={classes.root} id="overview">
            <h3 className={classes.title}>How it works</h3>
            <div className={classes.wrapper}>
                <p className={classes.description}>
                    You always travel in groups of minimum 8 to maximum 12 people. You can book this trip for yourself, or
                    invite a couple of your friends. The remaining open spots will be filled by us.
                </p>
                <FlatButton bgColor={colors.black} color={colors.white} href="/how-it-works" onClick={handleClick}>
                    Learn more
                </FlatButton>
            </div>
        </section>
    );


}

export default withRouter(TripHowItWorks);