import React, {useEffect, useRef} from 'react';
import makeStyles from "@material-ui/core/styles/makeStyles";
import colors from "./styles/colors";
import FlatButton from "./FlatButton";
import _ from "lodash";
import useMediaQuery from "@material-ui/core/useMediaQuery";


const useStyles = makeStyles(theme => ({
    root: {
        position: 'fixed',
        top: '4rem',
        left: 0,
        width: '100%',
        height: '4rem',
        zIndex: 1000,

        display: 'flex',
        alignItems: 'center',
        padding: '0 5rem',
        borderTop: '1px solid #eee',

        background: colors.black,
        transform: props => props.show ? 'translateY(0)' : 'translateY(-110%)',
        transition: 'transform 300ms ease-out',

        [theme.breakpoints.down('xs')]: {
            top: '3rem',
            height: '3rem',
            padding: '0 1rem'
        }
    },
    innerWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        maxWidth: '80rem',
        margin: '0 auto',
        color: colors.white,

        [theme.breakpoints.down('xs')]: {
            fontSize: '0.875rem'
        }
    },
    navList: {
        display: 'flex',
    },
    navItem: {
        marginRight: '1.5rem',
        cursor: 'pointer',

        '&.active': {
            color: colors.orange,
        }
    },
    detailsWrapper: {
        display: 'flex',
        alignItems: 'center',

        [theme.breakpoints.down('xs')]: {
            display: 'none'
        }
    },
    priceText: {
        margin: '0 1rem 0 0',
        fontWeight: 800
    },
    priceLabel: {
        marginRight: '0.2rem'
    },
    price: {
        fontSize: '1.2rem',
    }
}));

function StickyDetailsAndNav(props) {
    const {price, bookingLink} = props;
    const classes = useStyles(props);
    const navRef = useRef(null);
    const matchesPhone = useMediaQuery('(max-width: 450px)');

    const scrollTo = (id, offset) => {
        const position = document.getElementById(id).offsetTop - offset;
        window.scrollTo({ top: position, behavior: 'smooth' });
    };

    const handleScroll = () => {
        if (window.scrollY > (document.getElementById('overview').offsetTop - 200) &&
        window.scrollY < (document.getElementById('itinerary').offsetTop - 200)) {
            setNavElementActive(0);
        } else if (window.scrollY > (document.getElementById('itinerary').offsetTop - window.innerHeight - 200)
            && window.scrollY < (document.getElementById('hotel').offsetTop - 200)) {
            setNavElementActive(1);
        } else if (window.scrollY > (document.getElementById('hotel').offsetTop - window.innerHeight - 200) &&
            window.scrollY < (document.getElementById('info').offsetTop - 300)) {
            setNavElementActive(2);
        } else if (window.scrollY > (document.getElementById('info').offsetTop - window.innerHeight - 300)) {
            setNavElementActive(3);
        }
        else if (window.scrollY > ( - window.innerHeight)) {
            setNavElementActive(4);
        }
    };

    const setNavElementActive = (position) => {
        const navElements = navRef.current.childNodes;

        for (let i = 0; i < navElements.length; i++) {
            navElements[i].classList.remove('active');
        }

        navRef.current.childNodes[position].classList.add('active');
    };

    const throttleScroll = _.throttle(handleScroll, 100);

    useEffect(() => {
        document.addEventListener('scroll', throttleScroll, false);
        return () => {
            document.removeEventListener('scroll', throttleScroll, false);
        }
    }, []);

    return (
        <div className={classes.root}>
            <div className={classes.innerWrapper}>
                <nav>
                    <ul className={classes.navList} ref={navRef}>
                        <li className={classes.navItem} onClick={() => {scrollTo('overview', matchesPhone ? 40 : 120)}}>Overview</li>
                        {/*<li className={classes.navItem}>Map</li>*/}
                        <li className={classes.navItem} onClick={() => {scrollTo('itinerary', 50)}}>Itinerary</li>
                        <li className={classes.navItem} onClick={() => {scrollTo('hotel', matchesPhone ? 130 : 190)}}>Hotel</li>
                        <li className={classes.navItem} onClick={() => {scrollTo('info', matchesPhone ? 130 : 170)}}>Info</li>
                    </ul>
                </nav>
                <div className={classes.detailsWrapper}>
                    <p className={classes.priceText}><span className={classes.priceLabel}>from </span><span className={classes.price}>{price}€</span></p>
                    <FlatButton smallButton color={colors.black} type={'small'} bgColor={colors.orange} href={bookingLink}>See dates</FlatButton>
                </div>
            </div>
        </div>
    );
}

export default StickyDetailsAndNav;