import React, {useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';

import CustomCarousel from "./CustomCarousel";
import {useMediaQuery} from "@material-ui/core";

const useStyles = makeStyles({
    modal: {
        overflow: 'visible',

        '& .paper': {
            overflow: 'visible',
        }
    }
});

export default function ImageGallery(props) {
    const classes = useStyles();
    const { imgNames, imgAltTexts, currentSlide } = props;
    const matchesMdUp = useMediaQuery('(min-width: 1200px)');
    const dialogWidth = matchesMdUp ? 'md' : 'sm';


    const slides = [];
    const basePath = `./img/benefits/large/`;

    imgAltTexts.map((altText, index) => {
        slides.push(
            <figure>
                <picture>
                    <source
                        srcSet={`${require(`${basePath}webp/${imgNames[index]}.webp`)}`}
                        type="image/webp"
                    />
                    <source
                        srcSet={`${require(`${basePath}jpeg/${imgNames[index]}.jpg`)}`}
                        type="image/jpeg"
                    />
                    <img
                        src={`${require(`${basePath}jpeg/${imgNames[index]}.jpg`)}`}
                        alt={altText}
                        className={classes.img}
                    />
                </picture>
            </figure>
        );
    });

    return (
        <Dialog classes={{paper: 'paper'}} className={classes.modal} open={props.open} onClose={props.handleClose} maxWidth={dialogWidth} PaperProps={{square: true}}>
            <CustomCarousel
                initialSlide={currentSlide}
                infinite={true}
                slides={slides}
                slidesToShow={1}
                slidesToScroll={1}
            />
        </Dialog>
    );
}