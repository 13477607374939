import React from 'react';
import Page from "./Page";
import TripHeader from "./TripHeader";
import tripData from './data/tripData';
import TripSummary from './TripSummary';
import TripInclusions from "./TripInclusions";
import Itinerary from "./Itinerary";
import OptionalExperiences from "./OptionalExperiences";
import Hotel from "./Hotel";
import {Helmet} from "react-helmet";
import TripHowItWorks from "./TripHowItWorks";
import TripInfo from "./TripInfo";
import ReactPixel from "react-facebook-pixel";
import TripImageGrid from "./TripImageGrid";
import TripHeaderComingSoon from "./TripHeaderComingSoon";
import NewsletterSmall from "./NewsletterSmall";
import newsletterBg from "./img/trips/bg_newsletter_trips.svg";
import newsletterBgMobile from "./img/trips/bg_newsletter_trips_mobile.svg";

function TripDetailPageComingSoon(props) {
    const id =  props.match.params.id;
    const { name, slug, city, country, duration, groupSize, languages, minPrice, description, inclusions, itinerary,
        optionalExperiences, hotel, teaserText, airport, bookingLink, deposit, priceDetailsText, activitiesPrice,
        guidePrice } = tripData[id];

    ReactPixel.track('ViewContent', {destination: name});

    return (
        <Page>
            <Helmet>
                <title>{name} | Travelmeister</title>
                <meta name="description" content={teaserText} />
            </Helmet>
            <TripHeaderComingSoon
                id={id}
                slug={slug}
                name={name}
                city={city}
                country={country}
                duration={duration}
                groupSize={groupSize}
                languages={languages}
                minPrice={minPrice}
                bookingLink={bookingLink}
                deposit={deposit}
                priceDetailsText={priceDetailsText}
                activitiesPrice={activitiesPrice}
                guidePrice={guidePrice}
            />
            {/*<TripImageGrid cityId={id}/>*/}
            <p style={{marginBottom: '2rem'}}>
                If you are interested in this trip subscribe to our newsletter for free to get more info about this
                and other new adventures coming soon!
            </p>
            <NewsletterSmall background={newsletterBg} mobileBg={newsletterBgMobile} location={name}/>
        </Page>
    );
}

export default TripDetailPageComingSoon;