import React from 'react';
import makeStyles from "@material-ui/core/styles/makeStyles";
import FlatButton from "./FlatButton";
import colors from './styles/colors';

import illustration from './img/illus/illu_instagram.svg';
import textIllu from './img/gif_share.gif';
import img1DesktopJpg from './img/instagram/desktop/1.jpg';
import img1DesptopWebp from './img/instagram/desktop/1.webp';
import img1MobileJpg1x from './img/instagram/mobile/1x/1.jpg';
import img1MobileWebp1x from './img/instagram/mobile/1x/1.webp';
import img1MobileJpg2x from './img/instagram/mobile/2x/1.jpg';
import img1MobileWebp2x from './img/instagram/mobile/2x/1.webp';
import img2DesktopJpg from './img/instagram/desktop/2.jpg';
import img2DesptopWebp from './img/instagram/desktop/2.webp';
import img2MobileJpg1x from './img/instagram/mobile/1x/2.jpg';
import img2MobileWebp1x from './img/instagram/mobile/1x/2.webp';
import img2MobileJpg2x from './img/instagram/mobile/2x/2.jpg';
import img2MobileWebp2x from './img/instagram/mobile/2x/2.webp';
import img3DesktopJpg from './img/instagram/desktop/3.jpg';
import img3DesptopWebp from './img/instagram/desktop/3.webp';
import img3MobileJpg1x from './img/instagram/mobile/1x/3.jpg';
import img3MobileWebp1x from './img/instagram/mobile/1x/3.webp';
import img3MobileJpg2x from './img/instagram/mobile/2x/3.jpg';
import img3MobileWebp2x from './img/instagram/mobile/2x/3.webp';
import img4DesktopJpg from './img/instagram/desktop/4.jpg';
import img4DesptopWebp from './img/instagram/desktop/4.webp';
import img4MobileJpg1x from './img/instagram/mobile/1x/4.jpg';
import img4MobileWebp1x from './img/instagram/mobile/1x/4.webp';
import img4MobileJpg2x from './img/instagram/mobile/2x/4.jpg';
import img4MobileWebp2x from './img/instagram/mobile/2x/4.webp';
import img5DesktopJpg from './img/instagram/desktop/5.jpg';
import img5DesptopWebp from './img/instagram/desktop/5.webp';
import img5MobileJpg1x from './img/instagram/mobile/1x/5.jpg';
import img5MobileWebp1x from './img/instagram/mobile/1x/5.webp';
import img5MobileJpg2x from './img/instagram/mobile/2x/5.jpg';
import img5MobileWebp2x from './img/instagram/mobile/2x/5.webp';
import img6DesktopJpg from './img/instagram/desktop/6.jpg';
import img6DesptopWebp from './img/instagram/desktop/6.webp';
import img6MobileJpg1x from './img/instagram/mobile/1x/6.jpg';
import img6MobileWebp1x from './img/instagram/mobile/1x/6.webp';
import img6MobileJpg2x from './img/instagram/mobile/2x/6.jpg';
import img6MobileWebp2x from './img/instagram/mobile/2x/6.webp';
import img7DesktopJpg from './img/instagram/desktop/7.jpg';
import img7DesptopWebp from './img/instagram/desktop/7.webp';
import img7MobileJpg1x from './img/instagram/mobile/1x/7.jpg';
import img7MobileWebp1x from './img/instagram/mobile/1x/7.webp';
import img7MobileJpg2x from './img/instagram/mobile/2x/7.jpg';
import img7MobileWebp2x from './img/instagram/mobile/2x/7.webp';
import ImageGrid from "./ImageGrid";


const useStyles = makeStyles(theme => ({
    root: {
        margin: '40rem auto 10rem',

        '@media (min-width: 1024.05px) and (max-width: 1280px)': {
            marginTop: '32rem'
        },
        '@media (min-width: 960.05px) and (max-width: 1024px)': {
            marginTop: '25rem'
        },
        [theme.breakpoints.down('sm')]: {
            marginTop: '20rem'
        },
        [theme.breakpoints.down('xs')]: {
            marginTop: '15rem'
        }
    },
    subtitle: {
        position: 'relative',
        textAlign: 'center',
        marginBottom: '3rem',
        zIndex: 1,

        '@media (max-width: 1024px)': {
            marginBottom: '2rem',
        },
        [theme.breakpoints.down('sm')]: {
            marginBottom: '1rem',
            fontSize: '1.5rem'
        },
        [theme.breakpoints.down('xs')]: {
            marginBottom: '0.5rem',
            marginRight: '1rem',
            fontSize: '1rem',
            textAlign: 'right'
        }
    },
    content: {
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
        margin: '0 auto',
        maxWidth: '80rem',
    },
    illu: {
        position: 'absolute',
        top: '-70%',
        left: '2%',
        width: '50%',

        [theme.breakpoints.down('sm')]: {
            top: '-60%',
            left: 0,
            width: '45%',
        },
        [theme.breakpoints.down('xs')]: {
            top: '-35%',
            width: '75%',
        }
    },
    textIllu: {
        position: 'absolute',
        top: '-12rem',
        right: '10%',
        width: '15rem',

        '@media (max-width: 1024px)': {
            right: 0,
            width: '13rem',
            top: '-11rem'
        },
        [theme.breakpoints.down('xs')]: {
            top: '-7rem',
            width: '7rem'
        }
    }
}));

function Instagram(props) {
    const classes = useStyles();

    const imageList = {
        img1DesktopJpg, img1DesptopWebp, img1MobileJpg1x, img1MobileWebp1x, img1MobileJpg2x,
        img1MobileWebp2x, img2DesktopJpg, img2DesptopWebp, img2MobileJpg1x, img2MobileWebp1x, img2MobileJpg2x,
        img2MobileWebp2x, img3DesktopJpg, img3DesptopWebp, img3MobileJpg1x, img3MobileWebp1x, img3MobileJpg2x,
        img3MobileWebp2x, img4DesktopJpg, img4DesptopWebp, img4MobileJpg1x, img4MobileWebp1x, img4MobileJpg2x,
        img4MobileWebp2x, img5DesktopJpg,  img5DesptopWebp, img5MobileJpg1x, img5MobileWebp1x, img5MobileJpg2x,
        img5MobileWebp2x, img6DesktopJpg, img6DesptopWebp, img6MobileJpg1x, img6MobileWebp1x, img6MobileJpg2x,
        img6MobileWebp2x, img7DesktopJpg, img7DesptopWebp, img7MobileJpg1x, img7MobileWebp1x, img7MobileJpg2x,
        img7MobileWebp2x
    };

    return (
        <section className={classes.root}>
            <h3 className={classes.subtitle}>Follow #travelmeister</h3>
            <div className={classes.content}>
                <img src={illustration} alt="3 friends taking a selfie with selfie stick" className={classes.illu}/>
                <img src={textIllu} alt="Show us your group selfie" className={classes.textIllu} />
                <ImageGrid imageList={imageList}/>
                <a href="https://www.instagram.com/travelmeister_eu/" target="_blank">
                    <FlatButton bgColor={colors.black} color={colors.white}>See more</FlatButton>
                </a>
            </div>
        </section>
    );
}

export default Instagram;