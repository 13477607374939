import React from 'react';
import Page from "./Page";
import BlogHeader from "./BlogHeader";
import BlogCities from "./BlogCities";
import BlogFeaturedArticles from "./BlogFeaturedArticles";
import BlogVideos from "./BlogVideos";
import BlogLatestArticles from "./BlogLatestArticles";
import newsletterBg from './img/blog/bg_blog_newsletter.svg';
import newsletterBgMobile from './img/blog/bg_blog_newsletter_mobile.svg';
import NewsletterSmall from "./NewsletterSmall";
import {Helmet} from 'react-helmet';

function BlogPage(props) {
    const baseUrl = 'https://blog.travelmeister.eu/wp-json/wp/v2';

    return (
        <Page>
            <Helmet>
                <title>Blog | Travelmeister | Insider Recommendations from Locals</title>
                <meta name="description" content="The best tips and recommendations from local insiders for restaurants, bars, clubs, museums, art galleries, and sights." />
            </Helmet>
            <BlogHeader/>
            <BlogCities baseUrl={baseUrl}/>
            <BlogFeaturedArticles baseUrl={baseUrl}/>
            <BlogVideos/>
            <BlogLatestArticles
                full={true}
                baseUrl={baseUrl}
            />
            <NewsletterSmall background={newsletterBg} mobileBg={newsletterBgMobile}/>
        </Page>
    );
}

export default BlogPage;