import React from 'react';
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles(theme => ({
    root: {
        position: 'relative',
        zIndex: 1
    },
    imgWrapper: {
        height: '60vh',

        '@media (max-width: 1024px) and (orientation: portrait)': {
            height: '40vh'
        },
        [theme.breakpoints.down('xs')]: {
            height: '60vw',
        },

        '& img': {
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            objectPosition: props => (typeof props.objectPos) !== 'undefined' ? props.objectPos : '50% 50%'
        }
    }
}));

function PageHeader(props) {
    const classes = useStyles(props);

    return (
        <div className={classes.root}>
            <figure className={classes.imgWrapper}>
                <picture>
                    <source
                        src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
                        data-srcset={`${props.headerImg.sources.webp.small} 415w, ${props.headerImg.sources.webp.medium} 830w, ${props.headerImg.sources.webp.large} 1200w`}
                        type="image/webp"
                    />
                    <source
                        src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
                        data-srcset={`${props.headerImg.sources.jpeg.small} 415w, ${props.headerImg.sources.jpeg.medium} 830w, ${props.headerImg.sources.jpeg.large} 1200w`}
                        type="image/jpeg"
                    />
                    <img
                        src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
                        data-src={props.headerImg.sources.jpeg.large}
                        alt={props.headerImg.altText}
                        className="lazyload"
                        data-sizes="auto"
                    />
                </picture>
            </figure>
        </div>
    );
}

export default PageHeader;