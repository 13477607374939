import React from 'react';
import {makeStyles} from "@material-ui/core/styles";
import FAQ from "./FAQ";

const useStyles = makeStyles(theme => ({
    root: {
        paddingBottom: '5rem',
        maxWidth: '80rem',
        margin: '0 auto'
    },
    title: {
        marginBottom: '5rem',
        zIndex: 1,

        [theme.breakpoints.down('xs')]: {
            marginBottom: '3rem'
        }
    },
    contentWrapper: {
        [theme.breakpoints.up('lg')]: {
            display: 'flex',
            justifyContent: 'space-between',

        }
    },
    infoWrapper: {
        marginBottom: '3rem',

        [theme.breakpoints.up('lg')]: {
            width: '45%'
        }
    }
}));

function TripInfo(props) {
    const classes = useStyles();
    const {city, country, airport, hotel} = props;

    return (
        <section className={classes.root} id="info">

            <h2 className={classes.title}>ImPortAnt Stuff</h2>
            <FAQ
                city={city}
                country={country}
                airport={airport}
                hotel={hotel}
            />
        </section>
    );


}

export default TripInfo;