import React from 'react';
import {makeStyles} from "@material-ui/core/styles";
import icon_accommodation from './img/trip/icon_accommodation.svg';
import icon_food from './img/trip/icon_food.svg';
import icon_drinks from './img/trip/icon_drinks.svg';
import icon_experiences from './img/trip/icon_experiences.svg';
import icon_staff from './img/trip/icon_staff.svg';
import icon_transport from './img/trip/icon_transport.svg';
import bg from './img/trip/bg_inclusions.svg';
import mobileBg from './img/trip/bg_inclusions_mobile.svg';

const illus = {
    accommodation: icon_accommodation,
    food: icon_food,
    drinks: icon_drinks,
    experiences: icon_experiences,
    staff: icon_staff,
    transport: icon_transport
};

const useStyles = makeStyles(theme => ({
    outerWrapper: {
        margin: '0 -5vw',
        padding: '0 5vw',
        background: `url(${bg}) no-repeat`,
        backgroundSize: '100% 100%',
        backgroundPosition: 'center',

        [theme.breakpoints.down('md')]: {
            margin: '0 -6vw',
            padding: '0 6vw',
        },
        [theme.breakpoints.down('sm')]: {
            background: `url(${mobileBg}) no-repeat`
        },
        [theme.breakpoints.down('xs')]: {
            padding: '3rem 6vw 2rem',
        }
    },
    innerWrapper: {
        maxWidth: '80rem',
        margin: '0 auto',
    },
    title: {
        maxWidth: '80rem',
        margin: '0 auto 2rem',

        [theme.breakpoints.down('sm')]: {
            marginBottom: '0.5rem'
        }
    },
    titleAdditionalInfo: {
        fontSize: '1rem',
        fontWeight: 'initial',

        [theme.breakpoints.down('xs')]: {
            display: 'block'
        },
    },
    inclusionsList: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: '3rem 0 2rem',

        [theme.breakpoints.down('sm')]: {
            padding: '4rem 0 2rem',
            justifyContent: 'center',
            flexWrap: 'wrap'
        },
        [theme.breakpoints.down('xs')]: {
            padding: 0,
        },
    },
    inclusionColumn: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '20%',

        [theme.breakpoints.down('sm')]: {
            width: '33%',
            marginBottom: '2rem',
        },
        [theme.breakpoints.down('xs')]: {
            width: '100%',
            flexDirection: 'row'
        }
    },
    illu: {
        width: '100%',
        height: '7rem',
        marginBottom: '1rem',

        [theme.breakpoints.down('xs')]: {
            width: '100%',
            height: 'auto',
            marginBottom: 0
        }
    },
    inclusionTitle: {
        fontSize: '1rem',
        fontWeight: 800,
        marginBottom: '0.2rem',

        [theme.breakpoints.up('sm')]: {
            textAlign: 'center'
        },
    },
    inclusions: {
        display: 'flex',
        flexWrap: 'wrap',
        whiteSpace: 'pre-wrap',
        justifyContent: 'center',
        textAlign: 'center',
        fontSize: '0.875rem',
        margin: 0,
        padding: '0 1rem',

        '& span': {
            display: 'block',
        },
        [theme.breakpoints.down('xs')]: {
            justifyContent: 'flex-start',
            padding: 0,
            textAlign: 'left'
        }
    },
    iconWrapper: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',

        [theme.breakpoints.down('xs')]: {
            width: '20%',
            height: '120%',
            flexShrink: 0,
            marginRight: '2rem'
        }
    }
}));

function TripInclusions(props) {
    const classes = useStyles();
    const { inclusions } = props;

    return (
        <section id="whats-included" className={classes.root}>
            <h3 className={classes.title}>What's included <span className={classes.titleAdditionalInfo}>(Flights not included)</span></h3>
            <div className={classes.outerWrapper}>
                <div className={classes.innerWrapper}>
                    <ul className={classes.inclusionsList}>
                        {
                            inclusions.map((incl) => (
                                <li className={classes.inclusionColumn}>
                                    <div className={classes.iconWrapper}>
                                        <img src={illus[incl.illu]} alt={incl.title} className={classes.illu} />
                                    </div>
                                    <div className={classes.textWrapper}>
                                        <h4 className={classes.inclusionTitle}>{incl.title}</h4>
                                        <p className={classes.inclusions}>
                                            {incl.values.map((value, i) => { return i === (incl.values.length - 1) ? <span>{value}</span> : <span>{`${value}, `}</span>; })}
                                        </p>
                                    </div>
                                </li>
                            ))

                        }
                    </ul>
                </div>
            </div>
        </section>
    );
}

export default TripInclusions;