import React from 'react';
import {FacebookShareButton, TwitterShareButton, WhatsappShareButton, PinterestShareButton} from 'react-share';
import {FacebookIcon, TwitterIcon, WhatsappIcon, PinterestIcon} from 'react-share';
import makeStyles from "@material-ui/core/styles/makeStyles";
import {useMediaQuery} from "@material-ui/core";
import faceBookIcon from './img/icon_facebook.svg';
import whatsAppIcon from './img/icon_whatsapp.svg';
import twitterIcon from './img/icon_twitter.svg';
import pinterestIcon from './img/icon_pinterest.svg';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        alignItems: 'center'
    },
    label: {
        fontWeight: 800,
        marginRight: '0.8rem',
        marginBottom: '-2px',

        [theme.breakpoints.down('xs')]: {
            marginRight: '0.5rem'
        }
    },
    iconsWrapper: {
        display: 'flex'
    },
    shareButton: {
        marginLeft: '0.5rem',

        '&:hover': {
            cursor: 'pointer'
        }
    },
    shareIcon: {
        width: '40px',
        height: '40px',

        [theme.breakpoints.down('sm')]: {
            width: '30px',
            height: '30px'
        }
    }
}));

function Share(props) {
    const classes = useStyles();
    const shareUrl = window.location.href;

    return (
        <div className={classes.root}>
            <p className={classes.label}>Share</p>
            <ul className={classes.iconsWrapper}>
                <li className={classes.shareButton}>
                    <FacebookShareButton url={shareUrl}>
                        <div className={`${classes.faceBookIcon} ${classes.shareIcon}`}>
                            <img src={faceBookIcon} alt="Facebook Logo"/>
                        </div>
                    </FacebookShareButton>
                </li>
                <li className={classes.shareButton}>
                    <WhatsappShareButton url={shareUrl}>
                        <div className={classes.shareIcon}>
                            <img src={whatsAppIcon} alt="Whatsapp Logo"/>
                        </div>
                    </WhatsappShareButton>
                </li>
                <li className={classes.shareButton}>
                    <TwitterShareButton url={shareUrl}>
                        <div className={classes.shareIcon}>
                            <img src={twitterIcon} alt="Twitter Logo"/>
                        </div>
                    </TwitterShareButton>
                </li>
                <li className={classes.shareButton}>
                    <PinterestShareButton url={shareUrl} media={props.image}>
                        <div className={classes.shareIcon}>
                            <img src={pinterestIcon} alt="Pinterest Logo"/>
                        </div>
                    </PinterestShareButton>
                </li>
            </ul>
        </div>
    );
}

export default Share;