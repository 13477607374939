import React, {useEffect, useRef} from 'react';
import trips from './data/trips';
import TripParallaxTeaser from "./TripParallaxTeaser";
import makeStyles from "@material-ui/core/styles/makeStyles";
import underline from './img/underline.svg';
import illuYoga from './img/illus/illu_yoga.svg';
import {navHeight} from './NavBar';
import bg from './img/trips/bg_trips_page.svg';
import comingSoonBg from './img/trips/coming_soon_bg.svg';
import _ from "lodash";

const useStyles = makeStyles(theme => ({
    root: {

    },
    header: {
        position: 'relative',
        minHeight: `calc(30vh - ${navHeight.xl})`,
        maxWidth: '80rem',
        margin: '0 auto',
        display: 'flex',
        alignItems: 'center',
        padding: '5rem 5%',

        [theme.breakpoints.down('md')]: {
            minHeight: 'auto',
            justifyContent: 'space-between'
        },
        [theme.breakpoints.down('xs')]: {
            height: 'auto',
            padding: '3rem 0',
            display: 'block',
        }
    },
    titleWrapper: {
        width: '45%',

        [theme.breakpoints.down('xs')]: {
            width: '100%',
            marginBottom: '3rem'
        }
    },
    titleBg: {
        position: 'absolute',
        width: '36rem',
        top: '-77%',
        right: '-3%',
        zIndex: 0,

        [theme.breakpoints.only('md')]: {
            top: '-88%',
            width: '35rem'
        },
        [theme.breakpoints.down('sm')]: {
            top: '-85%',
            width: '30rem'
        },
        [theme.breakpoints.down('xs')]: {
            top: '-47%',
            right: '-5%',
            width: '20rem'
        }
    },
    title: {
        display: 'inline-block',
        fontSize: '4rem',
        transform: 'rotate(-3deg)',

        [theme.breakpoints.down('sm')]: {
            fontSize: '3rem'
        },

        '@media (min-width: 600.05px) and (max-width: 750px)': {
            fontSize: '2.4rem',
        },

        '&::after': {
            content: '\"\"',
            position: 'absolute',
            left: '-5%',
            bottom: '-0.5rem',
            width: '110%',
            height: '0.5rem',
            backgroundImage: `url(${underline})`,
            backgroundRepeat: 'no-repeat',

            [theme.breakpoints.down('xs')]: {
                left: 0,
                // bottom: 0,
                width: '100%',
                height: '0.3rem'
            },
            '@media (max-width: 750px)': {
                height: '0.3rem'
            },
        }
    },
    description: {
        position: 'relative',
        width: '50%',
        margin: 0,

        [theme.breakpoints.down('md')]: {
            width: '45%'
        },
        [theme.breakpoints.down('xs')]: {
            width: '100%'
        }
    },
    tripWrapper: {
        overflow: 'hidden',
        marginBottom: '5rem',

        [theme.breakpoints.down('xs')]: {
            marginBottom: '0.5rem'
        },
    },
    comingSoon: {
        position: 'relative',
        paddingTop: '40%',
        overflow: 'hidden',
        background: `url(${comingSoonBg}) no-repeat`,
        backgroundSize: '100%',
        backgroundPosition: 'center',

        [theme.breakpoints.down('xs')]: {
            paddingTop: '50%'
        }
    },
    innerWrapper: {
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: '-2.5rem',
        right: '-2.5rem',
        height: '100%',
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'center',
        padding: '0 12%',
        boxShadow: 'inset 0px 0px 10px 17px rgba(0,0,0,0.1)',

        [theme.breakpoints.down('xs')]: {
            boxShadow: 'inset 0px 0px 10px 8px rgba(0,0,0,0.1)',
        }
    },
    tripsList: {
        [theme.breakpoints.down('xs')]: {
            margin: '0 -5vw'
        }
    },
    comingSoonTitleWrapper: {

    },
    illuWrapper: {
        width: '45%'
    },
    comingSoonTitle: {
        textAlign: 'center',
        lineHeight: 1.2,

        [theme.breakpoints.down('md')]: {
            fontSize: '3.5rem'
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: '2rem'
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: '1.2rem'
        },
        [theme.breakpoints.up('xl')]: {
            fontSize: '6rem'
        }
    }
}));

function TripsList(props) {
    const classes = useStyles();
    const basePath = './img/';
    const parallax = useRef(null);

    const handleScroll = () => {
        const element = parallax.current;
        const elementOffsetTop = element.offsetTop;
        const elementHeight = element.offsetHeight;

        if (
            (window.scrollY + window.innerHeight) > elementOffsetTop &&
            (window.scrollY + window.innerHeight) < (elementOffsetTop + window.scrollY + elementHeight)
        ) {
            const scrollHeight = elementHeight + window.innerHeight;
            const step = scrollHeight / 100;
            let pos = ((elementOffsetTop - window.scrollY + elementHeight) / step) > 0 ?  ((elementOffsetTop - window.scrollY + elementHeight) / step) : 0;
            element.style.backgroundPositionY = pos + '%';

        } else if (window.innerHeight > elementOffsetTop) {
            const step = (elementOffsetTop + elementHeight) / 100;
            let pos = window.scrollY / step < 100 ? (100 - (window.scrollY / step)) : 0;
            element.style.backgroundPositionY = pos + '%';
        }
    };

    const throttleScroll = _.throttle(handleScroll, 16);

    useEffect(() => {
        document.addEventListener('scroll', throttleScroll, false);
        return () => {
            document.removeEventListener('scroll', throttleScroll, false);
        }
    });

    return (
        <section className={classes.root}>
            <header className={classes.header}>
                <img src={bg} alt="" className={classes.titleBg}/>
                <div className={classes.titleWrapper}>
                    <h1 className={classes.title}>Choose your <br/>adventure</h1>
                </div>
                <p className={classes.description}>
                    Set out to discover the world in a local way! Choose from different locations all over
                    Europe for your new and authentic experience:
                </p>
            </header>
            <ul className={classes.tripsList}>
                {
                    trips.map((trip, index) => (
                        <li className={classes.tripWrapper} key={index}>
                            <TripParallaxTeaser
                                title={trip.name}
                                img={{
                                    small: require(`${basePath}${trip.slug}/teaser_small.jpg`),
                                    medium: require(`${basePath}${trip.slug}/teaser_large.jpg`),
                                    large: require(`${basePath}${trip.slug}/cover.jpg`),
                                }}
                                altText={trip.altText}
                                slug={trip.slug}
                            />
                        </li>
                    ))
                }
                {/*<li key={trips.length}>*/}
                {/*    <div className={classes.comingSoon} ref={parallax}>*/}
                {/*        <div className={classes.innerWrapper}>*/}
                {/*            <div className={classes.comingSoonTitleWrapper}>*/}
                {/*                <h2 className={classes.comingSoonTitle}>*/}
                {/*                    <span className="line-break">Relax … </span>*/}
                {/*                    <span className="line-break">more triPs are </span>*/}
                {/*                    <span className="line-break">coming soon!</span>*/}
                {/*                </h2>*/}
                {/*            </div>*/}
                {/*            <div className={classes.illuWrapper}>*/}
                {/*                <img src={illuYoga} alt="Person making yoga"/>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</li>*/}
            </ul>
        </section>
    );
}

export default TripsList;