import React from 'react';
import makeStyles from "@material-ui/core/styles/makeStyles";
import boxShadows from "./styles/boxShadows";
import colors from "./styles/colors";
import useToggleState from "./hooks/useToggleState";

const useStyles = makeStyles(theme => ({
    root: {
        position: 'relative',
        width: '95%',
        margin: '0 auto',
        paddingTop: '120%',
        boxShadow: boxShadows.normal,
        backgroundColor: colors.white,
        transition: 'transform 300ms linear',

        '&:hover': {
            boxShadow: boxShadows.hover
        },
        [theme.breakpoints.down('xs')]: {
            width: '100%',
        }
    },
    outerWrapper: {
        paddingBottom: '2.5rem'
    },
    title: {
        fontSize: '1.2rem',

        [theme.breakpoints.down('sm')]: {
            fontSize: '1.2rem',
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: '1rem',
        }
    },
    innerWrapper: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        padding: '1.2rem',
        transition: 'opacity 300ms linear',

        [theme.breakpoints.down('sm')]: {
            padding: '1rem'
        },
        [theme.breakpoints.down('xs')]: {
            padding: '0.75rem'
        }
    },
    imgWrapper: {
        marginBottom: '0.5rem'
    },
    summary: {
        position: 'absolute',
        bottom: '1.25rem',
        left: '1.25rem',

        [theme.breakpoints.down('sm')]: {
            bottom: '1rem',
            left: '1rem',
        },
        [theme.breakpoints.down('xs')]: {
            bottom: '0.75rem',
            left: '0.75rem'
        }
    },
    descriptionWrapper: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        padding: '1.2rem',
        transform: 'rotate3d(0, 1, 0, 180deg)',
        transition: 'opacity 300ms linear',
        overflow: 'scroll',

        [theme.breakpoints.down('sm')]: {
            padding: '1rem'
        },
        [theme.breakpoints.down('xs')]: {
            padding: '0.75rem'
        }
    },
    description: {
        width: '100%',
        height: '100%',
        overflow: 'scroll',
        fontSize: '0.875rem'
    }
}));

function Experience(props) {
    const classes = useStyles();
    const { cityId, img, altText, name, duration, price, description } = props;
    const basePath = `./img/${cityId}/optional`;
    const [rotate, toggleRotate] = useToggleState(false);

    const handleClick = () => {
        toggleRotate();
    };

    return (
        <div className={classes.outerWrapper}>
            <article className={classes.root} onClick={handleClick} style={{transform: (rotate ? 'rotate3d(0, 1, 0, 180deg)' : '')}}>
                <div className={classes.innerWrapper} style={{opacity: (rotate ? 0 : 1)}}>
                    <figure className={classes.imgWrapper}>
                        <picture>
                            <source
                                src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
                                data-srcset={`${require(`${basePath}/1x/${img}.webp`)} 300w, ${require(`${basePath}/2x/${img}.webp`)} 600w`}
                                type="image/webp"
                            />
                            <source
                                src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
                                data-srcset={`${require(`${basePath}/1x/${img}.jpg`)} 300w, ${require(`${basePath}/2x/${img}.jpg`)} 600w`}
                                type="image/jpeg"
                            />
                            <img
                                src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
                                data-src={require(`${basePath}/1x/${img}.jpg`)}
                                alt={altText}
                                className={`lazyload ${classes.img}`}
                                data-sizes="auto"
                            />
                        </picture>
                    </figure>
                    <h3 className={classes.title}>{name}</h3>
                    <summary className={classes.summary}>
                        {
                            duration && <span>{`${duration} h | `}</span>
                        }
                        <span>{`${price} €`}</span>
                    </summary>
                </div>
                <div className={classes.descriptionWrapper} style={{opacity: (rotate ? 1 : 0)}}>
                    <div className={classes.description}>
                        <p>{ description }</p>
                    </div>
                </div>
            </article>
        </div>
    );
}

export default Experience;