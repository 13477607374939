import React, {useEffect, useState} from 'react';
import colors from "./styles/colors";
import makeStyles from "@material-ui/core/styles/makeStyles";
import PaginationDot from "./PaginationDot";

const dotWidth = 0.625;
const dotMargin = 0.5;

const useStyles = makeStyles({
    dotGroup: {
        display: 'flex'
    },
    dot: {
        display: 'block',
        position: 'relative',
        width: dotWidth + 'rem',
        height: dotWidth  + 'rem',
        backgroundColor: colors.lightTransparentBlack,
        marginRight: dotMargin + 'rem',
        borderRadius: dotWidth + 'rem',
        cursor: 'pointer',
        pointerEvents: props => (props.clickable ? '' : 'none'),

        '&.active': {
            '&::after': {
                content: '\"\"',
                position: 'absolute',
                width: dotWidth + 'rem',
                height: dotWidth  + 'rem',
                top: 0,
                borderRadius: '0.625rem',
                backgroundColor: colors.black,
                transition: 'all 300ms ease-in-out',
                zIndex: 1
            },
            '&.moving-right::after': {
                left: 0,
                width: (dotWidth*2 + dotMargin) + 'rem'
            },
            '&.finish-move-right::after': {
                left: 0,
                transform: `translateX(${(dotWidth + dotMargin)}rem)`
            },
            '&.moving-right-2::after': {
                width: ((dotWidth*3 + dotMargin*2)) + 'rem'
            },
            '&.finish-move-right-2::after': {
                transform: `translateX(${(dotWidth + dotMargin)*2}rem)`
            },
            '&.moving-right-3::after': {
                width: ((dotWidth*4 + dotMargin*3)) + 'rem'
            },
            '&.finish-move-right-3::after': {
                transform: `translateX(${(dotWidth + dotMargin)*3}rem)`
            },
            '&.moving-right-4::after': {
                width: ((dotWidth*5 + dotMargin*4)) + 'rem'
            },
            '&.finish-move-right-4::after': {
                transform: `translateX(${(dotWidth + dotMargin)*4}rem)`
            },
            '&.moving-right-5::after': {
                width: ((dotWidth*6 + dotMargin*5)) + 'rem'
            },
            '&.finish-move-right-5::after': {
                transform: `translateX(${(dotWidth + dotMargin)*5}rem)`
            },
            '&.moving-right-6::after': {
                width: ((dotWidth*7 + dotMargin*6)) + 'rem'
            },
            '&.finish-move-right-6::after': {
                transform: `translateX(${(dotWidth + dotMargin)*6}rem)`
            },



            '&.moving-left::after': {
                right: 0,
                width: (dotWidth*2 + dotMargin) + 'rem'
            },
            '&.finish-move-left::after': {
                right: 0,
                transform: `translateX(-${(dotWidth + dotMargin)}rem)`
            },
            '&.moving-left-2::after': {
                right: 0,
                width: ((dotWidth*3 + dotMargin*2)) + 'rem'
            },
            '&.finish-move-left-2::after': {
                transform: `translateX(-${(dotWidth + dotMargin)*2}rem)`
            },
            '&.moving-left-3::after': {
                right: 0,
                width: ((dotWidth*4 + dotMargin*3)) + 'rem'
            },
            '&.finish-move-left-3::after': {
                right: 0,
                transform: `translateX(-${(dotWidth + dotMargin)*3}rem)`
            },
            '&.moving-left-4::after': {
                right: 0,
                width: ((dotWidth*5 + dotMargin*4)) + 'rem'
            },
            '&.finish-move-left-4::after': {
                right: 0,
                transform: `translateX(-${(dotWidth + dotMargin)*4}rem)`
            },
            '&.moving-left-5::after': {
                right: 0,
                width: ((dotWidth*6 + dotMargin*5)) + 'rem'
            },
            '&.finish-move-left-5::after': {
                right: 0,
                transform: `translateX(-${(dotWidth + dotMargin)*5}rem)`
            },
            '&.moving-left-6::after': {
                right: 0,
                width: ((dotWidth*7 + dotMargin*6)) + 'rem'
            },
            '&.finish-move-left-6::after': {
                right: 0,
                transform: `translateX(-${(dotWidth + dotMargin)*6}rem)`
            },
        }
    }
});

let savedSlideIndex = 0;

function Pagination(props) {
    const classes = useStyles(props);
    const {slideIndex, slidesCount, pageCount, initialSlide, slidesToShow} = props;
    const [paginationIndex, setPaginationIndex] = useState(slideIndex);

    let paginationRef = React.createRef();

    useEffect(() => {
        if (savedSlideIndex !== slideIndex) {
            if (slideIndex > paginationIndex) {
                if (paginationIndex + 1 >= paginationRef.current.childNodes.length) {
                    paginationPrev(paginationIndex);
                } else {
                    paginationNext(1);
                }
            }

            if (slideIndex < paginationIndex) {
                if (paginationIndex - 1 < 0) {
                    paginationNext(paginationRef.current.childNodes.length - 1);
                } else {
                    paginationPrev(1);
                }
            }
        }
    }, [slideIndex]);

    const paginationNext = (steps) => {
        const activeElement = paginationRef.current.querySelector('.active');
        const newActiveElement = paginationRef.current.childNodes[paginationIndex + steps].childNodes[0];

        const movingClassname = steps > 1 ? `moving-right-${steps}` : 'moving-right';
        const finishClassname = steps > 1 ? `finish-move-right-${steps}` : 'finish-move-right';

        activeElement.classList.add(movingClassname);

        setTimeout(() => {
            activeElement.classList.remove(movingClassname);
            activeElement.classList.add(finishClassname);
        }, 300);

        setTimeout(() => {
            newActiveElement.classList.add('active');
            activeElement.classList.remove(finishClassname);
            activeElement.classList.remove('active');

            props.setClicked(false);
        }, 600);

        setPaginationIndex(paginationIndex + steps);
        savedSlideIndex = paginationIndex + steps;
    };

    const paginationPrev = (steps) => {
        const activeElement = paginationRef.current.querySelector('.active');
        const newActiveElement = paginationRef.current.childNodes[paginationIndex - steps].childNodes[0];

        const movingClassname = steps > 1 ? `moving-left-${steps}` : 'moving-left';
        const finishClassname = steps > 1 ? `finish-move-left-${steps}` : 'finish-move-left';

        activeElement.classList.add(movingClassname);

        setTimeout(() => {
            activeElement.classList.remove(movingClassname);
            activeElement.classList.add(finishClassname);
        }, 300);

        setTimeout(() => {
            newActiveElement.classList.add('active');
            activeElement.classList.remove(finishClassname);
            activeElement.classList.remove('active');

            props.setClicked(false);
        }, 600);

        setPaginationIndex(paginationIndex - steps);
        savedSlideIndex = paginationIndex - steps;
    };

    const handleDotClick = (e) => {
        const index = parseInt(e.target.dataset.index);

        if (index > paginationIndex) {
            paginationNext(index - paginationIndex);
        } else if (index < paginationIndex) {
            paginationPrev(paginationIndex - index);
        }

        let newSlideIndex = 0;

        if ((index * slidesToShow) + slidesToShow > slidesCount) {
            newSlideIndex = slidesCount - slidesToShow;
        } else {
            newSlideIndex = index * slidesToShow;
        }
        props.setSlideIndex(newSlideIndex);
        savedSlideIndex = newSlideIndex;
    };

    const generateDots = () => {
        let dots = [];

        for (let i = 0; i < pageCount; i++) {
            dots.push(
                <li key={i}>
                    <span
                        data-index={i}
                        className={`${classes.dot} ${i === initialSlide ? 'active' : ''}`}
                        onClick={handleDotClick}
                    ></span>
                </li>
            );
        }
        return dots;
    };

    return (
        <ul className={classes.dotGroup} ref={paginationRef}>
            {generateDots()}
        </ul>
    );
}

export default Pagination;