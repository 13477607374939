import React, {useEffect, useState} from 'react';
import { makeStyles } from "@material-ui/core/styles";
import {useScrollTrigger} from "@material-ui/core";
import colors from "./styles/colors";
import Footer from "./Footer";
import ReactGA from 'react-ga4';

const useStyles = makeStyles(theme => ({
    root: {
        padding: '5rem 5vw 0',
        backgroundColor: colors.white,
        transition: 'padding-top 200ms linear',
        overflowX: 'hidden',

        [theme.breakpoints.down('md')]: {
            padding: '4rem 6vw 0',
        },
        [theme.breakpoints.down('xs')]: {
            padding: '3rem 5vw 0',
        }
    }
}));

function Page(props) {
    const classes = useStyles();

    useEffect(() => {
        ReactGA.send("pageview");
    });

    const scrollTrigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 0,
    });

    const paddingTop = scrollTrigger ? '4rem' : '';
    const [transition, setTransition] = useState('none');

    setTimeout(() => {
        setTransition('');
    }, 100);

    return (
        <main className={classes.root} style={{paddingTop, transition}}>
            {props.children}
            <Footer/>
        </main>
    );
}

export default Page;