import React from 'react';
import FlatButton from "./FlatButton";
import colors from "./styles/colors";
import makeStyles from "@material-ui/core/styles/makeStyles";
import bg from './img/blog/bg_blog_home_videos.svg';
import mobileBg from './img/blog/bg_blog_home_videos_mobile.svg';
import {useMediaQuery} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    root: {
        margin: '5rem -5vw 8rem',
        background: `url(${bg}) no-repeat`,
        backgroundSize: '101%',
        backgroundPosition: 'center',
        padding: '5rem 5vw',

        [theme.breakpoints.down('md')]: {
            margin: '5rem -6vw',
            padding: '5rem 6vw 4rem'
        },
        [theme.breakpoints.down('xs')]: {
            background: `url(${mobileBg}) no-repeat`,
            margin: '5rem -5vw',
            padding: '0 1rem'
        }
    },
    innerWrapper: {
        maxWidth: '80rem',
        margin: '0 auto',
        display: 'flex',

        [theme.breakpoints.down('xs')]: {
            flexWrap: 'wrap'
        }
    },
    leftColumn: {
        paddingTop: '3rem',
        width: '30%',

        [theme.breakpoints.down('xs')]: {
            width: '100%',
            marginBottom: '3rem'
        }
    },
    rightColumn: {
        width: '70%',

        [theme.breakpoints.down('xs')]: {
            width: '100%',
            paddingBottom: '2rem'
        }
    },
    description: {
        width: '70%',
        marginBottom: '3rem',

        [theme.breakpoints.down('xs')]: {
            width: '100%',
            marginBottom: '1.5rem'
        }
    }
}));

function BlogVideos(props) {
    const classes = useStyles();
    const matchesXsDown = useMediaQuery('(max-width: 599.95px)');
    const videoHeight = matchesXsDown ? 230 : 500;

    return (
        <section className={classes.root}>
            <div className={classes.innerWrapper}>
                <div className={classes.leftColumn}>
                    <h3>Videos</h3>
                    <p className={classes.description}>
                        It's always good to know a bit about the places you are going to visit before leaving.
                        <br/><br/>
                        Here we collect Videos that have relevance to us, with lots of insights about culture, people,
                        and places of our destinations.
                    </p>
                    {/*<FlatButton bgColor={colors.black} color={colors.white}>*/}
                    {/*    More Videos*/}
                    {/*</FlatButton>*/}
                </div>
                <div className={classes.rightColumn}>
                    <iframe
                        title="Youtube Video"
                        width="100%"
                        height={videoHeight}
                        src="https://www.youtube.com/embed/zqp8YtSkJKk"
                        frameBorder="0"
                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                        color="white"
                    />
                </div>
            </div>
        </section>
    );
}

export default BlogVideos;