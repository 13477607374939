import React from "react";
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {makeStyles} from "@material-ui/core";
import colors from "./styles/colors";
import bg from './img/bg_faq.svg';

const useStyles = makeStyles( theme => ({
    root: {
        position: 'relative'
    },
    bgIllu: {
        position: 'absolute',
        top: '-10rem',
        right: '-15rem',
        width: '28rem',
        zIndex: 0,

        '@media (max-width: 90rem)': {
            right: '-7rem',
        },
        [theme.breakpoints.down('sm')]: {
            top: '-8rem',
            right: '-5rem',
            width: '20rem',
        },
        [theme.breakpoints.down('xs')]: {
            top: '-4rem',
            right: '-11rem',
            width: '25rem',
        }
    },
    accordionTitle: {
        fontWeight: 800,
        margin: 0
    },
    accordionText: {
        margin: 0,

        '& a': {
            color: colors.orange
        }
    }
}));


function FAQ(props) {
    const classes = useStyles();
    const {city, country, airport, hotel} = props;

    return (
        <section className={classes.root}>
            <img src={bg} alt="" className={classes.bgIllu}/>
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                >
                    <p className={classes.accordionTitle}>Travel Documents</p>
                </AccordionSummary>
                <AccordionDetails>
                    <p className={classes.accordionText}>
                        Depending on where you’re traveling and your nationality, you'll need a valid passport and may also
                        need visas or vaccinations to enter certain countries. If you live legally in an EU-country
                        part of the Schengen Area, you don't need a special Visa for traveling to {country}. If you don't
                        live within the Schengen Area, you may need a Visa, so before booking, please ensure you check
                        what documents are required to enter the country you are visiting. Remember that Visas can take up
                        to six weeks & a fee is normally charged. If you have any questions, please contact us and we
                        will help you wherever we can.
                    </p>
                </AccordionDetails>
            </Accordion>
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                >
                    <p className={classes.accordionTitle}>Arrival and Departure</p>
                </AccordionSummary>
                <AccordionDetails>
                    <p className={classes.accordionText}>
                        Please remember to book your flight, train, car, or whatever you want to use to arrive
                        in {city} separately, it is not included in the trip. The closest airport is {airport}. We
                        recommend being at the meet-up point ({hotel.title} in {city}, {country}) around 5-6 pm local
                        time, to make sure you arrive in time for the Welcome Dinner / Welcome snacks & drinks, where
                        you will meet your group for the first time. We normally start at 7 pm local time. On the last
                        day of the tour, we normally don't plan activities so you are free to leave whenever you want.
                        You will get a detailed itinerary one week before your trip starts.
                    </p>
                </AccordionDetails>
            </Accordion>
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                >
                    <p className={classes.accordionTitle}>What's not included</p>
                </AccordionSummary>
                <AccordionDetails>
                    <p className={classes.accordionText}>
                        Flights to and from your starting and ending points are not included in the cost of your trip,
                        so please remember to book them separately. The closest airport is {airport}.
                        <br/><br/>
                        Also keep in mind that only some food and drinks are included during your stay, specifically
                        everything listed in the <a href="#whats-included">What's included</a> section. The rest of the
                        meals and drinks you want to have are not included, so please don't forget to budget for this.
                        <br/><br/>
                        Travel insurance and visas are also not included in the cost of your trip, and should be
                        booked separately.
                    </p>
                </AccordionDetails>
            </Accordion>
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                >
                    <p className={classes.accordionTitle}>Transport</p>
                </AccordionSummary>
                <AccordionDetails>
                    <p className={classes.accordionText}>
                        We sometimes need to move further distances that we cannot cover by walking. For these trips,
                        within the city, we usually use public transport, and for excursions outside of the city,
                        we move in a private small bus, perfect for our group.
                    </p>
                </AccordionDetails>
            </Accordion>
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                >
                    <p className={classes.accordionTitle}>Age & Accessibility</p>
                </AccordionSummary>
                <AccordionDetails>
                    <p className={classes.accordionText}>
                        Our trips are for adults only (21+). The reason for this is that as part of the trips we usually
                        include alcoholic drinks tastings as well as visits to bars and clubs. Some of them may not allow
                        entrance to people younger than 21.
                        <br/><br/>
                        We don't have a maximum age limit, but we think that our trips are perfect if you're in your
                        20s, 30s, or 40s.
                        <br/><br/>
                        Unfortunately, our trips are not suitable for people with reduced mobility.
                    </p>
                </AccordionDetails>
            </Accordion>
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                >
                    <p className={classes.accordionTitle}>Minimum participants</p>
                </AccordionSummary>
                <AccordionDetails>
                    <p className={classes.accordionText}>
                        If the minimum number of 8 participants is not reached, Travelmeister reserves the right to cancel the trip,
                        if considered that it will affect negatively the quality of the journey itself, and the participants must be
                        notified the latest 14 days before the start of the trip. Of course, we will refund you the amount you paid.
                        <br/><br/>
                        In the case of cancellation, if you want, we can help you to do the same or similar activities
                        with other local tour operators and help you to find a suitable hotel replacement.
                    </p>
                </AccordionDetails>
            </Accordion>
        </section>
    );
}

export default FAQ;