import React, {useEffect, useState} from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import { makeStyles } from '@material-ui/core/styles';
import TopNavigation from './TopNavigation';
import {Link, withRouter} from 'react-router-dom';

import logo from './img/logo.svg';
import logoSmall from './img/logo_small.svg';
import {useMediaQuery} from "@material-ui/core";

export const navHeight = {
    xs: '3rem',
    md: '4rem',
    xl: '5rem'
};

const pagesWithBoxShadow = ['/blog', '/imprint', '/how-it-works', '/privacy'];

const useStyles = makeStyles(theme => ({
    appBar: {
        height: navHeight.xl,
        transition: 'height 200ms ease-out',
        padding: '0 5vw',

        [theme.breakpoints.down('md')]: {
            height: navHeight.md,
            padding: '0 6vw',
        },
        [theme.breakpoints.down('sm')]: {
            padding: '0 5vw',
        },
        [theme.breakpoints.down('xs')]: {
            height: navHeight.xs
        }
    },
    logoWrapper: {
        position: 'relative',
        zIndex: 10,
        marginTop: '4.3rem',
        verticalAlign: 'middle',
        flexShrink: 0,
        transition: 'all 200ms ease-out',

        '@media (min-width: 600.05px) and (max-width: 1024px)': {
            marginTop: '3.5rem',
        },
        [theme.breakpoints.down('xs')]: {
            marginTop: '2.8rem'
        }
    },
    logo: {
        width: 'auto',
        height: '9vw',
        maxHeight: '8rem',
        transition: 'height 200ms ease-out',

        [theme.breakpoints.down('sm')]: {
            height: '6rem'
        },

        [theme.breakpoints.down('xs')]: {
            height: '4.8rem',
        }
    },
    logoSmall: {
        position: 'absolute',
        top: 0,
        right: 0,
        transition: 'width 200ms ease-out',
        display: 'none'
    },
    toolBar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        height: navHeight.xl,
        transition: 'height 200ms linear',
        padding: '0 4rem',

        [theme.breakpoints.down('md')]: {
            height: navHeight.md,
            minHeight: navHeight.md,
            padding: '0 2rem',
        },
        [theme.breakpoints.down('xs')]: {
            height: navHeight.xs,
            minHeight: navHeight.xs,
            padding: '0 1rem'
        },
    },
}));

function NavBar(props) {
    const classes = useStyles();
    const scrollTrigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 0,
    });
    const matchesXsDown = useMediaQuery('(max-width: 599.95px)');
    let height = matchesXsDown ? '3rem' : '4rem';
    let logoHeight = matchesXsDown ? '2.75rem' : '3.7rem';
    height = scrollTrigger ? height : '';
    logoHeight = scrollTrigger ? logoHeight : '';
    const logoMargin = scrollTrigger ? (matchesXsDown ? '-1px' : '-3px') : '';
    const withInitialShadow = pagesWithBoxShadow.indexOf(props.location.pathname) !== -1;
    const initialElevation = withInitialShadow ? 4 : 0;
    const elevation = scrollTrigger ? 4 : initialElevation;

    const [transition, setTransition] = useState('none');

    setTimeout(() => {
        setTransition('');
    }, 100);

    // Add Zendesk Widget
    useEffect(() => {
        const script = document.createElement('script');

        script.src = 'https://static.zdassets.com/ekr/snippet.js?key=3a93cfa6-8d38-4ac7-9af4-8536d704b024';
        script.async = false;
        script.id = 'ze-snippet';

        setTimeout(() => {
            document.body.appendChild(script);
        }, 10000);

    }, []);

    return (
        <AppBar color="inherit" className={classes.appBar} style={{height: height, transition}} elevation={elevation}>
            <Toolbar className={classes.toolBar} style={{height: height, minHeight: height, transition}}>
                <Link to="/">
                    <div className={classes.logoWrapper} style={{marginTop: logoMargin}}>
                        <img src={logo} alt="Small Logo Travelmeister" className={classes.logo} style={{height: logoHeight, transition, opacity: scrollTrigger ? 0 : ''}} />
                        <img src={logoSmall} alt="Logo Travelmeister" className={classes.logoSmall} style={{display: scrollTrigger ? 'block' : ''}}/>
                    </div>
                </Link>
                <TopNavigation scrollTrigger={scrollTrigger}/>
            </Toolbar>
        </AppBar>
    );
}

export default withRouter(NavBar);
