import React, {useEffect, useRef, useState} from 'react';
import makeStyles from "@material-ui/core/styles/makeStyles";
import colors from './styles/colors';
import background from './img/bg_newsletter.svg';
import illu from './img/illus/illu_newsletter.svg';
import NewsletterForm from "./NewsletterForm";

const useStyles = makeStyles(theme => ({
    root: {
        position: 'relative',
        backgroundColor: colors.red,
        margin: '35rem -5vw -7rem',
        padding: '0 5vw',

        '@media (min-width: 1024.05px) and (max-width: 1280px)': {
            padding: '0 6vw 8rem',
            margin: '35rem -6vw -11rem'
        },
        '@media (min-width: 960px) and (max-width: 1024px)': {
            padding: '0 6vw 3rem',
            margin: '28rem -6vw -4rem',
        },
        [theme.breakpoints.down('sm')]: {
            padding: '0 6vw 5rem',
            margin: '20rem -6vw -1rem'
        },
        [theme.breakpoints.down('xs')]: {
            padding: '0 5vw 2rem',
            margin: '17rem -5vw -1rem'
        }
    },
    innerWrapper: {
        maxWidth: '80rem',
        margin: '0 auto',
        transform: 'translateY(-60%)',

        '@media (min-width: 1024.05px) and (max-width: 1280px)': {
            transform: 'translateY(-34%)'
        },
        '@media (min-width: 960px) and (max-width: 1024px)': {
            transform: 'translateY(-20%)'
        },
        [theme.breakpoints.down('sm')]: {
            transform: 'translateY(-7%)'
        },
        [theme.breakpoints.down('xs')]: {
            transform: 'translateY(0)',
            padding: '6rem 0 5rem'
        },
    },
    title: {
        marginBottom: '5rem',
        textTransform: 'uppercase',

        [theme.breakpoints.down('sm')]: {
            marginBottom: '3rem',
        },
    },
    content: {
        width: '50%',

        [theme.breakpoints.down('sm')]: {
            width: '80%'
        },
        [theme.breakpoints.down('xs')]: {
            width: '100%'
        }
    },
    background: {
        position: 'absolute',
        bottom: 'calc(100% - 1px)',
        left: '-2px',
        width: '101%',

        [theme.breakpoints.up('xl')]: {
            bottom: '70%'
        }
    },
    illu: {
        position: 'absolute',
        right: 0,
        top: '-8.5rem',
        width: '45%',

        '@media (min-width: 1024.05px) and (max-width: 1440px)': {
            top: '-6.5rem'
        },
        [theme.breakpoints.down('sm')]: {
            right: '-2rem',
            width: '48%'
        },
        [theme.breakpoints.down('xs')]: {
            top: '-10.5rem',
            right: 0,
            width: '70%'
        },
        '@media (max-width: 430px)': {
            top: '-9.2rem',
            width: '90%'
        }
    },
    description: {
        marginBottom: '3rem'
    }
}));

function Newsletter(props) {
    const classes = useStyles();

    return (
        <section className={classes.root}>
            <img src={background} alt="" className={classes.background}/>
            <div className={classes.innerWrapper}>
                <img src={illu} alt="Person flying on giant newspaper plane" className={classes.illu}/>
                <header className={classes.header}>
                    <h2 className={classes.title}>Stay updated!</h2>
                </header>
                <article className={classes.content}>
                    <p className={classes.description}>
                        Register to our newsletter to always be informed about our latest offers and new
                        exciting destinations. We promise not to spam you – you’ll receive a maximum of one
                        mail per month ;)
                    </p>
                    <NewsletterForm wrapperWidth="40rem" inputWidth="24rem" buttonText="Give me Spam!" />
                </article>
            </div>
        </section>
    );
}

export default Newsletter;