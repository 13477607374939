import React from 'react';
import makeStyles from "@material-ui/core/styles/makeStyles";
import boxShadows from "./styles/boxShadows";
import {withRouter, Link} from 'react-router-dom';
import colors from "./styles/colors";
import TextTruncate from 'react-text-truncate';

const useStyles = makeStyles(theme => ({
    root: {
        position: 'relative',
        paddingTop: '120%',
        cursor: 'pointer',
        flexShrink: 0,
        boxShadow: boxShadows.normal,
        overflow: 'hidden',

        [theme.breakpoints.up('sm')]: {
            '&:hover': {
                boxShadow: boxShadows.hover
            }
        }
    },
    innerWrapper: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        padding: '1.25rem',

        [theme.breakpoints.down('xs')]: {
            padding: '0.875rem'
        }
    },
    img: {
        height: '60%',
        marginBottom: '1rem'
    },
    title: {

    },
    link: {
        color: colors.black,
        textDecoration: 'none'
    },
    teaserText: {
        '& span': {
            '@media (max-width: 440px)': {
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'nowrap'
            }
        }
    }
}));

function BlogCityTeaser(props) {
    const classes = useStyles();

    const handleClick = () => {
        props.history.push(`/blog/${props.id}`);
    };

    return (
        <article className={classes.root} onClick={handleClick}>
            <div className={classes.innerWrapper}>
                <img src={props.img} alt={props.altText} className={classes.img} />
                <h4>
                    <Link to={`/blog/${props.id}`} className={classes.link}>
                        {props.name}
                    </Link>
                </h4>
                <TextTruncate
                    className={classes.teaserText}
                    line={3}
                    element="p"
                    truncateText="…"
                    text={props.teaserText}
                />
            </div>
        </article>
    );
}

export default withRouter(BlogCityTeaser);