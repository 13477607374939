import React, {useEffect, useState} from 'react';
import NukaCarousel from "nuka-carousel";
import Fab from "@material-ui/core/Fab";
import arrowLeft from "./img/arrow_left_white.svg";
import colors from "./styles/colors";
import arrowRight from "./img/arrow_right_white.svg";
import {makeStyles} from "@material-ui/core";
import Pagination from "./Pagination";

import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex'
    },
    container: {
        overflow: 'hidden'
    },
    arrowBtn: {
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
        backgroundColor: '#000',

        '&:hover': {
            backgroundColor: '#000',
        },

        [theme.breakpoints.down('xs')]: {
            display: 'none'
        }
    },
    next: {
        right: '-5rem',

        [theme.breakpoints.down('sm')]: {
            right: '-4rem'
        }
    },
    prev: {
        left: '-5rem',

        [theme.breakpoints.down('sm')]: {
            left: '-4rem'
        }
    },
    arrow: {
        width: '26%',
    },
    arrowRight: {
        transform: 'translate(2px, 1px)'
    },
    arrowLeft: {
        transform: 'translate(-2px, 1px)'
    },
    slideImg: {
        height: '100%',
        objectFit: 'cover'
    },
    pagination: {
        position: 'absolute',
        bottom: '-2rem',
        left: '50%',
        transform: 'translateX(-50%)',

        [theme.breakpoints.down('xs')]: {
            display: 'none'
        }
    },
}));

function CustomCarousel(props) {
    const classes = useStyles();
    const { slides, initialSlide, infinite, slidesToShow, slidesToScroll } = props;
    const [slideIndex, setSlideIndex] = useState(initialSlide);
    const [clicked, setClicked] = useState(false);
    const [nextButtonDisabled, setNextButtonDisabled] = useState(false);
    const [prevButtonDisabled, setPrevButtonDisabled] = useState(false);
    const pageCount = (slides.length % slidesToShow) === 0 ? (slides.length / slidesToShow) : Math.floor((slides.length / slidesToShow)) + 1;

    useEffect(() => {
        if (infinite === false) {
            setPrevButtonDisabled(true);
        }
    }, [infinite]);

    useEffect(() => {
        if (infinite === false) {
            if (slideIndex > 0) {
                setPrevButtonDisabled(false);
            }

            if ((slideIndex + slidesToShow) < slides.length) {
                setNextButtonDisabled(false);
            }

            if ((slideIndex + slidesToShow) === slides.length) {
                setNextButtonDisabled(true);
            }

            if (slideIndex === 0) {
                setPrevButtonDisabled(true);
                setNextButtonDisabled(false);
            }
        }
    }, [slideIndex]);

    const next = (useNative, nativeNextFunction) => {
        if (clicked) {
            return;
        }
        setClicked(true);
        setPrevButtonDisabled(false);

        let oldIndex = slideIndex;
        let steps = slidesToScroll;

        if (infinite === false && (((oldIndex) + slidesToShow) + steps) > slides.length) {
            steps = slides.length % slidesToShow;
            setNextButtonDisabled(true);
        }

        setSlideIndex(oldIndex + steps);

        if (useNative) {
            nativeNextFunction();
        }
    };

    const prev = (useNative, nativePrevFunction) => {
        if (clicked) {
            return;
        }
        setClicked(true);
        setNextButtonDisabled(false);

        let oldIndex = slideIndex;
        let steps = slidesToScroll;

        if (infinite === false && (oldIndex - steps) < 0) {
            steps = oldIndex;
        }

        if (oldIndex - steps === 0 && infinite === false) {
            setPrevButtonDisabled(true);
        }

        setSlideIndex(oldIndex - steps);

        if (useNative) {
            nativePrevFunction();
        }
    };


    if (slidesToShow > 1) {
        return (
            <div>
                <NukaCarousel
                    wrapAround={infinite}
                    slideIndex={slideIndex}
                    afterSlide={slideIndex => setSlideIndex(slideIndex)}
                    initialSlideWidth={100}
                    initialSlideHeight={640}
                    slidesToShow={slidesToShow}
                    slidesToScroll={slidesToScroll}
                    withoutControls={true}
                >
                    {
                        slides.map(slide => slide)
                    }
                </NukaCarousel>
                {
                    slides.length > slidesToShow ?
                        <React.Fragment>
                            <Fab className={`${classes.arrowBtn} ${classes.prev}`} onClick={() => {prev(false);}} aria-label="Swipe to previous page" disabled={prevButtonDisabled}>
                                <img className={`${classes.arrow} ${classes.arrowLeft}`} src={arrowLeft} alt=""/>
                            </Fab>
                            <Fab className={`${classes.arrowBtn} ${classes.next}`} color={colors.white} onClick={() => {next(false);}} aria-label="Swipe to next page" disabled={nextButtonDisabled}>
                                <img className={`${classes.arrow} ${classes.arrowRight}`} src={arrowRight} alt=""/>
                            </Fab>
                        </React.Fragment> : ''
                }

                <div className={classes.pagination}>
                    {
                        slides.length > 3 ?
                            <Pagination
                                className={classes.pagination}
                                slideIndex={slideIndex}
                                setClicked={setClicked}
                                pageCount={pageCount}
                                slidesCount={slides.length}
                                clickable={!infinite}
                                setSlideIndex={setSlideIndex}
                                initialSlide={initialSlide}
                                slidesToShow={slidesToShow}
                            /> : ''
                    }
                </div>
            </div>
        );
    }
    return (
        <div>
            <Carousel
                className="image-gallery"
                showThumbs={false}
                showIndicators={false}
                swipeable={true}
                infiniteLoop={infinite}
                selectedItem={initialSlide}
                showStatus={false}
                renderArrowPrev={(onClickHandler, hasPrev) =>
                    hasPrev && (
                        <Fab className={`${classes.arrowBtn} ${classes.prev}`} onClick={() => {prev(true, onClickHandler);}} aria-label="Swipe to previous page" disabled={prevButtonDisabled}>
                            <img className={`${classes.arrow} ${classes.arrowLeft}`} src={arrowLeft} alt="" style={{width: '26%'}} />
                        </Fab>
                    )
                }
                renderArrowNext={(onClickHandler, hasNext) =>
                    hasNext && (
                        <Fab className={`${classes.arrowBtn} ${classes.next}`} color={colors.white} onClick={() => {next(true, onClickHandler);}} aria-label="Swipe to next page" disabled={nextButtonDisabled}>
                            <img className={`${classes.arrow} ${classes.arrowRight}`} src={arrowRight} alt="" style={{width: '26%'}} />
                        </Fab>
                    )
                }
            >
                {
                    slides.map(slide => slide)
                }
            </Carousel>

            <div className={classes.pagination}>
                {
                    slides.length > 3 ?
                        <Pagination
                            className={classes.pagination}
                            slideIndex={slideIndex}
                            setClicked={setClicked}
                            pageCount={pageCount}
                            slidesCount={slides.length}
                            clickable={!infinite}
                            setSlideIndex={setSlideIndex}
                            initialSlide={initialSlide}
                            slidesToShow={slidesToShow}
                        /> : ''
                }
            </div>
        </div>
    );
}

export default CustomCarousel;