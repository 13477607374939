import React, {useEffect, useRef, useState} from 'react';
import FlatButton from "./FlatButton";
import makeStyles from "@material-ui/core/styles/makeStyles";
import colors from "./styles/colors";
import ReactPixel from "react-facebook-pixel";

const useStyles = makeStyles(theme => ({
    root: {
        textAlign: 'center',
        padding: '7rem 0 5rem',
        maxWidth: '80rem',
        margin: '0 auto',

        [theme.breakpoints.down('md')]: {
            paddingBottom: '10rem'
        },
        [theme.breakpoints.down('xs')]: {
            padding: '5rem 0'
        }
    },
    title: {
        marginBottom: '1.5rem'
    },
    description: {
        width: '55%',
        margin: '0 auto 4rem',

        [theme.breakpoints.down('sm')]: {
            width: '85%',
            marginBottom: '3rem'
        },
        [theme.breakpoints.down('xs')]: {
            width: '100%',
            marginBottom: '3rem'
        }
    },
    form: {
        maxWidth: '43rem',
        margin: '0 auto',
        minHeight: '3rem'
    },
    inputWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '0.25rem',
        borderRadius: '10rem',
        border: `1px solid ${colors.black}`,

        [theme.breakpoints.down('xs')]: {
            display: 'none'
        }
    },
    input: {
        height: '2rem',
        fontSize: '1rem',
        padding: '0 1.5rem',
        color: colors.black,
        outline: 0,
        boxSizing: 'border-box',

        [theme.breakpoints.down('xs')]: {
            borderRadius: '10rem',
            border: `1px solid ${colors.black}`,
            marginBottom: '1rem',
            height: '3rem'
        }
    },
    inputLeft: {
        [theme.breakpoints.up('sm')]: {
            width: '30%',
            borderLeft: 'none',
            borderBottom: 'none',
            borderTop: 'none',
            borderRight: `1px solid ${colors.black}`,
            borderBottomLeftRadius: '10rem',
            borderTopLeftRadius: '10rem',
        },
        [theme.breakpoints.down('xs')]: {
            width: '80%'
        }
    },
    inputRight: {
        borderTopRightRadius: '10rem',
        borderBottomRightRadius: '10rem',

        [theme.breakpoints.up('sm')]: {
            width: '30%',
            border: 'none',
        },

        [theme.breakpoints.down('xs')]: {
            width: '80%'
        }
    },
    mobileWrapper: {
        [theme.breakpoints.up('sm')]: {
            display: 'none'
        }
    },
    responseWrapper: {
        position: 'absolute',
        marginTop: '1rem',

        '& a': {
            color: colors.black
        }
    }
}));

function NewDestinationForm(props) {
    const classes = useStyles();
    const formRef = useRef(null);
    const responseRef = useRef(null);
    const [formSent, setFormSent] = useState(false);

    const handleSubmit = (e) => {
        e.preventDefault();

        const email = formRef.current.querySelector('#newsletter_email').value;
        const location = formRef.current.querySelector('#newsletter_location').value.replace(/\s*$/,'');
        const botTest = formRef.current.querySelector('#newsletter_test_33f219c445').value;
        const emailRegex = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;

        if (location === '') {
            responseRef.current.innerHTML = 'Please enter a location'
        } else if (emailRegex.test(email)) {
            document.getElementById('mce-EMAIL').value = email;
            document.getElementById('mce-LOCATION').value = location;
            document.getElementById('newsletter-TEST-33f219c445').value = botTest;
            document.getElementById('mc-embedded-subscribe').click();
        } else {
            responseRef.current.innerHTML = 'Please enter a valid Email address'
        }

        ReactPixel.track('Subscribe', {suggestedLocation: location});
    };

    const handleSubmitMobile = (e) => {
        e.preventDefault();

        const email = formRef.current.querySelector('#newsletter_email_mobile').value;
        const location = formRef.current.querySelector('#newsletter_location_mobile').value.replace(/\s*$/,'');
        const botTest = formRef.current.querySelector('#newsletter_test_33f219c445').value;
        const emailRegex = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;

        if (location === '') {
            responseRef.current.innerHTML = 'Please enter a location'
        } else if (emailRegex.test(email)) {
            document.getElementById('mce-EMAIL').value = email;
            document.getElementById('mce-LOCATION').value = location;
            document.getElementById('newsletter-TEST-33f219c445').value = botTest;
            document.getElementById('mc-embedded-subscribe').click();
        } else {
            responseRef.current.innerHTML = 'Please enter a valid Email address'
        }

        ReactPixel.track('Subscribe', {suggestedLocation: location});
    };

    useEffect(() => {
        setErrorObserver();
        setSuccessObserver();
    }, []);

    const setErrorObserver = () => {
        const target = document.querySelector('#mce-error-response');
        const config = {
            attributes: true,
            childList: true
        };

        const observer = new MutationObserver(function(mutations) {
            mutations.forEach(function(mutation) {
                if (mutation.type === "childList") {
                    responseRef.current.innerHTML = target.innerHTML;
                }
            });
        });

        observer.observe(target, config);
    };

    const setSuccessObserver = () => {
        const target = document.querySelector('#mce-success-response');
        const config = {
            attributes: true,
            childList: true
        };

        const observer = new MutationObserver(function(mutations) {
            mutations.forEach(function(mutation) {
                if (mutation.type === "childList") {
                    responseRef.current.innerHTML = target.innerHTML;
                    setFormSent(true);
                }
            });
        });

        observer.observe(target, config);
    };

    return (
        <section className={classes.root} id="new-destination">
            <h3 className={classes.title}>Inspire us!</h3>
            <p className={classes.description}>
                We are working hard on adding new trips very soon. If there is a special destination
                you would like to explore, fill out this form and let us know where to plan next :)
            </p>
            <form
                action="https://travelmeister.us4.list-manage.com/subscribe/post?u=6d3a37f219ecb1a5ecf3caab3&amp;id=33f219c445"
                method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form"
                className={classes.form} target="_blank" noValidate onSubmit={handleSubmit} ref={formRef}
            >
                {
                    !formSent &&
                    <React.Fragment>
                        <div className={classes.inputWrapper}>
                            <input type="text" placeholder="Location" id="newsletter_location" className={`${classes.inputLeft} ${classes.input}`} />
                            <input type="text" placeholder="Email" id="newsletter_email" className={`${classes.inputRight} ${classes.input}`} />
                            <FlatButton bgColor={colors.orange} width="40%" onClick={handleSubmit}>Suggest location</FlatButton>
                        </div>
                        <div className={classes.mobileWrapper}>
                            <input type="text" placeholder="Location" id="newsletter_location_mobile" className={`${classes.inputLeft} ${classes.input}`} />
                            <input type="text" placeholder="Email" id="newsletter_email_mobile" className={`${classes.inputRight} ${classes.input}`} />
                            <FlatButton bgColor={colors.orange} width="80%" onClick={handleSubmitMobile}>Suggest location</FlatButton>
                        </div>
                        <div style={{position: 'absolute', left: '-5000px'}} aria-hidden="true">
                            <input type="text" name="b_6d3a37f219ecb1a5ecf3caab3_33f219c445" tabIndex="-1" value="" id="newsletter_test_33f219c445" />
                        </div>
                    </React.Fragment>
                }
                <div className={classes.responseWrapper} ref={responseRef}></div>
            </form>
        </section>
    );
}

export default NewDestinationForm;