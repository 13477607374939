import React, {useState} from 'react';
import { makeStyles } from "@material-ui/core/styles";
import Page from "./Page";
import {Helmet} from "react-helmet";

const useStyles = makeStyles(theme => ({
    root: {
        padding: '10rem 0',

        '& h1, & h2': {
            fontFamily: 'Open Sans, sans-serif',
            fontWeight: '800'
        },
        '& h1': {
            fontSize: '2rem',
            marginBottom: '2rem'
        },
        '& h2': {
            fontSize: '1.5rem',
            transform: 'rotate(0)',
            margin: '1rem 0 0.75rem',

            '&:after': {
                display: 'none'
            }
        }
    }
}));

function ImprintPage(props) {
    const classes = useStyles();

    return (
        <Page>
            <Helmet>
                <title>Imprint | Travelmeister</title>
                <meta name="description"
                      content="Our goal is to offer you the best possible way of enjoying your destination, bringing
                      you closer to the local way of life, its people and their culture."
                />
            </Helmet>
            <section className={classes.root}>
                <h1>Impressum</h1>

                <h2>Angaben gemäß § 5 TMG</h2>
                <p>Alejandro David Duarte Raposo
                    <br />
                    Travelmeister
                    <br />
                    Singerstraße 33
                    <br />
                    10243 Berlin
                </p>

                <h2>Kontakt</h2>
                <p>Telefon: +49 (0) 173 286 31 25
                    <br />
                    E-Mail: contact@travelmeister.eu
                </p>

                <h2>Umsatzsteuer-ID</h2>
                <p>Umsatzsteuer-Identifikationsnummer gemäß § 27 a Umsatzsteuergesetz:
                    <br />
                    <b>DE324779177</b>
                </p>

                <h2>Verantwortlich für den Inhalt nach § 55 Abs. 2 RStV</h2>
                <p>Alejandro Duarte</p>

                <h2>EU-Streitschlichtung</h2>
                <p>Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit:
                    <a href="https://ec.europa.eu/consumers/odr" target="_blank" rel="noopener noreferrer">https://ec.europa.eu/consumers/odr</a>.
                    <br />
                    Unsere E-Mail-Adresse finden Sie oben im Impressum.
                </p>

                <h2>Verbraucher&shy;streit&shy;beilegung/Universal&shy;schlichtungs&shy;stelle</h2>
                <p>Wir nehmen an einem Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teil. 
                    Zuständig ist die Universalschlichtungsstelle des Zentrums für Schlichtung e.V., Straßburger 
                    Straße 8, 77694 Kehl am Rhein (
                    <a href="https://www.verbraucher-schlichter.de" rel="noopener noreferrer" target="_blank">https://www.verbraucher-schlichter.de</a>
                    ).
                </p>

                <h3>Haftung für Inhalte</h3> 
                <p>
                    Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den 
                    allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch 
                    nicht verpflichtet, übermittelte oder gespeicherte fremde Informationen zu überwachen oder nach 
                    Umständen zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen.
                </p>
                <p>
                    Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen 
                    Gesetzen bleiben hiervon unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt 
                    der Kenntnis einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden 
                    Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.
                </p>
                <h3>Haftung für Links</h3>
                <p>Unser Angebot enthält Links zu externen Websites Dritter, auf deren Inhalte wir keinen Einfluss 
                    haben. Deshalb können wir für diese fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte 
                    der verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten verantwortlich. 
                    Die verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft.
                    Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar.
                </p>
                <p>
                    Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte
                    einer Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir
                    derartige Links umgehend entfernen.
                </p>
                <h3>Urheberrecht</h3>
                <p>
                    Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem
                    deutschen Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung
                    außerhalb der Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des jeweiligen
                    Autors bzw. Erstellers. Downloads und Kopien dieser Seite sind nur für den privaten, nicht
                    kommerziellen Gebrauch gestattet.</p> <p>Soweit die Inhalte auf dieser Seite nicht vom Betreiber
                    erstellt wurden, werden die Urheberrechte Dritter beachtet. Insbesondere werden Inhalte Dritter als
                    solche gekennzeichnet. Sollten Sie trotzdem auf eine Urheberrechtsverletzung aufmerksam werden,
                    bitten wir um einen entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen werden wir
                    derartige Inhalte umgehend entfernen.
                </p>
            </section>
        </Page>
    );
}

export default ImprintPage;