import React from 'react';
import makeStyles from "@material-ui/core/styles/makeStyles";
import colors from "./styles/colors";
import infoIcon from "./img/information.png";

const useStyles = makeStyles(theme => ({
    root: {
        position: 'relative',
        fontSize: '0.8rem',
        fontWeight: 800,
        marginTop: '2rem',
        paddingLeft: '1.75rem',

        '&:before': {
            content: '\"\"',
            position: 'absolute',
            top: '2px',
            left: 0,
            width: '1rem',
            height: '1rem',
            backgroundImage: `url(${infoIcon})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'contain'
        },

        [theme.breakpoints.up('sm')]: {
            marginTop: '1.8rem',
            marginBottom: 0,
            paddingLeft: '1.2rem',

            '&:before': {
                top: '4px',
                width: '0.8rem',
                height: '0.8rem',
            },
        },
        [theme.breakpoints.up('md')]: {
            width: '630px',
            marginLeft: 'auto',
            marginRight: 'auto'
        }
    },
    priceDetailsLink: {
        display: 'inline-block',
        color: colors.orange,
        cursor: 'pointer'
    },
}));

function DepositText(props) {
    const classes = useStyles();
    const { deposit, openPriceDetails } = props;

    return (
        <p className={classes.root}>
            Reserve your spot for only 20% of the price now and pay the rest 30 days before the trip starts. The
            money pot is collected on-site on the first day of the trip. <span className={classes.priceDetailsLink} onClick={openPriceDetails}>Learn more</span>
        </p>
    );
}

export default DepositText;