import React from 'react';
import {makeStyles} from "@material-ui/core/styles";
import Day from "./Day";

const useStyles = makeStyles(theme => ({
    root: {
        maxWidth: '80rem',
        margin: '0 auto',
        padding: '8rem 0',

        [theme.breakpoints.down('xs')]: {
            padding: '5rem 0'
        },
    },
    title: {
        marginBottom: '5rem',

        [theme.breakpoints.down('xs')]: {
            marginBottom: '3rem'
        }
    },
    dayRow: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        maxWidth: '90%',
        margin: '0 auto 10rem',

        [theme.breakpoints.down('sm')]: {
            display: 'block',
            marginBottom: '8rem'
        },
        [theme.breakpoints.down('xs')]: {
            marginBottom: '5rem'
        },
        '&:nth-child(even)': {

            [theme.breakpoints.up('md')]: {
                flexDirection: 'row-reverse',
            }
        },
        '&:last-child': {
            [theme.breakpoints.down('xs')]: {
                marginBottom: '3rem'
            }
        }
    }
}));

function Itinerary(props) {
    const classes = useStyles();
    const { id, days } = props;

    return (
        <section className={classes.root} id="itinerary">
            <h2 className={classes.title}>The Itinerary</h2>
            <ul>
                {
                    days.map((day, i)  => (
                        <li className={classes.dayRow} key={`day_${i+1}`}>
                            <Day
                                cityId={id}
                                index={i}
                                imgName={day.imgName}
                                imgAltText={day.imgAltText}
                                name={day.name}
                                description={day.description}
                                inclusions={day.inclusions}
                            />
                        </li>
                    ))
                }
            </ul>
        </section>
    );
}

export default Itinerary;