import React, {createRef, useEffect, useRef, useState} from 'react';
import CityTeaser from "./BlogCityTeaser";
import img from './img/berlin/cover.jpg';
import makeStyles from "@material-ui/core/styles/makeStyles";
import BlogLane from "./BlogLane";
import axios from 'axios';


const useStyles = makeStyles(theme => ({
    root: {
        maxWidth: '80rem',
        margin: '0 auto 10rem',

        [theme.breakpoints.down('xs')]: {
            marginBottom: '5rem'
        }
    },
    cityList: {
        display: 'flex',
        justifyContent: 'flex-start',
        overflowX: 'scroll',
        padding: '0.5rem 2rem 3rem',

        '&::-webkit-scrollbar': {
            height: 0
        },
        '&::-webkit-scrollbar-track': {
            background: 'transparent'
        },
        '&::-webkit-scrollbar-thumb': {
            background: 'transparent'
        },
        [theme.breakpoints.down('xs')]: {
            padding: '0.5rem 1rem 2rem',
        }
    },
    innerWrapper: {
    },
    column: {
        width: 'calc(33.33333% - 2rem)',
        flexShrink: 0,
        marginRight: '3rem',

        '@media (min-width: 960.5px) and (max-width: 1024px)': {
            width: 'calc(44% - 1rem)'
        },
        '@media (min-width: 1024.5px) and (max-width: 1280px)': {
            width: 'calc(40% - 1rem)'
        },
        [theme.breakpoints.down('sm')]: {
            width: 'calc(50% - 1rem)'
        },
        '@media (min-width: 600.5px) and (max-width: 700px)': {
            width: 'calc(60% - 1rem)'
        },
        [theme.breakpoints.down('xs')]: {
            width: '75%',
            marginRight: '2rem',

            '& p': {
                textOverflow: 'ellipsis'
            }
        },
        '&:last-child': {
            marginRight: 0,
            paddingRight: '2rem'
        }
    },
}));

function BlogCities(props) {
    const classes = useStyles();
    const laneRef = useRef(null);
    const [cities, setCities] = useState([]);
    const [isLoaded, setIsLoaded] = useState(false);
    const baseUrl = props.baseUrl;

    useEffect(() => {
        axios.get(`${baseUrl}/pages?slug=cities`)
            .then((response) => {
                // handle success
                setCities(response.data[0].acf.cities);
                setIsLoaded(true);
            })
            .catch(function (error) {
                // handle error
                console.log(error);
            });
    }, []);

    return (
        <section className={classes.root}>
            <BlogLane title="Places" laneRef={laneRef} margin={'0 -2rem'} marginMobile={'0 -1rem'} isLoaded={isLoaded}>
                <div className={classes.innerWrapper}>
                    <ul className={`${classes.cityList} no-scrollbar`} ref={laneRef}>
                        {
                            cities.map(city => (
                                <li key={city.name} className={`${classes.column} js-lane-item`}>
                                    <CityTeaser
                                        id={city.id}
                                        name={city.name}
                                        img={city.image.sizes.medium_large}
                                        altText={city.image.alt}
                                        teaserText={city.teaser_text}
                                    />
                                </li>
                            ))
                        }
                    </ul>
                </div>
            </BlogLane>
        </section>
    );
}

export default BlogCities;