import React from 'react';
import {Link} from 'react-router-dom';
import makeStyles from "@material-ui/core/styles/makeStyles";
import colors from "./styles/colors";

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        fontWeight: 800,

        [theme.breakpoints.down('xs')]: {
            marginBottom: '2rem'
        }
    },
    breadcrumb: {
        marginRight: '0.2rem',

        '&:last-child': {
            color: colors.green
        },

        [theme.breakpoints.down('xs')]: {
            fontSize: '0.875rem'
        }
    },
    breadcrumbLink: {
        color: colors.black,
        textDecoration: 'none',

        '&:hover': {
            color: colors.green
        }
    }
}));

function Breadcrumbs(props) {
    const classes = useStyles();
    const {cityName, cityId, articleTitle} = props;

    return (
        <ul className={classes.root}>
            <li className={classes.breadcrumb}>
                <Link className={classes.breadcrumbLink} to="/blog">
                    Blog
                </Link>
            </li>
            <li className={classes.breadcrumb}>|</li>
            <li className={classes.breadcrumb}>
                <Link className={classes.breadcrumbLink} to={`/blog/${cityId}`}>
                    {cityName}
                </Link>
            </li>
            <li className={classes.breadcrumb}>|</li>
            <li className={classes.breadcrumb}>
                {articleTitle}
            </li>
        </ul>
    );
}

export default Breadcrumbs;